import React from 'react';
import styles from './styles';

interface ILoader {
  widthInRem?: number
}

const Loader: React.FC<ILoader> = ({ widthInRem = 6.25 }) => {
  return (
    <styles.Loader widthInRem={widthInRem} />
  );
};

export default Loader;
