import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

const ViewButton: FlattenSimpleInterpolation = css`
    margin: 4.5rem auto 0;
    @media (max-width: 768px) {
      margin: 3rem auto 0;
    }
  `;

export default ViewButton;
