import React, { useState } from 'react';
import {
  Network,
  countriesList,
} from 'shared/enums';

import Textarea from 'shared/components/Textarea';

import { useFormContext } from 'react-hook-form';
import Dropdown from 'shared/components/Dropdown';
import { ReportNetworks } from 'shared/components/Dropdown/interface';
import Tooltip from 'shared/components/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info-circle.svg';

import styles from './styles';

import PhotoProof from '../components/PhotoProof';
import { IBasic } from './index';
import { tooltipForm } from './constants';

const Form = () => {
  const [showNetworks, setShowNetworks] = useState<boolean>(false);
  const [showCountries, setShowCountries] = useState<boolean>(false);

  const methods = useFormContext<Partial<IBasic>>();

  const {
    formState: { errors },
    setValue,
    register,
    control,
    watch,
    trigger,
  } = methods;

  const handleNetworks = (network: Network) => {
    setValue('network', network as Network);
    setShowNetworks(false);
    trigger('address');
  };

  const handleCountries = (country: string) => {
    setValue('country', country);
    setShowCountries(false);
    trigger('country');
  };

  const currentCountry = watch('country');
  const currentNetwork = watch('network');

  return (
    <styles.Wrapper>
      <styles.NetworkFieldWrapper>
        <styles.Label>
          <Tooltip tooltip={tooltipForm.network} iconRight={<InfoIcon />}>
            Network
          </Tooltip>
        </styles.Label>
        <styles.FieldItem
          $isSelected
          $isOpened={showNetworks}
          onClick={() => {
            setShowNetworks((val: boolean) => !val);
          }}
        >
          {Network[currentNetwork || Network.Bitcoin]}
          <styles.Arrow />
        </styles.FieldItem>
        {showNetworks && <Dropdown onChange={handleNetworks} elements={ReportNetworks} />}
      </styles.NetworkFieldWrapper>
      <styles.SubcategoryFieldWrapper>
        <styles.Label>
          <Tooltip tooltip={tooltipForm.country} iconRight={<InfoIcon />}>
            Country
          </Tooltip>
        </styles.Label>
        <styles.FieldItem
          $isSelected={Boolean(currentCountry)}
          $isOpened={showCountries}
          onClick={() => {
            setShowCountries((val: boolean) => !val);
          }}
        >
          {currentCountry || 'Select'}
          <styles.Arrow />
        </styles.FieldItem>
        {showCountries && <Dropdown onChange={handleCountries} elements={countriesList} isFullWidth />}
        {errors?.subcategory && <styles.Error>{errors.subcategory.message}</styles.Error>}
      </styles.SubcategoryFieldWrapper>
      <styles.InputContainer>
        <styles.Label>
          <Tooltip tooltip={tooltipForm.address} iconRight={<InfoIcon />}>
            Address
          </Tooltip>
        </styles.Label>
        <styles.Input {...register('address')} type="text" error={Boolean(errors?.address)} />
        {errors?.address && <styles.Error>{errors.address.message}</styles.Error>}
      </styles.InputContainer>
      <styles.InputContainer>
        <styles.Label>
          <Tooltip tooltip={tooltipForm.url} iconRight={<InfoIcon />}>
            URL
          </Tooltip>
        </styles.Label>
        <styles.Input {...register('url')} type="text" error={Boolean(errors?.url)} />
        {errors?.url && <styles.Error>{errors.url.message}</styles.Error>}
      </styles.InputContainer>
      <PhotoProof control={control} />
      <Textarea label="Description" name="description" />
    </styles.Wrapper>
  );
};

export default Form;
