import React, { useCallback, useState } from 'react';
import Title from 'shared/components/Title';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import { css } from 'styled-components/macro';
import LoadingIcon from 'assets/images/icons/loading.svg';
import { EMPTY_STRING } from 'shared/constants';
import apiService from 'services/api-service/api-service';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import CheckAddressStyles from './styles';
import { RequestResult, checkButton, title } from './constants';
import InformationMessage from './components/InformationMessage';
import ECheckAddressResponseStatuses from './enum';

const CheckAddress: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>(EMPTY_STRING);
  const [result, setResult] = useState<null | RequestResult>(null);

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (result !== null) return;
    const { value: addressValue } = event.target;
    setValue(addressValue);
  };

  const clearAction = useCallback(() => {
    setValue(EMPTY_STRING);
    setResult(null);
    MixpanelService.trackEvent(EMixpanelEvents.CHECK_ANOTHER_ADDRESS);
  }, [setValue, setResult]);

  const handleCheck = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        if (value.trim().length === 0) return;
        setLoading(true);
        const status = await apiService.checkAddress(value);
        MixpanelService.trackEvent(EMixpanelEvents.CHECK_ADDRESS, { addressCheck: value });

        switch (status) {
          case ECheckAddressResponseStatuses.SCAMFARI_LEGACY:
          case ECheckAddressResponseStatuses.ACCEPTED:
          case ECheckAddressResponseStatuses.PENDING: {
            setResult(RequestResult.Existed);
            break;
          }
          case ECheckAddressResponseStatuses.HAPI: {
            setResult(RequestResult.HAPI);
            break;
          }
          default:
            setResult(RequestResult.Unique);
        }
      } catch (e) {
        console.warn('Error while trying to check address', e);
      } finally {
        setLoading(false);
      }
    },
    [value],
  );

  return (
    <CheckAddressStyles.Container id="checkAddress">
      <CheckAddressStyles.Wrapper onSubmit={handleCheck}>
        <Title value="Check the Address for Presence in the Database" styles={title || css``} />
        {loading ? (
          <CheckAddressStyles.LoadingWrapper>
            <CheckAddressStyles.LoadingIcon src={LoadingIcon} alt="loading icon" />
            <CheckAddressStyles.WhiteText>Processing...</CheckAddressStyles.WhiteText>
          </CheckAddressStyles.LoadingWrapper>
        ) : (
          <>
            <CheckAddressStyles.InputWrapperAction>
              <CheckAddressStyles.Input
                type="text"
                placeholder="Enter Address"
                value={value}
                onChange={handleInputValue}
                disabled={result !== null}
              />
              {result === null && (
                <Button
                  label="Check Address"
                  handleClick={handleCheck}
                  variant={EButtonActions.RED_BUTTON}
                  styles={checkButton || css``}
                  type="submit"
                />
              )}
            </CheckAddressStyles.InputWrapperAction>

            <InformationMessage status={result} clearAction={clearAction} />
          </>
        )}
      </CheckAddressStyles.Wrapper>
    </CheckAddressStyles.Container>
  );
};

export default CheckAddress;
