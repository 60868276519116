import React from 'react';
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import { EDimensions } from 'shared/enums';
import { ReactComponent as ArrowBottom } from 'assets/images/icons/arrow-bottom.svg';
import styles from './styles';
import { IListItemWithSubmenu } from './interface';

const ListItemWithSubmenu: React.FC<IListItemWithSubmenu> = ({ name, submenu }: IListItemWithSubmenu) => {
  const dimension = useWindowDimensions();

  const [showSubmenu, setShowSubmenu] = React.useState<boolean>(false);

  const isAdaptive = dimension === EDimensions.SMALL || dimension === EDimensions.MEDIUM;

  return (
    <styles.ListItemWithSubmenu onMouseEnter={() => setShowSubmenu(true)} onMouseLeave={() => setShowSubmenu(false)}>
      <styles.ListItemWithSubmenuName>
        {name}
        {!isAdaptive && <ArrowBottom />}
      </styles.ListItemWithSubmenuName>
      {(isAdaptive || showSubmenu) && submenu}
    </styles.ListItemWithSubmenu>
  );
};

export default ListItemWithSubmenu;
