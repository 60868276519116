import React, { useCallback, useState } from 'react';
import { IClaimModal } from 'shared/interfaces';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectUser } from 'store/slices/user';
import { displayAmount } from 'shared/utils';
import { ReactComponent as Metamask } from 'assets/images/icons/metamask.svg';
import isMobile from 'shared/utils/userAgent';

import useAppDispatch from 'shared/hooks/redux/useAppDispatch';

import WarnImg from 'assets/images/icons/warn-icon.svg';
import { selectRewardToken } from 'store/slices/token';
import { CLAIM_MODAL_WIDTH, ZERO_STRING } from 'shared/constants';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import addTokenToMetamask from 'shared/utils/addTokenToMetamask';
import ToastService from 'services/toast-service';
import Big from 'big.js';
import { waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { useWalletClient } from 'wagmi';
import config, { getConfig } from 'services/config';
import { scamfariABI } from 'services/config/contract_abi';
import loadAccountDetails from 'store/actions/loadAccountDetails';
import { selectRewardConfiguration } from 'store/slices/reports';
import ModalWrapper from '../ModalWrapper';
import ModalsHeader from '../components/ModalsHeader';
import ClaimStyles from './styles';

enum ClaimModalStatus {
  RewardAvailable,
  ContractAvailable,
  DayLimitAvailable,
  NotAvailable
}

const calculateStatus = (dailyLimit: Big, contractBalance: Big, reward: Big, claimedToday: Big) => {
  const dailyLimitLeft = dailyLimit.minus(claimedToday);
  if (dailyLimitLeft.eq(ZERO_STRING) && !contractBalance.eq(ZERO_STRING) && !reward.eq(ZERO_STRING)) {
    return { status: ClaimModalStatus.RewardAvailable, amount: reward.gt(contractBalance) ? contractBalance : reward };
  }
  if (dailyLimitLeft.eq(ZERO_STRING) || contractBalance.eq(ZERO_STRING) || reward.eq(ZERO_STRING)) {
    return { status: ClaimModalStatus.NotAvailable, amount: Big(0) };
  }
  if (dailyLimitLeft.lte(contractBalance) && dailyLimitLeft.lte(reward)) { return { status: ClaimModalStatus.DayLimitAvailable, amount: dailyLimitLeft }; }
  if (contractBalance.lte(dailyLimitLeft) && contractBalance.lte(reward)) { return { status: ClaimModalStatus.ContractAvailable, amount: contractBalance }; }
  if (reward.lte(contractBalance) && reward.lte(dailyLimitLeft)) { return { status: ClaimModalStatus.RewardAvailable, amount: reward }; }
  return { status: ClaimModalStatus.NotAvailable, amount: Big(0) };
};

// eslint-disable-next-line import/prefer-default-export
export const ClaimModal: React.FC<IClaimModal> = ({ closeModal, token: { symbol } }: IClaimModal) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const { data: walletClient } = useWalletClient();
  const rewardToken = useAppSelector(selectRewardToken);
  const { reward } = useAppSelector(selectUser);
  const { dailyLimit, claimedToday } = useAppSelector(selectRewardConfiguration);

  const dispatch = useAppDispatch();
  console.log(Big(rewardToken.contractBalance).toFixed());
  const { status, amount } = calculateStatus(dailyLimit, rewardToken.contractBalance, reward, claimedToday);

  const claim = async () => {
    try {
      setIsButtonLoading(true);
      const hash = await writeContract(config, {
        abi: scamfariABI,
        address: getConfig().contract,
        functionName: 'claim',
        args: [BigInt(amount.toFixed())],
      });
      await waitForTransactionReceipt(config, { hash });
      dispatch(loadAccountDetails());
      ToastService.success({ text: 'Tokens have been successfully claimed' });
      MixpanelService.trackEvent(EMixpanelEvents.USER_CLAIMED_TOKENS, {
        amount: displayAmount(amount),
      });
    } catch (e) {
      ToastService.error({ text: 'An error occurred during the token claiming process' });
      console.warn(e, 'while claiming tokens');
    } finally {
      setIsButtonLoading(false);
      closeModal();
    }

    MixpanelService.trackEvent(EMixpanelEvents.CLAIM_CLICKED);
  };

  const closeModalHandler = () => {
    closeModal();
    MixpanelService.trackEvent(EMixpanelEvents.CLOSE_CLAIM_MODAL_CLICKED);
  };

  const addToken = useCallback(async () => {
    if (!walletClient) return;
    addTokenToMetamask(walletClient, rewardToken, isMobile);
  }, [walletClient, rewardToken]);

  return (
    <ModalWrapper closeModal={closeModalHandler} width={CLAIM_MODAL_WIDTH} padding="1.5rem">
      <ModalsHeader title="Claim Tokens" closeModal={closeModalHandler} />
      <ClaimStyles.AmountWrapper>
        <ClaimStyles.AmountRow>
          <ClaimStyles.AmountTitle>Reward Amount:</ClaimStyles.AmountTitle>
          <ClaimStyles.AmountValue>{`${displayAmount(reward)} ${symbol}`}</ClaimStyles.AmountValue>
        </ClaimStyles.AmountRow>
        <ClaimStyles.AmountRow>
          <ClaimStyles.AmountTitle>Daily claim remaining:</ClaimStyles.AmountTitle>
          <ClaimStyles.AmountValue>
            {`${displayAmount(
              dailyLimit.minus(claimedToday),
            )} ${symbol}`}
          </ClaimStyles.AmountValue>
        </ClaimStyles.AmountRow>
        {status === ClaimModalStatus.ContractAvailable && (
          <ClaimStyles.AmountRow>
            <ClaimStyles.AmountTitle>Available for Claim:</ClaimStyles.AmountTitle>
            <ClaimStyles.AmountValue>
              {`${displayAmount(amount)} ${symbol}`}
            </ClaimStyles.AmountValue>
          </ClaimStyles.AmountRow>
        )}
      </ClaimStyles.AmountWrapper>
      {status === ClaimModalStatus.ContractAvailable && (
        <ClaimStyles.WarningText>
          Currently, there is not enough tokens on the contract balance to claim the full amount of the reward. Please
          wait for the contract to be refilled to claim the rest. This process typically takes no more than a few days.
          <ClaimStyles.WarningIcon src={WarnImg} alt="warn icon" />
        </ClaimStyles.WarningText>
      )}
      {status === ClaimModalStatus.NotAvailable && dailyLimit.minus(claimedToday).eq(ZERO_STRING) && (
        <ClaimStyles.WarningText>
          Your daily claim limit reached
          <ClaimStyles.WarningIcon src={WarnImg} alt="warn icon" />
        </ClaimStyles.WarningText>
      )}
      <ClaimStyles.ButtonWrapper>
        <div>
          <Metamask onClick={addToken} />
        </div>
        <Button label="Cancel" handleClick={closeModal} variant={EButtonActions.GREY_BUTTON} />
        <Button
          label="Claim"
          handleClick={claim}
          variant={EButtonActions.YELLOW_BUTTON}
          disabled={status === ClaimModalStatus.NotAvailable}
          loading={isButtonLoading}
        />
      </ClaimStyles.ButtonWrapper>
    </ModalWrapper>
  );
};
