enum TRANSITION_DURATIONS {
  slow = 500,
  medium = 250,
  fast = 125,
}

const transitionOptions = {
  duration: {
    slow: `${TRANSITION_DURATIONS.slow}ms`,
    medium: `${TRANSITION_DURATIONS.medium}ms`,
    fast: `${TRANSITION_DURATIONS.fast}ms`,
  },
  timing: {
    ease: 'ease',
    in: 'ease-in',
    out: 'ease-out',
    inOut: 'ease-in-out',
  },
};

const hoverTransition = `${transitionOptions.duration.fast} ${transitionOptions.timing.in}`;
const hoverTransitionAll = `all ${transitionOptions.duration.medium} ${transitionOptions.timing.out}`;

const transitions = {
  hoverTransition,
  hoverTransitionAll,
};

export default transitions;
