import React from 'react';
import { v4 as uuid } from 'uuid';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectDataByNetwork } from 'store/slices/networks';
import HunterStyles from './styles';

import UsualUserRow from '../UsualUserRow';

const HuntersList: React.FC<{ isMainPage?: boolean }> = ({ isMainPage }) => {
  const dataByNetwork = useAppSelector(selectDataByNetwork);
  const hunters = dataByNetwork?.topReporters.slice(0, 5) || [];

  return (
    <HunterStyles.Wrapper isMain={Boolean(isMainPage)}>
      <HunterStyles.Table>
        <HunterStyles.Title isMain={Boolean(isMainPage)}>{isMainPage ? 'Top Hunters' : 'Hunters'}</HunterStyles.Title>
        <HunterStyles.TopRow>
          {isMainPage && !!hunters.length && <HunterStyles.ColumnName>Reports Amount</HunterStyles.ColumnName>}
        </HunterStyles.TopRow>
        {!hunters.length && <HunterStyles.NoResults>No results found</HunterStyles.NoResults>}
        <HunterStyles.AllHunters>
          {hunters.map((user, id) => (
            <div key={uuid()}>
              <UsualUserRow rank={id + 1} nickname={user.addr} score={user.score} />
            </div>
          ))}
        </HunterStyles.AllHunters>
      </HunterStyles.Table>
    </HunterStyles.Wrapper>
  );
};

export default HuntersList;
