import React from 'react';

const Crime: React.FC = () => {
  return (
    <>
      <div>
        <h2>Crime</h2>
        <p>
          Reports involve acts or intents that breach legal statutes, potentially leading to punishment like fines or imprisonment. This category includes a range of unlawful activities from counterfeiting to organized crime.
        </p>
      </div>
      <div>
        <h3>Counterfeiting</h3>
        <ul>
          <li>Faking.</li>
          <li>Goods.</li>
          <li>Currency.</li>
        </ul>
      </div>
      <div>
        <h3>Organized Crime</h3>
        <p>The activity of organized criminal groups.</p>
        <ul>
          <li>Gang Operations: Operations of criminal gangs.</li>
          <li>Mafia Activities: Actions associated with mafia groups.</li>
        </ul>
      </div>
      <div>
        <h3>Cyber Crime</h3>
        <p>Crimes committed via the internet or computer networks.</p>
      </div>
      <ul>
        <li>APT Group: Unauthorized intrusion into other computer systems.</li>
        <li>Phishing Attacks: Fraud with the aim of obtaining personal information.</li>
        <li>Hacking tool: Malicious software that blocks access to a system or data with a ransom demand.</li>
        <li>Hackers: Addresses of hackers who took part in similar attacks.</li>
      </ul>
      <div>
        <h3>Data Breaches</h3>
        <p>Unauthorized access to data.</p>
      </div>
      <div>
        <h3>Drug</h3>
        <p>The trade of drugs.</p>
      </div>
      <ul>
        <li>Trafficking.</li>
        <li>Distribution.</li>
        <li>Manufacturing.</li>
      </ul>
      <div>
        <h3>Weapons Trade</h3>
        <p>Illegal trade of weapons.</p>
      </div>
      <div>
        <h3>Human Trafficking</h3>
        <p>The trade of humans.</p>
      </div>
    </>
  );
};

export default Crime;
