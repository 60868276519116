import styled from 'styled-components';

const Tooltip = styled.p`
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
`;

export default {
  Tooltip,
};
