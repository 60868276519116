import styled from 'styled-components';
import { css } from 'styled-components/macro';

const Container = styled.div`
  ${({ theme }) => theme.media.tablet`
      padding: 0 1rem;
  `}
`;

const Wrapper = styled.form`
  max-width: 45.5rem;
  margin: 9rem auto 0;
  border-radius: 4.5rem;
  padding: 3rem 2rem 4.6rem;
  background: ${({ theme }) => theme.borderBlack};

  ${({ theme }) => theme.media.mobile`
    height: auto;
    padding: 3rem 1rem;
    border-radius: 3rem;
    margin: 6rem auto 0;
  `}
`;

const InputWrapperAction = styled.div`
  gap: 1.5rem;
  display: flex;
  margin-top: 4.63rem;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.media.mobile(`
      gap: 2.25rem;
      margin-top: 2.75rem;
      flex-direction: column;
    `)}
  `}
`;

const Input = styled.input`
  flex: 1;
  outline: none;
  height: 2.75rem;
  font-size: 1rem;
  background: none;
  padding-left: 0.5rem;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interRegular};
  border: 1px solid ${({ theme }) => theme.lightGrey};

  &::placeholder {
    color: ${({ theme }) => theme.lightFontGrey};
  }

  ${({ theme }) => css`
    ${theme.media.mobile(`
      width: 100%;
      flex: unset;
    `)}
  `}
`;

const LoadingWrapper = styled.div`
  text-align: center;
  margin: 7.5rem auto 0;
`;

const LoadingIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem auto;
`;

const WhiteText = styled.div`
  font-family: ${({ theme }) => theme.interSemiBold};
  font-size: 1rem;
  color: ${({ theme }) => theme.white};
`;

const Bg = styled.div<{ isBg: boolean }>`
  margin-top: ${({ isBg }) => (isBg ? '3rem' : '0')};
  background: ${({ theme, isBg }) => (isBg ? theme.grey : 'unset')};
  padding: ${({ isBg }) => (isBg ? '2rem 2rem 2rem' : '0')};
  border-radius: 2.5rem;
`;

const Icon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.5rem;
`;

const MessageSubtitle = styled.div`
  font-family: ${({ theme }) => theme.interRegular};
  font-size: 0.8rem;
  color: ${({ theme }) => theme.lightGrey};
  margin-top: 0.5rem;
`;

const InformMessageWrapper = styled.div`
  margin-top: 2.25rem;
  display: flex;
  justify-content: center;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  margin-top: 2.25rem;
  justify-content: flex-end;
`;

export default {
  Container,
  Wrapper,
  Input,
  InputWrapperAction,
  LoadingIcon,
  WhiteText,
  LoadingWrapper,
  Bg,
  Icon,
  MessageSubtitle,
  InformMessageWrapper,
  ActionButtonWrapper,
};
