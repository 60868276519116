import styled from 'styled-components';
import { css } from 'styled-components/macro';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 39.5rem;
  margin: 2rem auto 0;
  ${({ theme }) => css`
    ${theme.media.tablet(`
      width: auto;
      padding: 0 6.3rem;
    `)}
    ${theme.media.mobile(`
      padding: 0 1rem;
    `)}
  `}
`;

const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.37rem;
  margin-top: 2.38rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  font-weight: 600;
  ${({ theme }) => css`
    ${theme.media.tablet(`
      margin-right: 1.5rem;
    `)}
    ${theme.media.mobile(`
      font-size: 1.25rem;
    `)}
  `}
`;

const Icon = styled.div`
  cursor: pointer;
  & img {
    width: 2rem;
    height: 2rem;
  }
`;

const AnswerText = styled.div`
  font-weight: 400;
  line-height: 140%;
  font-size: 1.25rem;
  margin-top: 1.8rem;
  color: ${({ theme }) => theme.lightGrey};

  & .without-dots {
    list-style-type: none;
    padding: 0;
    & li {
      padding-top: 1rem;
      &:first-child {
        padding-top: 0;
      }
    }
  }
  & .with-dots {
    padding: 0;
    & li {
      padding-top: 1rem;
      &:first-child {
        padding-top: 0;
      }
    }
    & p {
      padding-top: 1rem;
    }
  }
  ${({ theme }) => css`
    ${theme.media.mobile(`
      font-size: 1rem;
    `)}
  `}
`;

export default {
  Page,
  Container,
  FaqList,
  HeaderWrapper,
  Title,
  Icon,
  AnswerText,
};
