import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import { EMPTY_STRING, REPORTS_PER_PAGE_INVESTIGATOR } from 'shared/constants';
import ReportList from 'shared/components/ReportList';
import Pagination from 'shared/components/Pagination';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectReports } from 'store/slices/reports';
import { toArray } from 'shared/utils';

import { ReactComponent as RefreshIcon } from 'assets/images/icons/refresh.svg';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';

import { EDimensions } from 'shared/enums';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import updateReportPage from 'store/actions/updateReportPage';
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import { IFilter } from 'shared/interfaces';
import FilterPanel from 'shared/components/FilterPanel';
import { DEFAULT_FILTER_OPTIONS } from 'shared/components/FilterPanel/constants';
import { EModals, showModal } from 'store/slices/modals';
import { selectUser } from 'store/slices/user';
import { selectCurrentChainId } from 'store/slices/networks';

import ReportStyles from './styles';
import SearchReports from './components/SearchReports';

const Reports: React.FC = () => {
  const dispatch = useAppDispatch();
  const dimensions = useWindowDimensions();
  const currentChainId = useAppSelector(selectCurrentChainId);
  const { isInvestigator } = useAppSelector(selectUser);
  const { list: reports, amount: reportsAmount, notFound: notFoundReports } = useAppSelector(selectReports);
  const reportsArray = toArray(reports);
  const showFilter = [EDimensions.DESKTOP, EDimensions.LAPTOP].includes(dimensions);

  const [filter, setFilter] = useState<IFilter>(DEFAULT_FILTER_OPTIONS);
  const [searchValue, setSearchValue] = useState<string>(EMPTY_STRING);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showReportsAmount, setShowReportsAmount] = useState<boolean>(false);

  const resetPages = () => setCurrentPage(1);

  const handleChangeFilter = (retrievedFilters: IFilter) => {
    resetPages();
    dispatch(updateReportPage({ page: 1, filter: retrievedFilters, searchQuery: searchValue }));
    setFilter(retrievedFilters);
    setShowReportsAmount(!isEqual(retrievedFilters, DEFAULT_FILTER_OPTIONS));
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    dispatch(updateReportPage({ page: pageNumber, filter, searchQuery: searchValue }));
    MixpanelService.trackEvent(EMixpanelEvents.CHANGE_REPORTS_PAGE, {
      page: pageNumber.toString(),
    });
  };

  const updateReportsHandler = useCallback(() => {
    dispatch(updateReportPage({ page: currentPage, filter }));
    setShowReportsAmount(false);
  }, [dispatch, currentPage, filter]);

  useEffect(() => {
    dispatch(updateReportPage({ page: 1 }));
  }, [dispatch, isInvestigator, currentChainId]);

  const totalPages = Math.ceil(reportsAmount / REPORTS_PER_PAGE_INVESTIGATOR);

  const showMobileFilter = [EDimensions.MEDIUM, EDimensions.SMALL].includes(dimensions) && !!reportsArray.length;
  const isActiveFilter = !isEqual(filter, DEFAULT_FILTER_OPTIONS);

  return (
    <ReportStyles.Container>
      <ReportStyles.Header>
        <ReportStyles.Title>Reports</ReportStyles.Title>
        <div>
          <RefreshIcon onClick={updateReportsHandler} />
          {showMobileFilter && (
            <>
              <ReportStyles.Filter
                isActiveFilter={isActiveFilter}
                onClick={() => dispatch(
                  showModal({
                    modal: EModals.FILTER,
                    props: { handleChangeFilter, filter },
                  }),
                )}
              >
                Filters
                <ReportStyles.FilterIconContainer isActiveFilter={isActiveFilter}>
                  <FilterIcon />
                </ReportStyles.FilterIconContainer>
              </ReportStyles.Filter>
              {isActiveFilter && (
                <span aria-hidden="true" onClick={() => handleChangeFilter(DEFAULT_FILTER_OPTIONS)}>Clear</span>
              )}
            </>
          )}
        </div>
      </ReportStyles.Header>
      <SearchReports
        filter={filter}
        resetPages={resetPages}
        showReportsAmount={(showValue) => setShowReportsAmount(showValue)}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {(showReportsAmount || notFoundReports) && (
        <ReportStyles.ReportsAmount>
          {`${notFoundReports ? 0 : reportsAmount} results found`}
        </ReportStyles.ReportsAmount>
      )}
      {notFoundReports ? (
        <ReportStyles.NotFoundReports>
          <p>We couldn&apos;t find any reports that match your search request.</p>
          <p>Double-check your search for any errors, or try different search term</p>
        </ReportStyles.NotFoundReports>
      ) : (
        <ReportStyles.Body showFilter={showFilter}>
          {showFilter && (
            <FilterPanel
              filter={filter}
              handleChangeFilter={handleChangeFilter}
            />
          )}
          <ReportList
            isClientReport={false}
            reports={reportsArray}
          />
          {(Boolean(totalPages) && !!reportsArray.length) && (
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          )}
        </ReportStyles.Body>
      )}
    </ReportStyles.Container>
  );
};

export default Reports;
