import React from 'react';
import FooterStyles from 'shared/components/Footer/styles';
import Socials from 'shared/components/Socials';
import ProtectedIcon from 'assets/images/icons/hapi-protected.svg';
import { FOOTER_LINKS, SOCIALS as socialsConstants } from 'shared/constants';
import { MixpanelService, EMixpanelEvents } from 'services/mixpanel';
import { ROUTES } from 'routes/constants';
import { useHistory } from 'react-router-dom';

const Desktop: React.FC = () => {
  const history = useHistory();
  const handlerTermsAndConditions = () => {
    history.push(ROUTES.TERMS_AND_CONDITIONS);
    MixpanelService.trackEvent(EMixpanelEvents.TERMS_AND_CONDITIONS_CLICKED);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };
  return (
    <FooterStyles.Wrapper>
      <FooterStyles.Text>{`© ${new Date().getFullYear()} Scamfari`}</FooterStyles.Text>
      <Socials />
      <FooterStyles.FlexRow>
        <FooterStyles.TermsAndPrivacy>
          <div
            aria-hidden="true"
            onClick={handlerTermsAndConditions}
          >
            Terms and conditions
          </div>
          <a
            href={FOOTER_LINKS.docs}
            target="_blank"
            rel="noreferrer"
            onClick={() => MixpanelService.trackEvent(EMixpanelEvents.DOCS_CLICKED)}
          >
            Docs
          </a>
        </FooterStyles.TermsAndPrivacy>
        <a
          href={socialsConstants.hapi}
          target="_blank"
          rel="noreferrer"
          onClick={() => MixpanelService.trackEvent(EMixpanelEvents.HAPI_CLICKED)}
        >
          <FooterStyles.ProtectedLogo src={ProtectedIcon} alt="protected icon" />
        </a>
      </FooterStyles.FlexRow>
    </FooterStyles.Wrapper>
  );
};

export default Desktop;
