import React from 'react';
import { ReactComponent as ClearIcon } from 'assets/images/icons/clear-search.svg';

import { EMPTY_STRING } from 'shared/constants';
import styles from './styles';

interface IInputPanel {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
}

const InputPanel = ({
  value, setValue, placeholder, disabled = false,
}: IInputPanel) => {
  return (
    <styles.InputContainer>
      <styles.Input
        value={value}
        onChange={(event) => setValue(event.target.value.trim())}
        autoComplete="off"
        autoCorrect="off"
        type="text"
        placeholder={placeholder}
        spellCheck="false"
        disabled={disabled}
      />
      {value.length ? <ClearIcon onClick={() => setValue(EMPTY_STRING)} /> : null}
    </styles.InputContainer>
  );
};

export default InputPanel;
