import React from 'react';

import { ReportStatus } from 'shared/enums';
import { EMPTY_STRING } from 'shared/constants';
import FilterStyles from './styles';
import { FILTER_CATEGORIES } from './constants';

interface FilterComponentProps {
  onCategoryClick: (category: ReportStatus | string) => void;
  selectedCategory: ReportStatus | string;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onCategoryClick,
  selectedCategory,
}) => {
  return (
    <FilterStyles.Wrapper>
      {FILTER_CATEGORIES.map((category) => (
        <FilterStyles.Type
          key={category.name}
          onClick={() => onCategoryClick(category.type)}
          type="button"
          className={category.type === selectedCategory ? 'active' : EMPTY_STRING}
        >
          {category.name}
        </FilterStyles.Type>
      ))}
    </FilterStyles.Wrapper>
  );
};

export default FilterComponent;
