const explorers = {
  Bitcoin: 'https://blockchair.com/bitcoin/testnet/address/',
  Ethereum: 'https://goerli.etherscan.io/address/',
  Linea: 'https://goerli.lineascan.build/address/',
  Tron: 'https://nile.tronscan.org/#/address/',
  BNBChain: 'https://testnet.bscscan.com/address/',
  Solana: 'https://solscan.io/account/',
  Polygon: 'https://mumbai.polygonscan.com/address/',
  Near: 'https://testnet.nearblocks.io/address/',
  Aurora: 'https://explorer.testnet.aurora.dev/address/',
  OKTC: 'https://www.okx.com/explorer/oktc-test/address/',
};

export default explorers;
