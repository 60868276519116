import React from 'react';

import ApproveIcon from 'assets/images/icons/approve-icon.svg';
import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

import { MixpanelService } from 'services/mixpanel';

import { ROUTES } from 'routes/constants';
import { useHistory } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import UserAvatarStyles from './styles';

interface IUserAvatar {
  isHunter?: boolean;
  nickname?: string;
  styles?: FlattenSimpleInterpolation;
}

const UserAvatar: React.FC<IUserAvatar> = ({ nickname, isHunter = true, styles }) => {
  const history = useHistory();
  const { open } = useWeb3Modal();
  const hunterAvatarUrl = `https://api.dicebear.com/6.x/bottts-neutral/svg?seed=${nickname}&radius=50`;

  const openUserProfile = async () => {
    if (!isHunter) {
      const [path] = history.location.pathname.split('/').slice(-1);
      const [userPath] = ROUTES.PROFILE.split('/').slice(-1);
      if (path.includes(userPath)) {
        open();
        return;
      }
      history.push(ROUTES.PROFILE);
      MixpanelService.trackEvent('Navigate to User Profile');
    }
  };

  return (
    <UserAvatarStyles.Wrapper onClick={openUserProfile} isHunter={isHunter} styles={styles || css``}>
      {hunterAvatarUrl && <UserAvatarStyles.Image src={hunterAvatarUrl} alt="avatar icon" />}
      {isHunter && <UserAvatarStyles.ApproveIcon src={ApproveIcon} alt="approve icon" />}
    </UserAvatarStyles.Wrapper>
  );
};

export default UserAvatar;
