import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

export const titleStyles = css`
  font-size: 2rem;
  text-align: left;
  ${({ theme }) => theme.media.mobile`
    font-size: 1.5rem;
  `}
` as FlattenSimpleInterpolation;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 39.5rem;
  margin: 2rem auto 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  ${({ theme }) => css`
    ${theme.media.tablet(`
      width: auto;
      padding: 0 6.3rem;
    `)}
    ${theme.media.mobile(`
      padding: 0 1rem;
    `)}
  `}
`;

const MainListHeading = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  font-weight: 600;
  ${({ theme }) => css`
    ${theme.media.mobile(`
      font-size: 1.25rem;
    `)}
  `}
`;

const SublistHeading = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 140%;
  a {
    margin: 0 0.5rem;
    color: ${({ theme }) => theme.regularGrey};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const MainList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  & > div {
    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
`;

const SubList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ThirdLevel = styled.div`
  display: flex;
  gap: 0.5rem;
  & > div {
    width: 1.25rem;
    flex-shrink: 0;
  }
`;

export default {
  Page,
  Container,
  MainListHeading,
  SublistHeading,
  Description,
  MainList,
  SubList,
  ThirdLevel,
};
