import styled from 'styled-components';

const StatusAndButtonsWrapper = styled.div`
  gap: 1rem;
  display: flex;
  margin-top: 1rem;
  align-items: flex-end;
  justify-content: space-between;

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

const AddressStatus = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.lightGrey};

  ${({ theme }) => theme.media.tablet`
    width: 100%;
    text-align: left;
  `}
`;

const OpenInExplorer = styled.a`
    gap: 0.25rem;
    display: flex;
    font-weight: 500;
    margin-top: 0.15rem; 
    font-size: 0.813rem;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.lightGrey};
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:hover {
      opacity: 0.8;
    }
`;

export default {
  StatusAndButtonsWrapper,
  AddressStatus,
  OpenInExplorer,
};
