import { networks } from 'services/config';
import { IRewards, RewardNetworksType } from 'shared/interfaces';

export const initialReportsState = {
  list: {},
  amount: 0,
  notFound: false,
};

export const initialRewardsNetwork = (): RewardNetworksType => Object.values(networks)
  .map((network) => ({ ...network, disabled: false, loading: false }));

export const initialRewardsState: IRewards = {
  categoryRewards: null,
  networks: initialRewardsNetwork(),
};
