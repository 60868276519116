import React from 'react';
import Instruction from 'views/Main/components/Instruction';
import Hunters from 'shared/components/HuntersList/huntersList';
import Partners from 'views/Main/components/Partners';
import ReviewReportSection from 'shared/components/ReviewReportSection';
import InfoCards from 'views/Main/components/InfoCards';

import LastAddedReports from 'shared/components/ReportList/LastAddedReports';
import HomeScreen from './components/HomeScreen';
import CheckAddress from './components/CheckAddress';

const Main: React.FC = () => {
  return (
    <>
      <HomeScreen />
      <InfoCards />
      <Instruction />
      <Hunters isMainPage />
      <CheckAddress />
      <ReviewReportSection />
      <LastAddedReports />
      <Partners />
    </>
  );
};

export default Main;
