import React from 'react';

import {
  EReportsAddressNetwork,
  EReportsCategory,
  EReportsStatus,
} from 'services/api-service/interfaces/report';
import { IFilter } from 'shared/interfaces';

import { ReactComponent as EthereumIcon } from 'assets/images/icons/networks/Ethereum.svg';
import { ReactComponent as LineaIcon } from 'assets/images/icons/networks/Linea.svg';
import { ReactComponent as BNBIcon } from 'assets/images/icons/networks/BNB.svg';
import { ReactComponent as PolygonIcon } from 'assets/images/icons/networks/Polygon.svg';
import { ReactComponent as AuroraIcon } from 'assets/images/icons/networks/Aurora.svg';
import { ReactComponent as NEARIcon } from 'assets/images/icons/networks/NEAR.svg';
import { ReactComponent as BitcoinIcon } from 'assets/images/icons/networks/Bitcoin.svg';
import { ReactComponent as OKTCIcon } from 'assets/images/icons/networks/OKTC.svg';
import { ReactComponent as TronIcon } from 'assets/images/icons/networks/Tron.svg';
import { ReactComponent as SolanaIcon } from 'assets/images/icons/networks/Solana.svg';

const status: Array<EReportsStatus> = [
  EReportsStatus.ACCEPTED,
  EReportsStatus.PENDING,
  EReportsStatus.REJECTED,
  EReportsStatus.CLAIMED,
];

const category: Array<EReportsCategory> = [
  EReportsCategory.SOCIAL_MEDIA_SCAMMER,
  EReportsCategory.SCAM_PROJECT,
  EReportsCategory.TERRORIST_FINANCING,
  EReportsCategory.FRAUDULENT_WEBSITE,
  // TODO: checkNEW
  EReportsCategory.RUG_PULL,
  EReportsCategory.MONEY_LAUNDERING,
  EReportsCategory.COUNTERFEITING,
  EReportsCategory.ORGANIZED_CRIME,
  EReportsCategory.CYBER_CRIME,
  EReportsCategory.DATA_BREACHES,
  EReportsCategory.DRUG,
  EReportsCategory.WEAPONS_TRADE,
  EReportsCategory.HUMAN_TRAFFICKING,
  EReportsCategory.SOCIAL_SCAM,
  EReportsCategory.NFT_SCAM,
  EReportsCategory.TERRORIST_FINANCE,
  EReportsCategory.SANCTION,
  EReportsCategory.DARKNET_MARKETS,
  EReportsCategory.WAR_DONATIONS,
];

const network: Array<EReportsAddressNetwork> = [
  EReportsAddressNetwork.NEAR,
  EReportsAddressNetwork.AURORA,
  EReportsAddressNetwork.ETHEREUM,
  EReportsAddressNetwork.SOLANA,
  EReportsAddressNetwork.BNB_CHAIN,
  EReportsAddressNetwork.BITCOIN,
  EReportsAddressNetwork.POLYGON,
  EReportsAddressNetwork.OKTC,
  EReportsAddressNetwork.TRON,
  EReportsAddressNetwork.LINEA,
];

export const filterOptions = {
  status,
  category,
  network,
};

export const DEFAULT_FILTER_OPTIONS: IFilter = {
  status: null,
  category: null,
  network: null,
};

export const FilterNetworkIconMap: Record<EReportsAddressNetwork, JSX.Element> = {
  [EReportsAddressNetwork.NEAR]: <BitcoinIcon />,
  [EReportsAddressNetwork.AURORA]: <EthereumIcon />,
  [EReportsAddressNetwork.SOLANA]: <LineaIcon />,
  [EReportsAddressNetwork.ETHEREUM]: <TronIcon />,
  [EReportsAddressNetwork.BNB_CHAIN]: <BNBIcon />,
  [EReportsAddressNetwork.BITCOIN]: <SolanaIcon />,
  [EReportsAddressNetwork.POLYGON]: <PolygonIcon />,
  [EReportsAddressNetwork.OKTC]: <NEARIcon />,
  [EReportsAddressNetwork.TRON]: <AuroraIcon />,
  [EReportsAddressNetwork.LINEA]: <OKTCIcon />,
};

export const FilterNetworkTextMap: Record<EReportsAddressNetwork, string> = {
  [EReportsAddressNetwork.NEAR]: 'Bitcoin',
  [EReportsAddressNetwork.AURORA]: 'Ethereum',
  [EReportsAddressNetwork.SOLANA]: 'Linea',
  [EReportsAddressNetwork.ETHEREUM]: 'Tron',
  [EReportsAddressNetwork.BNB_CHAIN]: 'BNB',
  [EReportsAddressNetwork.BITCOIN]: 'Solana',
  [EReportsAddressNetwork.POLYGON]: 'Polygon',
  [EReportsAddressNetwork.OKTC]: 'NEAR',
  [EReportsAddressNetwork.TRON]: 'Aurora',
  [EReportsAddressNetwork.LINEA]: 'OKTC',
};

export const FilterStatusTextMap: Record<EReportsStatus, string> = {
  [EReportsStatus.ACCEPTED]: 'Approved',
  [EReportsStatus.PENDING]: 'Pending',
  [EReportsStatus.REJECTED]: 'Declined',
  [EReportsStatus.CLAIMED]: 'Claimed',
};
