import React from 'react';
import { SOCIALS as SOCIAL_LINKS } from 'shared/constants';
import Telegram from 'assets/images/icons/telegram.svg';
import Twitter from 'assets/images/icons/twitter.svg';
import Medium from 'assets/images/icons/medium.svg';
import Discord from 'assets/images/icons/discord.svg';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import SocialStyles from './styles';

const SOCIALS = [
  {
    link: SOCIAL_LINKS.twitter,
    image: Twitter,
  },
  {
    link: SOCIAL_LINKS.telegram,
    image: Telegram,
  },
  {
    link: SOCIAL_LINKS.discord,
    image: Discord,
  },
  {
    link: SOCIAL_LINKS.medium,
    image: Medium,
  },
];

const openSocial = (link: string) => {
  window.open(link, '_blank');
  MixpanelService.trackEvent(EMixpanelEvents.SOCIAL_CLICKED, { url: link });
};

const Socials: React.FC = () => {
  return (
    <SocialStyles.Wrapper>
      {SOCIALS.map(({ link, image }) => (
        <SocialStyles.SocialCircle key={link} onClick={() => openSocial(link)}>
          <img src={image} alt="social icon" />
        </SocialStyles.SocialCircle>
      ))}
    </SocialStyles.Wrapper>
  );
};

export default Socials;
