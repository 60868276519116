import React, { useRef } from 'react';

import useOutsideClickCloseHandler from 'shared/hooks/useOutsideClickCloseHandler';
import { IOutsideClickWrapper } from './interface';
import styles from './styles';

const OutsideClickWrapper = ({ children, clickHandler }: IOutsideClickWrapper) => {
  const wrapperRef = useRef(null);
  useOutsideClickCloseHandler(wrapperRef, clickHandler);

  return <styles.OutsideClickContainer ref={wrapperRef}>{children}</styles.OutsideClickContainer>;
};

export default OutsideClickWrapper;
