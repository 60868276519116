import React from 'react';
import { capitalizeFirstLetter } from 'shared/utils';
import { ReportStatus } from 'shared/enums';
import ReportStatusStyles from './styles';
import ReportStatusIcon from './constants';

interface IReportStatus {
  status: ReportStatus;
}

const ReportStatusLabel: React.FC<IReportStatus> = ({
  status,
}) => {
  return (
    <ReportStatusStyles.Container status={status}>
      <ReportStatusStyles.Icon
        src={ReportStatusIcon[status]}
        alt="status icon"
      />
      {
        capitalizeFirstLetter(ReportStatus[status])
      }
    </ReportStatusStyles.Container>
  );
};

export default ReportStatusLabel;
