import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IReport, IReporter } from 'shared/interfaces';

import { defaultNetwork, networks as configNetworks } from 'services/config';
import { RootState } from '../index';

export interface IDataByNetwork {
  reportsCounter: number;
  reportersCounter: number;
  acceptedReportsCounter: number;
  lastReports: IReport[];
  topReporters: IReporter[];
}

export interface INetworksState {
  currentChainId: number;
  dataByNetwork: {
    [key: number]: IDataByNetwork;
  };
}

const initialState: INetworksState = {
  currentChainId: configNetworks[defaultNetwork]!.chainIdNumber,
  dataByNetwork: {},
};

const networks = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setCurrentChainId: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        currentChainId: action.payload,
      };
    },
    setDataByNetwork: (state, action: PayloadAction<{ [key: number]: IDataByNetwork }>) => {
      const newDataByNetwork = { ...state.dataByNetwork, ...action.payload };

      return {
        ...state,
        dataByNetwork: newDataByNetwork,
      };
    },
  },
});

export const selectCurrentChainId = (state: RootState) => state.networks.currentChainId;
export const selectAllDataByNetwork = (state: RootState) => state.networks.dataByNetwork;
export const selectDataByNetwork = (state: RootState) => state.networks.dataByNetwork[state.networks.currentChainId];

export const { setCurrentChainId, setDataByNetwork } = networks.actions;

export default networks.reducer;
