import styled from 'styled-components';

const Wrapper = styled.div`
  gap: 1.5rem;
  display: flex;
  align-items: center;
`;

const Type = styled.button`
  padding: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  background: transparent;
  color: ${({ theme }) => theme.lightFontGrey};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &::after {
    content: '';
    position: absolute;
    width: 0;
    left: 50%;
    opacity: 0;
    height: 0.125rem;
    top: calc(100% + 0.25rem);
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.white};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }

  &.active {
    color: ${({ theme }) => theme.white};

    &::after {
      opacity: 1;
      width: 50%;
    }
  }
  &:hover {
    color: ${({ theme }) => theme.white};
    &::after {
      opacity: 1;
      width: 50%;
    }
  }
  ${({ theme }) => theme.media.mobile`
    padding: 0 0 0.25rem;
  `}
`;

export default {
  Wrapper,
  Type,
};
