import React from 'react';
import Title from 'shared/components/Title';
import titleStyles from './constants';
import AboutStyles from './styles';

const About: React.FC = () => {
  return (
    <AboutStyles.Page>
      <AboutStyles.Container>
        <Title value="About" styles={titleStyles} />
        <AboutStyles.Text>
          <p>
            Fraud affects the reputation of the crypto market. Illegal
            transactions on the cryptocurrency network are steadily increasing.
          </p>
          <p>
            Fraudsters are attracted to the anonymity and non-traceability of
            the cryptocurrency transactions, making them the breeding ground for
            scams, exploits, and phishing traps.
          </p>
        </AboutStyles.Text>
        <AboutStyles.Title>What is Scamfari</AboutStyles.Title>
        <AboutStyles.Text>
          <p>
            Scamfari is a crowdsourcing platform, part of HAPI Foundation, a leading cybersecurity and cryptocurrency security company.
            Our mission with Scamfari is to make the blockchain safer and more secure by increasing user safety, awareness, and literacy in the face of potential fraud within the cryptocurrency world.
          </p>
          <p>
            We believe that by working together, we can combat the growing threat
            of fraud and create a more reliable and trustworthy blockchain
            ecosystem. Our platform is designed to collect the addresses of
            scammers, phishing sites, and fraudulent blockchain projects while
            rewarding community members for their efforts.
          </p>
          <p>
            Using authoritative data sources and advanced tools with real-time
            access, we ensure the accuracy of the submitted data.
          </p>
          <p>
            Scamfari is designed with the Blockchain in mind. Offering
            transparency of submissions and rewards by bringing the whole
            operation on-chain! We are strong proponents of Web3 technology and
            actively support its principles.
          </p>
          <p>
            Using Scamfari is simple and user-friendly. Users can report
            fraudulent or malicious projects through our website. They create a
            nickname that will be displayed at the TOP of active users, choose a
            category from the provided options (Social Media Scammer, Fraudulent
            Website, or Scam Project/Rugpull), and submit the report with
            accompanying proof. Once validated, users receive rewards for their
            submissions.
          </p>
          <p>
            Join us now in the fight against fraud, and let&rsquo;s build a safer and more secure blockchain ecosystem
            together!
          </p>
        </AboutStyles.Text>
        <AboutStyles.Title>Let the Scamfari begin!</AboutStyles.Title>
      </AboutStyles.Container>
    </AboutStyles.Page>
  );
};

export default About;
