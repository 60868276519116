import { EButtonActions } from 'shared/enums';
import { DefaultTheme } from 'styled-components';

export function getBg(theme: DefaultTheme, variant: EButtonActions) {
  switch (variant) {
    // TODO: add hover and pressed colors for white button
    case EButtonActions.WHITE_BUTTON:
      return { default: theme.white, hover: theme.white, pressed: theme.white };
    case EButtonActions.BLACK_BUTTON:
      return { default: theme.black, hover: theme.blackHover, pressed: theme.blackPressed };
    case EButtonActions.YELLOW_BUTTON:
      return { default: theme.yellow, hover: theme.yellowHover, pressed: theme.yellowPressed };
    case EButtonActions.OPACITY_YELLOW_BUTTON:
      return { default: theme.opacityYellow, hover: theme.opacityYellowHover, pressed: theme.opacityYellowHover };
    case EButtonActions.GREY_BUTTON:
      return { default: theme.greyButton, hover: theme.greyButtonHover, pressed: theme.greyButtonPressed };
    case EButtonActions.RED_BUTTON:
      return { default: theme.declinedRed, hover: theme.declinedRedHover, pressed: theme.declinedRedPressed };
    case EButtonActions.GREEN_BUTTON:
      return { default: theme.approvedGreen, hover: theme.approvedGreenHover, pressed: theme.approvedGreenPressed };
    default:
      return { default: theme.black, hover: theme.black, pressed: theme.black };
  }
}

export function getColor(theme: DefaultTheme, variant: EButtonActions) {
  switch (variant) {
    case EButtonActions.WHITE_BUTTON:
      return theme.black;
    case EButtonActions.BLACK_BUTTON:
      return theme.white;
    case EButtonActions.YELLOW_BUTTON:
      return theme.black;
    case EButtonActions.OPACITY_YELLOW_BUTTON:
      return theme.black;
    case EButtonActions.GREY_BUTTON:
      return theme.white;
    default:
      return theme.white;
  }
}
