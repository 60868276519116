import ToastService from 'services/toast-service';
import { Token } from 'store/slices/token';
import { WalletClient } from 'viem';

const openTokenAddUrlInNewTab = (address: string, symbol: string, decimal: number, icon: string) => {
  const url = new URL('https://metamask.app.link/add-token');
  url.searchParams.append('address', address);
  url.searchParams.append('symbol', symbol);
  url.searchParams.append('decimals', decimal.toString());
  url.searchParams.append('image', icon);
  window.open(url.href, '_blank');
};

const addTokenToMetamask = async (
  walletClient: WalletClient,
  metadata: Token,
  isMobile: boolean,
) => {
  const { address, symbol, decimal } = metadata;
  const icon = 'https://s2.coinmarketcap.com/static/img/coins/64x64/27863.png';
  const successText = 'Token SCM added to Metamask';
  const errorText = 'Token SCM added to Metamask';
  if (isMobile) {
    openTokenAddUrlInNewTab(address, symbol, decimal, icon);
  } else {
    try {
      const hasAdded = await walletClient.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals: decimal,
            image: icon,
          },
        },
      });
      if (hasAdded) {
        ToastService.success({ text: successText });
      } else {
        ToastService.error({ text: errorText });
      }
    } catch (error) {
      ToastService.error({ text: errorText });
      console.error(`${errorText}: ${error}`);
    }
  }
};

export default addTokenToMetamask;
