import React, { useEffect, useState } from 'react';
import UPLOAD_ICON from 'assets/images/icons/file-upload.svg';
import { useDropzone } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { megabytesToBytes } from 'shared/utils';
import FileAreaStyles from './styles';

const maxFiles = 3;
const fileSizeLimitInMB = 20;
const textHelper = {
  title: `Image size limit ${fileSizeLimitInMB} MB`,
  tooLarge: `File size is more than ${fileSizeLimitInMB} MB`,
};

const DropzoneErrors = {
  fileTooLarge: 'file-too-large',
};

const Dropzone = ({ onChange, value }: { onChange: (value: File[]) => void; value: File[]; }) => {
  const [fileError, setFileError] = useState<string | null>(null);
  const remainingUploadSlots = maxFiles - value.length;
  const onDrop = (acceptedFiles: File[]) => {
    const newFiles = [...value, ...acceptedFiles];
    onChange(newFiles);
  };
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/webp': [],
      'image/svg': [],
    },
    maxSize: megabytesToBytes(fileSizeLimitInMB),
    onDrop,
    disabled: remainingUploadSlots === 0,
    maxFiles: remainingUploadSlots,
  });

  useEffect(() => {
    const fileRejectionsLength = fileRejections.length;
    if (fileRejectionsLength > 0 && !fileError) {
      const lastFile = fileRejections[fileRejectionsLength - 1];
      const error = lastFile.errors[0];
      setFileError(error.code === DropzoneErrors.fileTooLarge ? textHelper.tooLarge : error.message);
    } else {
      setFileError(null);
    }
  }, [fileRejections]);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <FileAreaStyles.FileArea>
        <FileAreaStyles.UploadText error={Boolean(fileError)}>
          <img src={UPLOAD_ICON} alt="upload icon" />
          <span>Browse Files</span>
          <span>{fileError || textHelper.title}</span>
        </FileAreaStyles.UploadText>
      </FileAreaStyles.FileArea>
    </div>
  );
};

const FileArea = () => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="proofs"
      render={({ field: { onChange, value } }) => (
        <Dropzone onChange={onChange} value={value} />
      )}
    />
  );
};

export default FileArea;
