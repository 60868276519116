import { IReport } from 'shared/interfaces';
import { Network } from 'shared/enums';
import { Address } from 'viem';
import getCorrectIPFSLinks from '../helpers/ipfs';

export interface IIndexerReport {
  id: string;
  reporter_address: string;
  report_network: string;
  category: string;
  address: string;
  address_network: string;
  url: string;
  status: string;
  reject_reason: string;
  proof: string[];
  description: string;
  created_at: string;
  updated_at: string;
}

enum EReportIndexerStatuses {
  Pending,
  Accepted,
  Rejected,
  Claimed,
}

enum EReportIndexerCategory {
  SocialMediaScammer,
  FraudulentWebsite,
  ScamProject,
  TerroristFinancing,
  FinancialFraud,
  RugPull,
  PumpAndDumpSchemes,
  PonziSchemes,
  Honeypots,
  MoneyLaundering,
  TradeBasedLaundering,
  MixingServices,
  Crime,
  Counterfeiting,
  OrganizedCrime,
  GangOperations,
  MafiaActivities,
  CyberCrime,
  APTGroup,
  PhishingAttacks,
  HackingTool,
  Hackers,
  DataBreaches,
  Drug,
  Trafficking,
  Distribution,
  Manufacturing,
  WeaponsTrade,
  HumanTrafficking,
  SocialScam,
  Blackmail,
  InvestmentScam,
  LotteryScam,
  DataTheft,
  NFTScam,
  IllegalActivity,
  TerroristFinance,
  Sanction,
  DarknetMarkets,
  WarDonations,
}

export enum EReportIndexerNetwork {
  NEAR,
  Aurora,
  Solana,
  Ethereum,
  BNBChain,
  Bitcoin,
  Polygon,
  OKTC,
  Tron,
  Linea,
}

function getCorrectNetwork(network: string): Network {
  const mapping: Network[] = Object.values(Network);
  const networkIndex: number = EReportIndexerNetwork[network as keyof typeof EReportIndexerNetwork];

  return mapping[networkIndex];
}

const formatIndexerReport = (report: IIndexerReport): IReport => {
  const {
    id,
    reporter_address,
    category,
    address,
    address_network,
    url,
    status,
    reject_reason,
    proof,
    description,
    created_at,
    updated_at,
  } = report;

  const viewProofs = proof.map((p: string) => getCorrectIPFSLinks({ proof: p }).proof);

  return {
    addr: address,
    category: EReportIndexerCategory[category as keyof typeof EReportIndexerCategory],
    id: Number(id),
    network: getCorrectNetwork(address_network),
    proof: viewProofs,
    reject_reason,
    reporter: reporter_address as Address,
    status: EReportIndexerStatuses[status as keyof typeof EReportIndexerStatuses],
    url,
    description,
    created_at,
    updated_at,
  };
};

export default formatIndexerReport;
