import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReporterStatus } from 'shared/enums';

import { EMPTY_STRING } from 'shared/constants';

import Big from 'big.js';
import { RootState } from '../index';

interface UserState {
  isInvestigator: boolean;
  reportCount: number;
  reward: Big;
  status: ReporterStatus;
  username: string;
  iconUrl: string;
}

const initialState: UserState = {
  iconUrl: '',
  isInvestigator: false,
  reportCount: 0,
  reward: Big(0),
  status: ReporterStatus.None,
  username: EMPTY_STRING,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (
      state,
      {
        payload,
      }: PayloadAction<{
        iconUrl: string;
        isInvestigator: boolean;
        reportCount: number;
        reward: Big;
        status: ReporterStatus;
      }>,
    ) => ({
      ...state,
      iconUrl: payload.iconUrl,
      isInvestigator: payload.isInvestigator,
      reportCount: payload.reportCount,
      reward: payload.reward,
      status: payload.status,
    }),
  },
});

export const { setUserProfile } = user.actions;

export const selectUser = (state: RootState) => state.user;

export default user.reducer;
