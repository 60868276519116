import styled from 'styled-components';

const Description = styled.div`
  margin-top: 1rem;
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 400;
`;

const SidePadding = styled.div`
  padding: 0;
`;

const Wrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem
`;

const Reason = styled.div`
  border-radius: 1.25rem;
  background: ${({ theme }) => theme.grey};
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  color: ${({ theme }) => theme.white};
  padding: 0.75rem;
  cursor: pointer;
`;

export default {
  Description,
  SidePadding,
  Wrapper,
  Reason,
};
