import styled, { css } from 'styled-components';

const ListItemWithSubmenu = styled.div`
  top: 0.25rem;
  position: relative;
  padding-bottom: 0.5rem;

  div {
    color: ${({ theme }) => theme.lightGrey};

    svg {
      path {
        fill: ${({ theme }) => theme.lightGrey};
      }
    }
  }
  &:hover {
    div {
      color: ${({ theme }) => theme.white};

      svg {
        path {
          fill: ${({ theme }) => theme.white};
        }
      }
    }
  }

  ${({ theme }) => css`
    ${theme.media.tablet(`
      top: unset;
      padding-bottom: 0;

      &:hover {
        div {
          color: ${theme.white};
        }
      }
    `)}
  `}
`;

const ListItemWithSubmenuName = styled.div`
  gap: 0.25rem;
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  line-height: 140%;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.lightFontGrey};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  svg {
    path {
      fill: ${({ theme }) => theme.lightFontGrey};
      transition: ${({ theme }) => theme.transitions.hoverTransition};
    }
  }

  ${({ theme }) => css`
    ${theme.media.tablet(`
      gap: 3rem;
      opacity: 1;
      width: auto;
      cursor: default;
      text-align: left;
      font-size: 1.5rem;
      padding: 0 0 0 11.5rem;
      color: ${theme.lightGrey}!important;
    `)}

    ${theme.media.tablet('padding: 0 0 0 3.5rem;')}
  `}
`;

export default {
  ListItemWithSubmenu,
  ListItemWithSubmenuName,
};
