import { getConfig } from 'services/config';
import { scamfariABI } from 'services/config/contract_abi';

const getScamfariContract = (selectedChainId?: number) => {
  return {
    abi: scamfariABI,
    address: getConfig(selectedChainId).contract,
  };
};

export default getScamfariContract;
