import { UseFormSetError } from 'react-hook-form';

import { z } from 'zod';
import ToastService from 'services/toast-service';
import apiService from 'services/api-service/api-service';
import ECheckAddressResponseStatuses from 'views/Main/components/CheckAddress/enum';
import { convertEnumNetworkToNumber } from 'shared/utils/networks';
import getScamfariContract from 'shared/utils/getScamfariContract';
import config, { getConfig } from 'services/config';

import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core';
import { scamfariABI } from 'services/config/contract_abi';

import { IBasic } from '.';
import { uploadToIpfs } from '../helpers';

const checkAddresses = async (values: Partial<IBasic>, setError: UseFormSetError<Partial<IBasic>>) => {
  const scamfariContractConfig = getScamfariContract();
  const addresses: { address: string; path: keyof IBasic }[] = [];
  if (values.address) {
    addresses.push({ address: values.address, path: 'address' });
  }

  const resultArray: { isExist: boolean; path: keyof IBasic }[] = await Promise.all(
    addresses.map(async ({ address, path }) => {
      const isExist = await readContract(config, {
        ...scamfariContractConfig,
        functionName: 'checkAddress',
        args: [address],
      });
      return { isExist, path };
    }),
  );
  resultArray.map(({ isExist, path }) => isExist && setError(path, { message: 'Address already in database' }));

  return resultArray.map(({ isExist }) => isExist).includes(true);
};

const createReports = async (values: Partial<IBasic>, closeModal: () => void) => {
  try {
    if (!values.description || values.network === undefined || !values.address || !values.url || !values.subcategory) {
      throw new Error('Unable to create report: Insufficient data provided.');
    }
    const photosCID = await uploadToIpfs(values.proofs);

    const hash = await writeContract(config, {
      address: getConfig().contract,
      abi: scamfariABI,
      functionName: 'createReport',
      args: [
        convertEnumNetworkToNumber(values.network),
        values.subcategory,
        values.address,
        values.url,
        photosCID,
        `${values.country}: ${values.description}`,
        '',
      ],
    });
    await waitForTransactionReceipt(config, { hash });

    ToastService.success({ text: 'Report has been created successfully' });
    closeModal();
  } catch (e) {
    console.warn(e);
    ToastService.error({ text: 'Something went wrong. Please try again' });
  }
};

const checkAddressByApi = async (address: string, ctx: z.RefinementCtx) => {
  const status = await apiService.checkAddress(address);
  switch (status) {
    case ECheckAddressResponseStatuses.SCAMFARI_LEGACY: {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'This address is already present in Scamfari Database',
        path: ['address'],
      });
      break;
    }
    case ECheckAddressResponseStatuses.HAPI: {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'This address is already present in HAPI Protocol',
        path: ['address'],
      });
      break;
    }
    case ECheckAddressResponseStatuses.PENDING:
    case ECheckAddressResponseStatuses.ACCEPTED: {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'This address already present accepted in Scamfari contract',
        path: ['address'],
      });
      break;
    }
    default:
      break;
  }
};

export { checkAddresses, checkAddressByApi, createReports };
