import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

const titleStyles: FlattenSimpleInterpolation = css`
  font-size: 2rem;
  padding-top: 2rem;
    ${({ theme }) => theme.media.mobile`
      font-size: 1.5rem;
    `}
` as FlattenSimpleInterpolation;

export default titleStyles;
