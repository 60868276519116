import ToastService from 'services/toast-service';
import { FORMAT_COMMA, FORMAT_DOT, FULFILLED } from 'shared/constants';

export * from './formatString';
export * from './createReport';
export * from './displayAmount';
export { default as getEntries } from './getEntries';

export const openLinkInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export function isNotNullOrUndefined<T>(value: T): boolean {
  return value !== null && value !== undefined;
}

export function assertFulfilled<T>(item: PromiseSettledResult<T>): item is PromiseFulfilledResult<T> {
  return item.status === FULFILLED;
}

type KeyType = string | number | symbol;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toMap = <T extends Record<KeyType, any>, K extends keyof T>(
  array: Array<T>,
  keyName: K,
): Record<T[K], T> => array.reduce(
    (acc, item) => ({
      ...acc,
      [item[keyName]]: item,
    }),
    {} as Record<T[K], T>,
  );

export const toArray = <T>(map: { [key: string]: T }) => Object.values(map);

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes(FORMAT_DOT) || amount.includes(FORMAT_COMMA)) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export const copied = () => ToastService.success({ text: 'Copied to clipboard' });

export function splitCamelCase(input: string): string {
  return input.replace(/([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g, '$1$3 $2$4');
}

export const convertSvgElementToBase64 = (walletId: string): string | undefined => {
  const svg = document.getElementById(walletId);
  if (!svg) return undefined;
  const string = new XMLSerializer().serializeToString(svg);
  const encodedData = window.btoa(string);
  return `data:image/svg+xml;base64,${encodedData}`;
};
