import { createAsyncThunk } from '@reduxjs/toolkit';
import Big from 'big.js';
import { getAccount, readContract } from '@wagmi/core';

import { setUserProfile } from 'store/slices/user';
import getScamfariContract from 'shared/utils/getScamfariContract';
import config from 'services/config';
import { RootState } from '../index';

const loadAccountDetails = createAsyncThunk<void, void, { state: RootState }>(
  'loadAccountDetails',
  async (_, { dispatch }) => {
    try {
      const { address } = getAccount(config);
      const contractConfig = getScamfariContract();
      if (!address) return;
      const [status, reward, reportCount, isInvestigator] = await readContract(config, {
        ...contractConfig,
        functionName: 'getMyStatus',
        account: address,
      });
      dispatch(
        setUserProfile({
          iconUrl: `https://api.dicebear.com/6.x/bottts-neutral/svg?seed=${address}&radius=50`,
          reportCount: Number(reportCount),
          status: Number(status.toString()),
          reward: Big(reward.toString()),
          isInvestigator,
        }),
      );
    } catch (e) {
      console.error(`Error: while loading account details \n ${e}`);
    }
  },
);

export default loadAccountDetails;
