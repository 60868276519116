import React from 'react';
import IconOne from 'assets/images/report_icon_two.svg';
import IconTwo from 'assets/images/report_icon_three.svg';
import IconThree from 'assets/images/icons/scam-wallet.svg';

import ReviewStyles from './styles';

const explainSteps = [
  {
    icon: IconOne,
    text: 'We utilize cutting-edge data providers and their respective toolset to ensure that we have the most accurate real-time information about an address ',
    key: 1,
  },
  {
    icon: IconTwo,
    text: 'Only officially certified AML experts are reviewing submitted addresses. All of our reviewers have passed Chainalysis certification',
    key: 2,
  },
  {
    icon: IconThree,
    text: 'Approved addresses are added directly to the HAPI database with the "Scam" label ',
    key: 3,
  },
];

const ReviewReportSection: React.FC = () => {
  return (
    <>
      <ReviewStyles.Title>
        How We Review Your Reports
      </ReviewStyles.Title>
      <ReviewStyles.Wrapper>
        {explainSteps.map((step) => (
          <ReviewStyles.Item key={step.key}>
            <ReviewStyles.Logo src={step.icon} alt="review icon" />
            <ReviewStyles.Description>
              {step.text}
            </ReviewStyles.Description>
          </ReviewStyles.Item>
        ))}
      </ReviewStyles.Wrapper>
    </>
  );
};

export default ReviewReportSection;
