import { DropdownNetwork } from 'shared/enums';
import { IConfig } from 'services/config/interface';
import { goerli, sepolia } from 'viem/chains';
import networks from './constants';
import explorers from './explorers';

export default {
  apiUrl: 'https://scamfari.stage.hapi.farm/backend/',
  infuraKey: process.env.REACT_APP_INFURA_KEY,
  networks,
  chains: [sepolia, goerli],
  defaultNetwork: DropdownNetwork.Ethereum,
  mixpanelToken: '', // disable mixpanel for testnet users
  explorers,
} as IConfig;
