import { EToasterType } from 'shared/enums';
import SuccessIcon from 'assets/images/icons/success-toast-icon.svg';
import WarningIcon from 'assets/images/icons/warning-toast-icon.svg';
import ErrorIcon from 'assets/images/icons/error-toast-icon.svg';

const determineToasterIcon = (type: EToasterType) => {
  switch (type) {
    case EToasterType.SUCCESS_ACTION:
      return SuccessIcon;
    case EToasterType.WARNING_ACTION:
      return WarningIcon;
    default:
      return ErrorIcon;
  }
};

export default determineToasterIcon;
