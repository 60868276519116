import LineaIcon from 'assets/images/icons/networks/Linea.svg';
import EthereumIcon from 'assets/images/icons/networks/Ethereum.svg';
import OptimismIcon from 'assets/images/icons/networks/Optimism.svg';
import BNBIcon from 'assets/images/icons/networks/BNB.svg';
import PolygonIcon from 'assets/images/icons/networks/Polygon.svg';
import ScrollIcon from 'assets/images/icons/networks/Scroll.svg';
import AuroraIcon from 'assets/images/icons/networks/Aurora.svg';
import zkSyncIcon from 'assets/images/icons/networks/ZKSync.svg';
import BlastIcon from 'assets/images/icons/networks/Blast.svg';

import { DropdownNetwork } from 'shared/enums';
import { INetworks } from 'services/config/interface';
import { aurora, blastSepolia } from 'viem/chains';

const networks: INetworks = {
  [DropdownNetwork.Linea]: {
    chainIdNumber: 59144,
    infuraApiRoute: 'https://linea-mainnet.infura.io/v3/',
    iconPath: LineaIcon,
    name: DropdownNetwork.Linea,
    active: true,
  },
  [DropdownNetwork.Ethereum]: {
    chainIdNumber: 1,
    infuraApiRoute: 'https://mainnet.infura.io/v3/',
    iconPath: EthereumIcon,
    name: DropdownNetwork.Ethereum,
    active: true,
  },
  [DropdownNetwork.Aurora]: {
    chainIdNumber: aurora.id,
    infuraApiRoute: '',
    iconPath: AuroraIcon,
    name: DropdownNetwork.Aurora,
    active: true,
  },
  [DropdownNetwork.Blast]: {
    chainIdNumber: blastSepolia.id,
    infuraApiRoute: '',
    iconPath: BlastIcon,
    name: DropdownNetwork.Blast,
    active: true,
  },
  [DropdownNetwork.Optimism]: {
    chainIdNumber: 0,
    infuraApiRoute: '',
    iconPath: OptimismIcon,
    name: DropdownNetwork.Optimism,
    active: false,
  },
  [DropdownNetwork.BNBChain]: {
    chainIdNumber: 0,
    infuraApiRoute: '',
    iconPath: BNBIcon,
    name: DropdownNetwork.BNBChain,
    active: false,
  },
  [DropdownNetwork.Polygon]: {
    chainIdNumber: 0,
    infuraApiRoute: '',
    iconPath: PolygonIcon,
    name: DropdownNetwork.Polygon,
    active: false,
  },
  [DropdownNetwork.Scroll]: {
    chainIdNumber: 0,
    infuraApiRoute: '',
    iconPath: ScrollIcon,
    name: DropdownNetwork.Scroll,
    active: false,
  },

  [DropdownNetwork.zkSync]: {
    chainIdNumber: 324,
    infuraApiRoute: 'https://mainnet.era.zksync.io',
    iconPath: zkSyncIcon,
    name: DropdownNetwork.zkSync,
    active: false,
    addChainData: {
      chainId: `0x${Number(324).toString(16)}`,
      chainName: 'zkSync Era Mainnet',
      blockExplorerUrls: ['https://explorer.zksync.io/'],
      rpcUrls: ['https://mainnet.era.zksync.io'],
      nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
      },
    },
  },
};

export default networks;
