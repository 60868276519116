import React from 'react';
import RuleOne from 'assets/images/1.svg';
import RuleTwo from 'assets/images/2.svg';
import RuleThree from 'assets/images/3.svg';

import titleStyles from 'views/Main/constants';
import Title from 'shared/components/Title';

import InfoCardStyles from './styles';

const infoItems = [
  {
    title: 'Find an address funding',
    subTitle: 'military aggression',
    img: RuleOne,
    key: 1,
  },
  {
    title: 'Send us the address and',
    subTitle: 'screenshot as a proof',
    img: RuleTwo,
    key: 2,
  },
  {
    title: 'The more reports, the',
    subTitle: 'higher your reward',
    img: RuleThree,
    key: 3,
  },
];

const InfoCards: React.FC = () => {
  return (
    <InfoCardStyles.Container>
      <Title value="Easy Steps to Rewards" isBlack styles={titleStyles} />
      <InfoCardStyles.Wrapper>
        {infoItems.map((item) => (
          <InfoCardStyles.InfoItem key={item.key}>
            <InfoCardStyles.TextRow>
              <InfoCardStyles.TextTitle>
                <p>{item.title}</p>
                <p>{item.subTitle}</p>
              </InfoCardStyles.TextTitle>
            </InfoCardStyles.TextRow>
            <img src={item.img} alt="card" />
          </InfoCardStyles.InfoItem>
        ))}
      </InfoCardStyles.Wrapper>
    </InfoCardStyles.Container>
  );
};

export default InfoCards;
