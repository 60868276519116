// eslint-disable-next-line import/prefer-default-export
export const ROUTES = {
  HOME: '/',
  REPORT: '/report/:id',
  REPORTS: '/reports',
  PROFILE: '/user-profile',
  FAQ: '/faq',
  ABOUT: '/about',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  GLOSSARY: '/glossary',
  REWARDS: '/rewards',
} as const;
