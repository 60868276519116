import React from 'react';
import { EToasterType } from 'shared/enums';
import styles from './styles';
import determineToasterType from './helpers';

const ToastContent = (type: EToasterType, text: string) => {
  const icon = determineToasterType(type);
  return (
    <styles.Container>
      <styles.Icon src={icon} alt="toaster icon" />
      <styles.Description>
        <p>{text}</p>
      </styles.Description>
    </styles.Container>
  );
};

export default ToastContent;
