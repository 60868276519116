import React, { useState } from 'react';

import { DropdownNetwork } from 'shared/enums';

import NetworkDropdown from 'shared/components/Dropdown/NetworkDropdown';
import OutsideClickWrapper from 'shared/components/OutsideClickWrapper';

import config, { networks } from 'services/config';
import { useAccount } from 'wagmi';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import initialLoading from 'store/actions/initialLoading';
import { getChainIcon } from 'shared/utils/networks';
import switchNetwork from 'shared/utils/switchNetwork';
import { getAccount } from '@wagmi/core';
import { selectCurrentChainId, setCurrentChainId } from 'store/slices/networks';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import NetworkStyles from './styles';

const NetworkHandler: React.FC = () => {
  const [showDrop, setShowDrop] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { chain } = useAccount();
  const { isConnected } = getAccount(config);
  const currentChainId = useAppSelector(selectCurrentChainId);

  const handleDropdown = () => {
    setShowDrop(!showDrop);
  };

  const onChange = async (network: DropdownNetwork) => {
    const pendingChainId = networks[network]?.chainIdNumber;
    if (pendingChainId && chain?.id !== pendingChainId) {
      try {
        if (isConnected) {
          const { isSuccess } = await switchNetwork(pendingChainId);
          if (isSuccess) {
            dispatch(setCurrentChainId(pendingChainId));
          }
        } else {
          dispatch(setCurrentChainId(pendingChainId));
        }
      } finally {
        dispatch(initialLoading());
        setShowDrop(false);
      }
    }
  };

  return (
    <OutsideClickWrapper clickHandler={() => setShowDrop(false)}>
      <NetworkStyles.ButtonWrapper>
        <NetworkStyles.HandlerButton onClick={handleDropdown}>
          <NetworkStyles.NetworkIcon src={getChainIcon(currentChainId)} alt="network icon" />
          <NetworkStyles.Arrow />
          {showDrop && <NetworkDropdown onChange={onChange} elements={Object.values(networks)} title="Select Network" />}
        </NetworkStyles.HandlerButton>
      </NetworkStyles.ButtonWrapper>
    </OutsideClickWrapper>
  );
};

export default NetworkHandler;
