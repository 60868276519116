import React from 'react';

import ButtonStyles from './styles';
import { IButtonProps } from './interface';
import Loading from './LoadingSpinner';
import getButtonTextColor from './utils';

const Button: React.FC<IButtonProps> = ({
  label,
  handleClick,
  variant,
  disabled = false,
  styles,
  type = 'button',
  iconLeft,
  iconRight,
  loading = false,
}): JSX.Element => {
  return (
    <ButtonStyles.Button disabled={disabled} type={type} onClick={handleClick} variant={variant} styles={styles}>
      {loading ? (
        <>
          <span>Processing</span>
          <Loading spinnerColor={getButtonTextColor(variant)} />
        </>
      ) : (
        <>
          {iconLeft || null}
          {label}
          {iconRight || null}
        </>
      )}
    </ButtonStyles.Button>
  );
};

export default Button;
