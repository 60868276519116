import { createAsyncThunk } from '@reduxjs/toolkit';

import { updateToken } from 'store/slices/token';
import {
  Address, erc20Abi,
} from 'viem';

import Big from 'big.js';
import ToastService from 'services/toast-service';
import { getClient, getConfig } from 'services/config';

import { RootState } from '../index';

const loadTokenData = createAsyncThunk<void, Address, { state: RootState }>(
  'loadTokenData',
  async (address, { dispatch, getState }) => {
    try {
      const chainId = getState().networks.currentChainId;
      const tokenContractConfig = {
        address,
        abi: erc20Abi,
      };

      const publicClient = getClient(chainId);

      const [balance, decimal, symbol] = await Promise.all([
        publicClient.readContract({
          ...tokenContractConfig,
          functionName: 'balanceOf',
          args: [getConfig(chainId).contract],
        }),
        publicClient.readContract({
          ...tokenContractConfig,
          functionName: 'decimals',
        }), publicClient.readContract({
          ...tokenContractConfig,
          functionName: 'symbol',
        }),
      ]);

      dispatch(
        updateToken({
          contractBalance: Big(balance.toString()),
          decimal,
          symbol,
          address,
        }),
      );
    } catch (e) {
      console.error(`Error: while loading ${address} token \n ${e}`);
      ToastService.error({ text: 'Error: while loading token metadata' });
    }
  },
);

export default loadTokenData;
