import React, {
  Dispatch,
  SetStateAction,
  useCallback, useEffect, useState,
} from 'react';

import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectReports } from 'store/slices/reports';
import { toArray } from 'shared/utils';

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';

import { EButtonActions } from 'shared/enums';
import updateReportPage from 'store/actions/updateReportPage';
import { IFilter } from 'shared/interfaces';
import InputPanel from 'shared/components/StyledInput';
import Button from 'shared/components/Buttons';
import ReportStyles from '../../styles';

interface ISearchReports {
  filter: IFilter,
  resetPages: () => void,
  showReportsAmount: (isShow: boolean) => void,
  searchValue: string,
  setSearchValue: Dispatch<SetStateAction<string>>,
}

const SearchReports: React.FC<ISearchReports> = ({
  filter,
  resetPages,
  showReportsAmount,
  searchValue,
  setSearchValue,
}) => {
  const dispatch = useAppDispatch();
  const { list: reports } = useAppSelector(selectReports);
  const reportsArray = toArray(reports);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState<boolean>(false);

  const handleSearchChange = useCallback(() => {
    resetPages();
    dispatch(updateReportPage({ page: 1, filter, searchQuery: searchValue }));
    showReportsAmount(!!searchValue);
    setIsSearchButtonClicked(true);
  }, [dispatch, filter, searchValue, resetPages, showReportsAmount]);

  useEffect(() => {
    if (isSearchButtonClicked && !searchValue) { dispatch(updateReportPage({ page: 1, filter })); }
  }, [dispatch, filter, isSearchButtonClicked, searchValue]);

  return (
    <ReportStyles.SearchContainer>
      <InputPanel value={searchValue} setValue={setSearchValue} placeholder="Search" />
      <Button
        label="Search"
        handleClick={handleSearchChange}
        variant={EButtonActions.YELLOW_BUTTON}
        iconRight={<SearchIcon />}
        disabled={!searchValue || !reportsArray.length}
      />
    </ReportStyles.SearchContainer>
  );
};

export default SearchReports;
