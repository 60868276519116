import Big from 'big.js';

import { MIN_AMOUNT, ZERO_STRING } from '../constants';

const BASE_DECIMAL = 18;
const POW_BASE = 10;
const FORMAT_DOT = '.';
const FORMAT_COMMA = ',';

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes(FORMAT_DOT) || amount.includes(FORMAT_COMMA)) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export const displayAmount = (value?: Big, decimals = BASE_DECIMAL): string => {
  try {
    if (!value) return ZERO_STRING;
    const formattedValue = value.div(Big(POW_BASE).pow(decimals));
    if (Big(formattedValue.toString()).eq(ZERO_STRING)) return ZERO_STRING;
    if (Big(formattedValue).lt(MIN_AMOUNT)) return `<${MIN_AMOUNT}`;
    return removeTrailingZeros(formattedValue.toFixed());
  } catch (error) {
    console.error(`Error Big formatUnits \n ${error}`);
    return ZERO_STRING;
  }
};

export const stringToBigNumber = (value: string, decimals = BASE_DECIMAL): string => {
  return Big(value).mul(Big(POW_BASE).pow(decimals)).toFixed();
};
