import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { v4 as uuid } from 'uuid';
import { IReport } from 'shared/interfaces';
import ReportStatusLabel from 'shared/components/ReportStatusLabel';
import { addSpacesBetweenWords, copied } from 'shared/utils';
import {
  Category, EButtonActions, EQueryParams, Network, ReportStatus,
} from 'shared/enums';
import ReportListStyles from 'shared/components/ReportList/styles';
import { ReactComponent as COPY_ICON } from 'assets/images/icons/copy.svg';
import { ReactComponent as DECLINE_ICON } from 'assets/images/icons/criss-cross.svg';
import { ReactComponent as APPROVE_ICON } from 'assets/images/icons/approve-report-icon.svg';
import { ReactComponent as LINK_ICON } from 'assets/images/icons/external-link.svg';
import { DASH, EMPTY_STRING } from 'shared/constants';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectUser } from 'store/slices/user';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import approveReport from 'store/actions/approveReport';
import declineReport from 'store/actions/declineReport';
import { EModals, showModal } from 'store/slices/modals';
import { NetworkIconMap } from 'shared/components/Dropdown/interface';
import Button from 'shared/components/Buttons';
import apiService from 'services/api-service/api-service';
import ECheckAddressResponseStatuses from 'views/Main/components/CheckAddress/enum';
import getExplorerLink from 'shared/utils/getExplorerLink';
import styles from 'shared/components/ReportList/components/Report/styles';
import formatDate from 'shared/utils/formatDate';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import { selectCurrentChainId } from 'store/slices/networks';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const Report: React.FC<{ report: IReport; isReportPage?: boolean }> = ({
  report: {
    addr, reporter, category, id, status, reject_reason, network, url, proof, description, created_at, updated_at,
  },
  isReportPage = false,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isInvestigator } = useAppSelector(selectUser);
  const chainId = useAppSelector(selectCurrentChainId);

  const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false);
  const [isDeclineLoading, setIsDeclineLoading] = useState<boolean>(false);
  const [addressStatusMessage, setAddressStatusMessage] = useState<string>(DASH);

  const isReportRejected = status === ReportStatus.Declined;
  const isReportPending = status === ReportStatus.Pending;

  const onError = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    (e.target as HTMLInputElement).onerror = null;
    (e.target as HTMLInputElement).src = EMPTY_STRING;
  }, []);

  const handleClickApprove = async () => {
    try {
      setIsApproveLoading(true);
      await dispatch(approveReport({ id }));
    } catch (error) {
      console.error(`Error: while click on approving report \n ${error}`);
    } finally {
      setIsApproveLoading(false);
    }
  };

  const handleClickDecline = async (reason: string) => {
    try {
      setIsDeclineLoading(true);
      await dispatch(declineReport({ id, reason }));
    } catch (error) {
      console.error(`Error: while click on decline report \n ${error}`);
    } finally {
      setIsDeclineLoading(false);
    }
  };
  const searchParams = { [EQueryParams.CHAIN_ID]: chainId.toString() };
  const reportRoute = `${generatePath(ROUTES.REPORT, { id })}?${new URLSearchParams(searchParams).toString()}`;
  const reportPageUrl = `${window.location.origin}${reportRoute}`;

  const goToReport = () => {
    if (isReportPage) return;
    history.push(reportRoute);

    MixpanelService.trackEvent(EMixpanelEvents.REPORT, { id: id.toString(), addressReport: addr });
  };

  const checkAddress = useCallback(async () => {
    try {
      if (addr.trim().length === 0) return;
      const result = await apiService.checkAddress(addr);

      switch (result) {
        case ECheckAddressResponseStatuses.NOT_FOUND: {
          setAddressStatusMessage('Address has never been reported');
          break;
        }
        case ECheckAddressResponseStatuses.REJECTED: {
          setAddressStatusMessage('Address has been reported before, but the report was rejected');
          break;
        }
        case ECheckAddressResponseStatuses.ACCEPTED: {
          setAddressStatusMessage('Address has been reported before, and accepted');
          break;
        }
        case ECheckAddressResponseStatuses.HAPI: {
          setAddressStatusMessage('Address is reported to HAPI Protocol');
          break;
        }
        default:
          setAddressStatusMessage(DASH);
      }
    } catch (e) {
      console.warn('Error while trying to check address', e);
    }
  }, []);

  useEffect(() => {
    checkAddress();
  }, []);

  return (
    <ReportListStyles.Container>
      <ReportListStyles.Address>
        {addr}
      </ReportListStyles.Address>
      {!isReportPage && (
        <styles.OpenInExplorer href={getExplorerLink(addr, network)} target="_blank" rel="noreferrer">
          Open in explorer
          <LINK_ICON />
        </styles.OpenInExplorer>
      )}
      <ReportListStyles.InformationRow>
        <ReportStatusLabel status={status} />
        <ReportListStyles.IdContainer $isReportPage={isReportPage}>
          <ReportListStyles.SmallText onClick={goToReport}>{`ID: ${id}`}</ReportListStyles.SmallText>
          <CopyToClipboard onCopy={copied} text={reportPageUrl}>
            <COPY_ICON />
          </CopyToClipboard>
        </ReportListStyles.IdContainer>
        <ReportListStyles.BorderText>{addSpacesBetweenWords(Category[category])}</ReportListStyles.BorderText>
        <ReportListStyles.BorderText>
          <img src={NetworkIconMap[network]} alt="network icon" />
          {Network[network]}
        </ReportListStyles.BorderText>
      </ReportListStyles.InformationRow>
      <ReportListStyles.DetailsRow>
        <ReportListStyles.SmallText>
          Reported by
          {' '}
          {reporter}
        </ReportListStyles.SmallText>
        {created_at && (
        <ReportListStyles.SmallText>
          Created:
          {' '}
          {formatDate(created_at)}
        </ReportListStyles.SmallText>
        )}
        {updated_at && (
        <ReportListStyles.SmallText>
          Updated:
          {' '}
          {formatDate(updated_at)}
        </ReportListStyles.SmallText>
        )}
      </ReportListStyles.DetailsRow>
      <ReportListStyles.GeneralBlock>
        <ReportListStyles.Link href={url} target="_blank" rel="noreferrer">
          {url}
        </ReportListStyles.Link>
        <ReportListStyles.MiddleText>{description}</ReportListStyles.MiddleText>
        <ReportListStyles.PhotosWrapper>
          {proof.map((source) => (
            <a key={uuid()} href={source} target="_blank" rel="noreferrer">
              <img src={source} onError={onError} alt="proof" />
            </a>
          ))}
        </ReportListStyles.PhotosWrapper>
        <styles.StatusAndButtonsWrapper>
          <styles.AddressStatus>
            Address check:
            {' '}
            {addressStatusMessage}
          </styles.AddressStatus>
          {isInvestigator && isReportPending && (
          <ReportListStyles.ButtonWrapper>
            <Button
              label="Decline"
              loading={isDeclineLoading}
              disabled={isDeclineLoading || isApproveLoading}
              handleClick={() => dispatch(
                showModal({
                  modal: EModals.DECLINE_REASONS_MODAL,
                  props: {
                    handleClick: handleClickDecline,
                  },
                }),
              )}
              iconLeft={<DECLINE_ICON />}
              variant={EButtonActions.RED_BUTTON}
            />
            <Button
              label="Approve"
              loading={isApproveLoading}
              disabled={isApproveLoading || isDeclineLoading}
              handleClick={handleClickApprove}
              iconLeft={<APPROVE_ICON />}
              variant={EButtonActions.GREEN_BUTTON}
            />
          </ReportListStyles.ButtonWrapper>
          )}
        </styles.StatusAndButtonsWrapper>
        {isReportRejected && reject_reason && <ReportListStyles.RejectReason>{reject_reason}</ReportListStyles.RejectReason>}
      </ReportListStyles.GeneralBlock>
    </ReportListStyles.Container>
  );
};

export default Report;
