import { createAsyncThunk } from '@reduxjs/toolkit';
import { REPORTS_PER_PAGE_INVESTIGATOR } from 'shared/constants';

import { toMap } from 'shared/utils';
import formatIndexerReport, { IIndexerReport } from 'shared/utils/formatIndexerReport';

import apiService from 'services/api-service/api-service';
import { IFilter } from 'shared/interfaces';
import { RootState } from '../index';
import { setReports, setNotFoundReports } from '../slices/reports';

const updateReportPage = createAsyncThunk<
  void,
  { page: number, filter?: IFilter, searchQuery?: string },
  { state: RootState }
>('updateReportPage', async ({ page, filter, searchQuery }, { dispatch, getState }) => {
  try {
    const {
      networks: { currentChainId },
      user: { isInvestigator },
    } = getState();
    if (!isInvestigator) {
      dispatch(setReports({ list: {}, amount: 0, notFound: false }));
      return;
    }

    const { items: reports, total: reportsAmount } = await apiService.getReports({
      chain_id: currentChainId,
      skip: (page - 1) * REPORTS_PER_PAGE_INVESTIGATOR,
      status: filter?.status || undefined,
      category: filter?.category || undefined,
      address_network: filter?.network || undefined,
      search: searchQuery,
    });
    if (!reportsAmount && !!searchQuery) {
      dispatch(setNotFoundReports(true));
      return;
    }
    const reportsArray = reports.map((report: IIndexerReport) => formatIndexerReport(report));
    const reportsMap = toMap(reportsArray, 'id');

    dispatch(setReports({ list: reportsMap, amount: reportsAmount, notFound: false }));
  } catch (e) {
    console.error(`Error: while updating all reports \n ${e}`);
  }
});

export default updateReportPage;
