import React, { useRef } from 'react';
import PaginationStyled from './styles';
import { PaginationProps } from './interface';

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages && inputRef && inputRef.current) {
      onPageChange(newPage);
      inputRef.current.value = newPage.toString();
    }
  };

  const handleFirstPage = () => handlePageChange(1);
  const handleLastPage = () => handlePageChange(totalPages);
  const handlePreviousPage = () => handlePageChange(currentPage - 1);
  const handleNextPage = () => handlePageChange(currentPage + 1);

  const changePage = (inputPage: string) => {
    const page = Number(inputPage);
    const selectedPage = Math.min(Math.max(page, 1), totalPages);
    inputPage.length && handlePageChange(selectedPage);
  };

  const blurHandler = () => {
    !inputRef.current?.value.length && handlePageChange(currentPage);
  };

  return (
    <PaginationStyled.Pagination>
      <button type="button" aria-label="First" onClick={handleFirstPage} disabled={currentPage === 1} className="prev">
        <PaginationStyled.DoubleArrowIcon />
      </button>
      <button
        type="button"
        aria-label="Previous"
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        className="prev"
      >
        <PaginationStyled.ArrowIcon />
      </button>
      <input
        type="number"
        ref={inputRef}
        key={currentPage}
        defaultValue={currentPage}
        onBlur={blurHandler}
        onChange={(event) => changePage(event.target.value)}
      />
      <button type="button" aria-label="Next" onClick={handleNextPage} disabled={currentPage === totalPages}>
        <PaginationStyled.ArrowIcon />
      </button>
      <button type="button" aria-label="Last" onClick={handleLastPage} disabled={currentPage === totalPages}>
        <PaginationStyled.DoubleArrowIcon />
      </button>
      <span>{`of ${totalPages}`}</span>
    </PaginationStyled.Pagination>
  );
};

export default Pagination;
