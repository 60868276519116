import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

export const claimButton: FlattenSimpleInterpolation = css`
  margin-left: 1rem;
  font-weight: 700;
  line-height: 140%;
`;

export const avatarStyles: FlattenSimpleInterpolation = css`
  width: 5.25rem;
  height: 5.25rem;
  ${({ theme }) => theme.media.tablet`
    width: 3rem;
    height: 3rem;
  `}
` as FlattenSimpleInterpolation;
