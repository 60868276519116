import styled from 'styled-components';

const Stage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0;
  border-radius: 1rem;
  background: ${({ theme }) => theme.grey};
  font-size: 0.75rem;
  font-weight: 600;
  width: 4rem;
  margin-top: 1rem;
`;

const HeatTitle = styled.div`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 400;
  margin-top: 0.25rem;
`;

const SidePadding = styled.div`
  padding: 0 0.75rem;
`;

const Explain = styled.div`
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 400;
  margin-top: 0.25rem;
`;

export default {
  HeatTitle,
  Stage,
  SidePadding,
  Explain,
};
