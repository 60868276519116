import { createAsyncThunk } from '@reduxjs/toolkit';

import wagmiConfig, { chains, getConfig } from 'services/config';
import { scamfariABI } from 'services/config/contract_abi';
import ToastService from 'services/toast-service';

import { readContracts } from '@wagmi/core';
import { setDataByNetwork } from 'store/slices/networks';
import formatReporter, { IContractReporter } from 'shared/utils/formatReporter';
import formatReport, { IContractReport } from 'shared/utils/formatReport';
import { RootState } from '../index';

const loadDataByNetwork = createAsyncThunk<void, void, { state: RootState }>(
  'networks/loadDataByNetwork',
  async (_, { dispatch, getState }) => {
    const {
      networks: { currentChainId },
    } = getState();

    try {
      const currentChain = chains.find((chain) => chain.id === currentChainId);
      if (!currentChain) return ToastService.error({ text: 'Unsupported network' });

      const config = {
        address: getConfig(currentChainId).contract,
        abi: scamfariABI,
        chainId: currentChainId,
      };

      const contractFunctionNames = [
        'getTopReporters',
        'getLastReports',
        'accepted_reports_count',
        'report_count',
        'reporters_count',
      ];

      const contracts = contractFunctionNames.map((functionName) => ({
        ...config,
        functionName,
      }));

      const data = await readContracts(wagmiConfig, { contracts });

      if (data.find((item) => item.status === 'failure')) return;
      const [topReporters, lastReports, accepted_reports_count, report_count, reporters_count] = data;

      dispatch(
        setDataByNetwork({
          [currentChainId]: {
            reportsCounter: Number(report_count.result),
            reportersCounter: Number(reporters_count.result),
            acceptedReportsCounter: Number(accepted_reports_count.result),
            lastReports: (lastReports.result as unknown as IContractReport[]).map((report) => formatReport(report)),
            topReporters: (topReporters.result as IContractReporter[]).map((reporter) => formatReporter(reporter)),
          },
        }),
      );
    } catch (e) {
      console.error(`Error: while loading data by network, chainId: ${currentChainId} \n ${e}`);
      ToastService.error({ text: `Error: while loading data by network, chainId: ${currentChainId}` });
    }
  },
);

export default loadDataByNetwork;
