import { UserRejectedRequestError } from 'viem';

export default function userRejectedErrorHandler(err: any) {
  if (err instanceof UserRejectedRequestError) return true;

  if (err && typeof err === 'object') {
    if (
      ('code' in err && (err.code === 4001 || err.code === 'ACTION_REJECTED'))
      || ('cause' in err && 'code' in err.cause && err.cause.code === 4001)
    ) {
      return true;
    }
  }

  return false;
}
