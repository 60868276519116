import SepoliaIcon from 'assets/images/icons/networks/sepolia.svg';
import GoerliIcon from 'assets/images/icons/networks/goerli.svg';
import zkSyncIcon from 'assets/images/icons/networks/ZKSync.svg';
import { DropdownNetwork } from 'shared/enums';
import { INetworks } from 'services/config/interface';

const networks: INetworks = {
  [DropdownNetwork.Ethereum]: {
    chainIdNumber: 11155111,
    infuraApiRoute: 'https://sepolia.infura.io/v3/',
    iconPath: SepoliaIcon,
    name: DropdownNetwork.Ethereum,
    active: true,
  },
  [DropdownNetwork.Linea]: {
    chainIdNumber: 5,
    infuraApiRoute: 'https://goerli.infura.io/v3/',
    iconPath: GoerliIcon,
    name: DropdownNetwork.Linea,
    active: true,
  },
  [DropdownNetwork.zkSync]: {
    chainIdNumber: 280,
    infuraApiRoute: 'https://testnet.era.zksync.dev',
    iconPath: zkSyncIcon,
    name: DropdownNetwork.zkSync,
    active: false,
    addChainData: {
      chainId: `0x${Number(280).toString(16)}`,
      chainName: 'zkSync Era Testnet',
      blockExplorerUrls: ['https://goerli.explorer.zksync.io/'],
      rpcUrls: ['https://testnet.era.zksync.dev'],
      nativeCurrency: {
        decimals: 18,
        name: 'ETH',
        symbol: 'ETH',
      },
    },
  },
};

export default networks;
