import React from 'react';
import { selectUser } from 'store/slices/user';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { MixpanelService } from 'services/mixpanel';
import ListItemWithSubmenu from 'shared/components/NavigationList/components/ListItemWithSubmenu';
import { ADMIN_ROUTES, DEFAULT_ROUTES } from 'shared/constants/navigation';
import styles from './styles';

const NavigationList: React.FC<{ closeModal?: () => void | undefined }> = ({ closeModal }) => {
  const { isInvestigator } = useAppSelector(selectUser);
  const routesList = isInvestigator ? [...ADMIN_ROUTES, ...DEFAULT_ROUTES] : [...DEFAULT_ROUTES];

  const clickHandler = (route: string) => {
    closeModal && closeModal();
    MixpanelService.trackEvent(`Navigate to ${route}`);
  };

  return (
    <styles.List>
      {routesList.map(({ name, route, submenu }) => (
        <div key={name}>
          {route ? (
            <styles.ListItem to={route} onClick={() => clickHandler(name)} activeClassName="active">
              {name}
            </styles.ListItem>
          ) : (
            submenu && <ListItemWithSubmenu name={name} submenu={submenu} />
          )}
        </div>
      ))}
    </styles.List>
  );
};

export default NavigationList;
