import { ICheckAddressResponse } from 'views/Main/components/CheckAddress/interface';
import { REPORTS_PER_PAGE_INVESTIGATOR } from 'shared/constants';
import ECheckAddressResponseStatuses from 'views/Main/components/CheckAddress/enum';
import { IIndexerReport } from 'shared/utils/formatIndexerReport';
import { EReportSortDir, IReportsIndexerParams } from './interfaces/report';
import { apiUrl } from '../config';
import EApiRoutes from './enum';

class ApiService {
  private baseUrl: string | undefined;

  constructor() {
    this.baseUrl = apiUrl;
  }

  public async get(path: string): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}${path}`);
      return response.json();
    } catch (error) {
      throw new Error(`Error during the request to ${this.baseUrl}${path}: ${error}`);
    }
  }

  public async checkAddress(query: string): Promise<ECheckAddressResponseStatuses> {
    try {
      const { status } = (await this.get(`${EApiRoutes.CHECK_ADDRESS}${query.trim()}`)) as ICheckAddressResponse;
      return status as ECheckAddressResponseStatuses;
    } catch (error) {
      throw new Error(`Error during the request to check address: ${error}`);
    }
  }

  public async getReports(params: IReportsIndexerParams): Promise<{items: IIndexerReport[], total: number}> {
    try {
      const query = new URLSearchParams({
        take: REPORTS_PER_PAGE_INVESTIGATOR.toString(),
        sort_dir: EReportSortDir.ASC,
      });

      Object.keys(params).forEach((param) => {
        const paramValue = params[param as keyof IReportsIndexerParams];

        if (paramValue && query.has(param)) {
          query.set(param, paramValue.toString());
        } else if (paramValue) {
          query.append(param, paramValue.toString());
        }
      });

      return await this.get(`${EApiRoutes.REPORTS}?${query}`);
    } catch (error) {
      throw new Error(`Error during the request to ${this.baseUrl}${EApiRoutes.REPORTS}: ${error}`);
    }
  }
}

const apiService = new ApiService();
export default apiService;
