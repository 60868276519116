import { IReporter } from '../interfaces';

export interface IContractReporter {
    addr: `0x${string}`;
    username: string;
    score: bigint;
}

const formatReporter = (reporter: IContractReporter): IReporter => {
  const { addr, score, username } = reporter;

  return {
    addr,
    score: Number(score),
    username,
  };
};

export default formatReporter;
