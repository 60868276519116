import React from 'react';
import { ROUTES } from 'routes/constants';
import { INavigation } from 'shared/components/NavigationList/interface';
import Info from 'shared/components/Submenu/options/Info';
import { ERoutes } from 'shared/enums';

export const DEFAULT_ROUTES: INavigation[] = [
  {
    name: ERoutes.REWARDS,
    route: ROUTES.REWARDS,
  },
  {
    name: 'Info',
    submenu: <Info />,
  },
];

export const INFO_NAVIGATION: INavigation[] = [
  {
    name: ERoutes.GLOSSARY,
    route: ROUTES.GLOSSARY,
  },
  {
    name: ERoutes.FAQs,
    route: ROUTES.FAQ,
  },
  {
    name: ERoutes.ABOUT,
    route: ROUTES.ABOUT,
  },
];

export const ADMIN_ROUTES: INavigation[] = [
  {
    name: ERoutes.REPORTS,
    route: ROUTES.REPORTS,
  },
];
