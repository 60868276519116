import styled from 'styled-components';
import { css } from 'styled-components/macro';

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 39.5rem;
  width: 100%;

  ${({ theme }) => theme.media.mobile`
    padding: 0;
  `}
`;

const Container = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.borderBlack};
`;

const Address = styled.div`
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  line-height: 140%;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.lightWhite};
`;

const InformationRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
  gap: 0.75rem;
  margin-top: 1rem;
  ${({ theme }) => css`
    ${theme.media.mobile(`
      flex-wrap: wrap;
      flex-direction: column;
       align-items: baseline;
        > div {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 8px;
          width: auto;
        }
    `)}
  `}
`;

const TextProperty = styled.div`
  color: ${({ theme }) => theme.lightGrey};
  font-family: ${({ theme }) => theme.interRegular};
  word-wrap: break-word;
`;

const MiddleText = styled(TextProperty)`
  font-size: 0.813rem;
`;

const SmallText = styled(TextProperty)`
  font-size: 0.75rem;
`;

const DetailsRow = styled.div`
  gap: 0.5rem;
  display: flex;
  padding: 1.1rem 0 0;
  justify-content: space-between;
  flex-direction: row;

  ${({ theme }) => css`
    ${theme.media.mobile(`
        flex-direction: column;
      `)}
  `}
`;

const BorderText = styled(TextProperty)`
  border: 1px solid ${({ theme }) => theme.opacityBorderGrey};
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  & img {
    margin-right: 0.406rem;
    max-height: 0.75rem;
  }
`;

const WhiteText = styled.div<{ isShow: boolean }>`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interRegular};
  align-items: center;
  display: flex;
  cursor: pointer;
  font-weight: 600;
  line-height: 140%;
  font-size: 0.75rem;
  & img {
    margin-left: 0.45rem;
    transform: ${({ isShow }) => (isShow ? 'rotate(180deg)' : 'unset')};
  }
`;

const Link = styled.a`
  font-family: ${({ theme }) => theme.interSemiBold};
  font-size: 0.813rem;
  color: ${({ theme }) => theme.blue};
  display: block;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GeneralBlock = styled.div`
  margin-top: 1rem;
`;

const PhotosWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 0.75rem;
  & a {
    width: 3rem;
    height: 3rem;
  }
  & img {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    object-fit: cover;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 0 0 0 auto;
  justify-content: flex-end;
  gap: 1rem;
  & > button {
    font-family: ${({ theme }) => theme.interBold};
    min-width: 7.6rem;
    height: 3rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
    & img {
      margin-right: 0.77rem;
    }
    ${({ theme }) => css`
      ${theme.media.mobile(`
        flex-wrap: wrap;
      `)}
    `}
  }
`;

const RejectReason = styled.div`
  padding: 0.5rem 0.62rem;
  border-radius: 0.625rem;
  font-size: 0.75rem;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.declinedRed};
  margin-top: 1.5rem;
  display: inline-block;
  color: ${({ theme }) => theme.declinedRed};
`;

const EmptyReport = styled.div`
  display: flex;
  margin-top: 9rem;
  align-items: center;
  flex-direction: column;

  & img {
    width: 2.5rem;
    height: 2.5rem;
    margin: auto;
  }

  & span {
    text-align: center;
    display: block;
    margin-top: 1.12rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 120%;
    color: ${({ theme }) => theme.lightFontGrey};
  }
`;

const List = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.mobile`padding: 0 1rem;`}
`;

const IdContainer = styled.div<{ $isReportPage?: boolean }>`
  gap: 0.25rem;
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:hover {
      opacity: 0.8;
    }
  }

  div {
    cursor: ${({ $isReportPage }) => ($isReportPage ? 'unset' : 'pointer')};
    transition: ${({ theme }) => theme.transitions.hoverTransition};

    &:hover {
      opacity: ${({ $isReportPage }) => ($isReportPage ? 'unset' : '0.8')};
    }
  }
`;

export default {
  Wrapper,
  Container,
  Address,
  InformationRow,
  MiddleText,
  SmallText,
  DetailsRow,
  BorderText,
  WhiteText,
  Link,
  GeneralBlock,
  ButtonWrapper,
  PhotosWrapper,
  RejectReason,
  EmptyReport,
  List,
  IdContainer,
};
