import styled from 'styled-components';
import { css } from 'styled-components/macro';

const Title = styled.p`
  padding-top: 9rem;
  font-size: 2.75rem;
  text-align: center;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interBlack};

  ${({ theme }) => css`
    ${theme.media.mobile(`
       font-size: 1.5rem;
       padding-top: 6rem;
    `)}
  `}
`;

const Wrapper = styled.div`
  margin: 4.5rem auto 0;
  display: grid;
  justify-content: center;
  align-items: center;
  max-width: 55rem;
  flex-wrap: wrap;

  grid-template-columns: 1fr 1fr 1fr 1fr;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 10rem;
    justify-self: center;
    img {
      max-height: 2.5rem;
    }
  }

  > div:nth-last-child(2) {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
    img {
      max-height: 4.0625rem;
    }
  }

  > div:last-child {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
    img {
      max-height: 4.0625rem;
    }
  }

  ${({ theme }) => css`
    ${theme.media.tablet(`
    margin-top: 3rem;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15rem;
      height: 10rem;
      justify-self: center;
      img {
        max-height: 2.5rem;
      }
    }
    > div:last-child {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 4;
      img {
        max-height: 4.0625rem;
      }
    }
    `)}
  `}

  ${({ theme }) => css`
    ${theme.media.mobile(`
    margin-top: 3rem;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 9.75rem;
      height: 6.5rem;
      justify-self: center;
      img {
        max-height: 1.5625rem;
      }
    }
  > div:nth-last-child(2) {
      grid-column-start: 1;
      grid-column-end: 1;
      grid-row-start: 5;
      grid-row-end: 5;
      img {
        max-height: 1.5625rem;
      }
    }
    > div:last-child {
      grid-column-start: 2;
      grid-column-end: 2;
      grid-row-start: 5;
      grid-row-end: 5;
      img {
        max-height: 2.5rem;
      }
    }
    `)}
  `}
`;

const ProjectLogo = styled.img`
  width: 12.5rem;
  height: 3.75rem;
`;

const Link = styled.div`
  /* display: block; */
  cursor: pointer;
`;

export default {
  Title,
  Wrapper,
  ProjectLogo,
  Link,
};
