import styled from 'styled-components';

const Stats = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 7.5rem;
  & div {
    text-align: center;
    margin-right: 6.75em;
    ${({ theme }) => theme.media.mobile`
      margin-right: 0;
      margin-top: 2.25rem;
  `}
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-top: 0;
    }
    & span {
      display: block;
    }
  }
  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    margin-top: 0;
  `}
`;

const StatsValue = styled.span`
  font-size: 6rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.48rem;
  color: ${({ theme }) => theme.red};
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${({ theme }) => theme.interBold};
  font-feature-settings: 'cv10' on, 'cv09' on, 'cv04' on, 'cv03' on;
`;

const StatsName = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interBold};
`;

export default {
  StatsValue,
  StatsName,
  Stats,
};
