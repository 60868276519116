import styled, { FlattenSimpleInterpolation } from 'styled-components';

const Wrapper = styled.div<{ isHunter?: boolean; styles: FlattenSimpleInterpolation }>`
  position: relative;
  border-radius: 1.25rem;
  margin-left: ${({ isHunter }) => (isHunter ? '0' : '1rem')};
  width: ${({ isHunter }) => (isHunter ? '3.75rem' : '2.5rem')};
  height: ${({ isHunter }) => (isHunter ? '3.75rem' : '2.5rem')};
  transition: ${({ theme }) => theme.transitions.hoverTransition};
  cursor: ${({ isHunter }) => (isHunter ? 'default' : 'pointer')};
  &:hover {
    opacity: ${({ isHunter }) => (isHunter ? '1' : '0.9')};
  }

  ${({ theme, isHunter }) => theme.media.tablet(`
    width: ${isHunter ? '3.75rem' : '2rem'};
    height: ${isHunter ? '3.75rem' : '2rem'};
    margin-left: ${isHunter ? '0' : '0.5rem'};
  `)};

  ${({ theme, isHunter }) => theme.media.mobile(`
    width: ${isHunter ? '2.5rem' : '2rem'};
    height: ${isHunter ? '2.5rem' : '2rem'};
  `)};

  ${({ styles }) => styles};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const ApproveIcon = styled.img`
  position: absolute;
  top: -0.1rem;
  right: 0;
  width: 1rem;
  height: 1rem;
`;

export default {
  Wrapper,
  Image,
  ApproveIcon,
};
