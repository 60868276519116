import { EMPTY_STRING, ZERO_STRING } from 'shared/constants';

function formatDate(inputDate: string): string {
  const inputDateTime = new Date(inputDate);
  const userTimezoneOffset = inputDateTime.getTimezoneOffset();
  const inputDateTimeLocal = new Date(inputDateTime.getTime() - userTimezoneOffset * 60 * 1000);
  const now = new Date();
  const timeDifference = now.getTime() - inputDateTimeLocal.getTime();
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (hoursDifference < 24) {
    return hoursDifference === 0
      ? 'Less than an hour ago'
      : `${hoursDifference} hour${hoursDifference === 1 ? EMPTY_STRING : 's'} ago`;
  }

  const dd = String(inputDateTime.getDate()).padStart(2, ZERO_STRING);
  const mm = String(inputDateTime.getMonth() + 1).padStart(2, ZERO_STRING);
  const yyyy = inputDateTime.getFullYear();
  const hours = String(inputDateTime.getHours()).padStart(2, ZERO_STRING);
  const minutes = String(inputDateTime.getMinutes()).padStart(2, ZERO_STRING);

  return `${dd}.${mm}.${yyyy} ${hours}:${minutes}`;
}

export default formatDate;
