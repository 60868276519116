import React from 'react';
import { isDesktop as checkDesktopDimension, useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import Desktop from './components/Desktop';
import FooterStyles from './styles';
import Mobile from './components/Mobile';

const Footer: React.FC = () => {
  const dimension = useWindowDimensions();
  const isDesktop = checkDesktopDimension(dimension);
  return (
    <FooterStyles.Container>
      {
        isDesktop ? (<Desktop />) : (<Mobile />)
      }
    </FooterStyles.Container>
  );
};

export default Footer;
