import React from 'react';
import { useFormContext } from 'react-hook-form';
import { tooltipForm } from 'shared/components/ReportStages/SecondStage/constants';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info-circle.svg';

import TextAreaStyles from './styles';
import { ITextarea } from './interface';
import { IBasic } from '../ReportStages/SecondStage';
import Tooltip from '../Tooltip';

const Textarea: React.FC<ITextarea> = ({ label, name }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IBasic>();
  return (
    <div>
      <TextAreaStyles.Label>
        <Tooltip tooltip={tooltipForm.description} iconRight={<InfoIcon />}>
          {label}
        </Tooltip>
      </TextAreaStyles.Label>
      <TextAreaStyles.Textarea
        error={Boolean(errors?.description)}
        {...register(name)}
      />

      {errors?.description && (
        <TextAreaStyles.Error>
          {errors.description.message}
        </TextAreaStyles.Error>
      )}
    </div>
  );
};

export default Textarea;
