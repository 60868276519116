import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

const Label = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.lightGrey};
`;

const UploadedPhotos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const UploadedFile = styled.div`
  background: ${({ theme }) => theme.grey};
  border-radius: 0.5rem;
  padding: 0.25rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  & > img {
    flex: 0 0 auto;
  }
`;

const UploadedTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  min-width: 0;
  flex: 1;
`;

const PhotoName = styled.span`
  color: ${({ theme }) => theme.white};
  font-size: 0.8125rem;
  font-weight: 400;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DownloadSizeText = styled.span`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 0.8125rem;
  font-weight: 400;
  display: block;
`;

const Close = styled(CloseIcon)`
  flex: 0 0 auto;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.errorRed};
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.errorRed};
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
  display: block;
`;

export default {
  Label,
  UploadedPhotos,
  UploadedFile,
  UploadedTextWrapper,
  PhotoName,
  DownloadSizeText,
  Close,
  Error,
};
