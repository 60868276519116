import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  & > div:first-child {
    div {
      flex: 1;
      text-align: center;
    }
  }
`;

const WrapperButtons = styled.div`
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  ${({ theme }) => theme.media.mobile`
    button {
      &:last-child {
        width: 100%;
      }
    }
  `}
`;

export default {
  Container,
  WrapperButtons,
};
