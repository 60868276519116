import React, { useState } from 'react';

import FilterPanel from 'shared/components/FilterPanel';
import ModalWrapper from 'shared/components/modals/ModalWrapper';
import ModalsHeader from 'shared/components/modals/components/ModalsHeader';
import Button from 'shared/components/Buttons';
import { IFilter, IFilterModal } from 'shared/interfaces';
import { EButtonActions } from 'shared/enums';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as ApproveIcon } from 'assets/images/icons/approve-report-icon.svg';
import { DEFAULT_FILTER_OPTIONS } from 'shared/components/FilterPanel/constants';

import styles from './styles';

const FilterModal: React.FC<IFilterModal> = ({ closeModal, handleChangeFilter, filter }: IFilterModal) => {
  const [activeFilter, setFilter] = useState<IFilter>(filter);

  const clear = () => {
    handleChangeFilter(DEFAULT_FILTER_OPTIONS);
    setFilter(DEFAULT_FILTER_OPTIONS);
    closeModal();
  };

  const apply = () => {
    handleChangeFilter(activeFilter);
    closeModal();
  };

  return (
    <ModalWrapper closeModal={closeModal} shouldClose={false} width="39.375rem">
      <styles.Container>
        <ModalsHeader closeModal={closeModal} title="Filter" />
        <FilterPanel
          filter={activeFilter}
          handleChangeFilter={(retrievedFilters) => setFilter(retrievedFilters)}
          isModal
        />
        <styles.WrapperButtons>
          <Button
            label="Clear"
            handleClick={clear}
            variant={EButtonActions.RED_BUTTON}
            iconLeft={<CloseIcon />}
          />
          <Button
            label="Apply"
            handleClick={apply}
            variant={EButtonActions.YELLOW_BUTTON}
            iconLeft={<ApproveIcon />}
          />
        </styles.WrapperButtons>
      </styles.Container>
    </ModalWrapper>
  );
};

export default FilterModal;
