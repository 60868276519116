import styled from 'styled-components';

const Textarea = styled.textarea<{ error?: boolean }>`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme, error }) => (!error ? theme.lightGreyBorder : theme.declinedRed)};
  color: ${({ theme }) => theme.white};
  background: transparent;
  outline: none;
  height: 6.63rem;
  resize: none;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.5rem;
  &:focus {
    border: 1px solid ${({ theme }) => theme.yellow};
    box-shadow: 0 0 0 2px rgba(253, 238, 45, 0.4);
    transition: 0.5s border;
  }
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  font-size: 0.75rem;
  font-style: normal;
  color: ${({ theme }) => theme.lightGrey};
`;

const Error = styled.span`
  color: ${({ theme }) => theme.errorRed};
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
  display: block;
`;

export default {
  Textarea,
  Label,
  Error,
};
