import React from 'react';
import { DropdownNetwork, EDimensions } from 'shared/enums';
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import styles from './styles';
import data from './data';

interface IHeaderBannerProps {
  network: DropdownNetwork;
  closeHandler: () => void;
}

const HeaderBanner: React.FC<IHeaderBannerProps> = ({ network, closeHandler }: IHeaderBannerProps) => {
  const dimension = useWindowDimensions();
  const isMobile = dimension === EDimensions.SMALL;

  const NetworkLogo = data[network].logo;
  const NetworkIcon = data[network].icon;

  return (
    <styles.Container>
      <styles.Banner bgColor={data[network].bgColor} bgImagePath={data[network].bgImagePath}>
        {!isMobile && <NetworkLogo />}
        <styles.Text>
          New network available -
          {' '}
          {network}
          <NetworkIcon />
        </styles.Text>
        <styles.CloseIconWrapper>
          <styles.CloseIcon onClick={closeHandler} />
        </styles.CloseIconWrapper>
      </styles.Banner>
    </styles.Container>
  );
};

export default HeaderBanner;
