import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 0.875rem;
  font-weight: 600;
`;

const Icon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`;

export default { Container, Description, Icon };
