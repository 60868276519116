import styled, { css } from 'styled-components';

import { colors } from 'theme';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 1.5rem;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
`;

const Clear = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.lightGrey};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    path {
      fill: ${({ theme }) => theme.lightGrey};
    }
  }
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FilterName = styled.p`
  color: ${({ theme }) => theme.white};
  font-size: 0.875rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const FilterButton = (isActive: boolean) => css`
  padding: 0 0.5rem;
  font-size: 0.75rem;
  height: 2.5rem;

  svg {
    rect, circle {
      fill: none;
    }
    path {
      fill: ${colors.lightGrey};
    }
  }

  ${isActive
    && css`
      background-color: ${colors.white};
      color: ${colors.black};
      &:hover {
        background-color: ${colors.white};
        opacity: 0.9;
      }
      &:active {
        background-color: ${colors.white};
      }
      svg {
        path {
          fill: ${colors.black};
        }
      }
  `}
`;

export default {
  Container,
  Header,
  Title,
  Clear,
  Filter,
  FilterName,
  WrapperButtons,
  FilterButton,
};
