import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import store from 'store';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import wagmiConfig, { mixpanelToken } from 'services/config';
import { ToastContainer } from 'react-toastify';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import {
  closeModal, EModals, IModalsProps, MODALS, selectModalState,
} from 'store/slices/modals';
import useDisableScrolling from 'shared/hooks/useDisableScrolling';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import useAppSelector from 'shared/hooks/redux/useAppSelector';

import theme from './theme';
import AppRoutes from './routes';
import './index.css';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
mixpanel.init(mixpanelToken, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const App = () => {
  useDisableScrolling();
  const dispatch = useAppDispatch();
  const modalState = useAppSelector(selectModalState);
  const Modal: IModalsProps[EModals] = MODALS[modalState.modal];

  return (
    <Router>
      {Modal && <Modal closeModal={() => dispatch(closeModal())} {...modalState.props} />}
      <AppRoutes />
      <ToastContainer />
    </Router>
  );
};

root.render(
  <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>
    </QueryClientProvider>
  </WagmiProvider>,
);
