import React, { useMemo } from 'react';
import { addSpacesBetweenWords } from 'shared/utils';

import { chains, networks } from 'services/config';
import styles from './styles';
import { IReducerNetworkDropdown, INetworkDropdown } from './interface';

const NetworkDropdown: React.FC<INetworkDropdown> = ({
  elements,
  onChange,
  title,
}) => {
  const { active, testnet, soon } = useMemo(() => {
    return elements.reduce((acc: IReducerNetworkDropdown, network) => {
      const chainId = networks[network.name]?.chainIdNumber;
      const currentChain = chains.find((chain) => chain.id === chainId);

      if (currentChain?.testnet) {
        acc.testnet.push(network);
      } else if (network.active) {
        acc.active.push(network);
      } else {
        acc.soon.push(network);
      }

      return acc;
    }, { active: [], testnet: [], soon: [] });
  }, [elements]);

  return (
    <styles.DropdownWrapper isHeader>
      <styles.Title>{title}</styles.Title>
      <styles.List isHeader>
        {active.map(({ name, iconPath }) => (
          <styles.ItemWrapper active onClick={() => onChange(name)} key={name}>
            <styles.TitleWrapper>
              {iconPath && <styles.Icon src={iconPath} alt="network icon" />}
              <styles.Name>{addSpacesBetweenWords(name)}</styles.Name>
            </styles.TitleWrapper>
          </styles.ItemWrapper>
        ))}
      </styles.List>
      {testnet.length !== 0 && (
        <>
          <styles.Title marginTopInRem={0.5}>Testnet</styles.Title>
          <styles.List isHeader>
            {testnet.map(({ name, iconPath }) => (
              <styles.ItemWrapper active onClick={() => onChange(name)} key={name}>
                <styles.TitleWrapper>
                  {iconPath && <styles.Icon src={iconPath} alt="network icon" />}
                  <styles.Name>{addSpacesBetweenWords(name)}</styles.Name>
                </styles.TitleWrapper>
              </styles.ItemWrapper>
            ))}
          </styles.List>
        </>
      )}
      {soon.length !== 0 && (
        <>
          <hr style={{ opacity: 0.12 }} />
          <styles.List isHeader>
            {soon.map((network) => (
              <styles.ItemWrapper active={false} key={network.name}>
                <styles.TitleWrapper>
                  {network.iconPath && <styles.Icon src={network.iconPath} alt="network icon" />}
                  <styles.Name>{addSpacesBetweenWords(network.name)}</styles.Name>
                </styles.TitleWrapper>
                <styles.Soon>
                  <styles.SoonTitle>
                    <span>Soon</span>
                  </styles.SoonTitle>
                </styles.Soon>
              </styles.ItemWrapper>
            ))}
          </styles.List>
        </>
      )}
    </styles.DropdownWrapper>
  );
};

export default NetworkDropdown;
