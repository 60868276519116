export enum EReportsStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CLAIMED = 'claimed',
}

export enum EReportsAddressNetwork {
  NEAR = 'near',
  AURORA = 'aurora',
  SOLANA = 'solana',
  ETHEREUM = 'ethereum',
  BNB_CHAIN = 'bnb_chain',
  BITCOIN = 'bitcoin',
  POLYGON = 'polygon',
  OKTC = 'oktc',
  TRON = 'tron',
  LINEA = 'linea',
}

export enum EReportsCategory {
  SOCIAL_MEDIA_SCAMMER = 'social_media_scammer',
  FRAUDULENT_WEBSITE = 'fraudulent_website', // LEVEL 1
  SCAM_PROJECT = 'scam_project',
  TERRORIST_FINANCING = 'terrorist_financing',
  // TODO: check NEW
  RUG_PULL = 'rug_pull',
  MONEY_LAUNDERING = 'money_laundering',
  COUNTERFEITING = 'counterfeiting',
  ORGANIZED_CRIME = 'organized_crime',
  CYBER_CRIME = 'cyber_crime',
  DATA_BREACHES = 'data_breaches',
  DRUG = 'drug',
  WEAPONS_TRADE = 'weapons_trade',
  HUMAN_TRAFFICKING = 'human_trafficking',
  SOCIAL_SCAM = 'social_scam',
  NFT_SCAM = 'nft_scam',
  TERRORIST_FINANCE = 'terrorist_finance',
  SANCTION = 'Sanction',
  DARKNET_MARKETS = 'darknet_markets',
  WAR_DONATIONS = 'war_donations'
}

export enum EReportsSortBy {
  ID = 'id',
  STATUS = 'status',
  CATEGORY = 'category',
  ADDRESS_NETWORK = 'address_network',
}

export enum EReportSortDir {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IReportsIndexerParams {
  search?: string;
  reporter?: string;
  skip?: number;
  take?: number;
  chain_id?: number;
  status?: EReportsStatus;
  category?: EReportsCategory;
  address_network?: EReportsAddressNetwork;
  sort_by?: EReportsSortBy;
  sort_dir?: EReportSortDir;
}
