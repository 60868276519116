import React, { useEffect } from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { useAccount } from 'wagmi';

import initialLoading from 'store/actions/initialLoading';
import ReportPage from 'views/Report';
import TermsAndConditions from 'views/TermsAndConditions';
import Main from 'views/Main';
import UserProfile from 'views/UserProfile';
import Reports from 'views/Reports';
import FAQ from 'views/FAQ';
import About from 'views/About';
import Rewards from 'views/Rewards';
import GlossaryPage from 'views/Glossary';

import HeaderBanner from 'shared/components/HeaderBanner';
import { DropdownNetwork } from 'shared/enums';
import loadDataByNetwork from 'store/actions/loadDataByNetwork';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectCurrentChainId, selectDataByNetwork, setCurrentChainId } from 'store/slices/networks';
import switchNetwork from 'shared/utils/switchNetwork';
import { getAccount } from '@wagmi/core';
import loadAccountDetails from 'store/actions/loadAccountDetails';
import getSupportedNetwork from 'shared/utils/getSupportedNetwork';
import config from 'services/config';
import Footer from '../shared/components/Footer';
import Header from '../shared/components/Header';
import useAppDispatch from '../shared/hooks/redux/useAppDispatch';

import { ROUTES } from './constants';
import { ProtectedRoute } from './ProtectedRoute';
import styles from './styles';

const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isConnected, address, chain } = useAccount();
  const currentChainId = useAppSelector(selectCurrentChainId);
  const dataByNetwork = useAppSelector(selectDataByNetwork);

  const [showBanner, setShowBanner] = React.useState<boolean>(false);

  useEffect(() => {
    if (!isConnected) return;
    const supportedNetwork = getSupportedNetwork();
    if (supportedNetwork.needSwitch && supportedNetwork.network) {
      const { chain: localChain } = getAccount(config);
      localChain?.id !== currentChainId && switchNetwork(currentChainId);
      dispatch(setCurrentChainId(currentChainId));
    } else if (chain?.id) {
      dispatch(setCurrentChainId(chain.id));
    }
  }, [dispatch, chain, isConnected, currentChainId]);

  useEffect(() => {
    dispatch(initialLoading());
  }, [dispatch, isConnected]);

  useEffect(() => {
    if (!currentChainId || dataByNetwork) return;
    dispatch(loadDataByNetwork());
  }, [dispatch, currentChainId, dataByNetwork]);

  useEffect(() => {
    dispatch(loadAccountDetails());
  }, [dispatch, address]);

  return (
    <styles.Pages>
      {showBanner && <HeaderBanner network={DropdownNetwork.Blast} closeHandler={() => setShowBanner(false)} />}
      <Header />
      <styles.Content>
        <Switch>
          <Route exact path={ROUTES.HOME}>
            <Main />
          </Route>
          <ProtectedRoute path={ROUTES.PROFILE} component={UserProfile} />
          <Route exact path={ROUTES.REPORT}>
            <ReportPage />
          </Route>
          <Route exact path={ROUTES.REPORTS}>
            <Reports />
          </Route>
          <Route exact path={ROUTES.FAQ}>
            <FAQ />
          </Route>
          <Route exact path={ROUTES.ABOUT}>
            <About />
          </Route>
          <Route exact path={ROUTES.TERMS_AND_CONDITIONS}>
            <TermsAndConditions />
          </Route>
          <Route exact path={ROUTES.REWARDS}>
            <Rewards />
          </Route>
          <Route exact path={ROUTES.GLOSSARY}>
            <GlossaryPage />
          </Route>
          <Redirect to={ROUTES.HOME} />
        </Switch>
      </styles.Content>
      <Footer />
    </styles.Pages>
  );
};

export default AppRoutes;
