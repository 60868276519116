import { getAccount } from '@wagmi/core';
import config, { networks, defaultNetwork } from 'services/config';

const getSupportedNetwork = (pendingChainId?: number) => {
  const { chain } = getAccount(config);
  const chainId = pendingChainId || chain?.id;
  const isSupportedChain = Object.values(networks)
    .find((supportedNetwork) => {
      return supportedNetwork.active && supportedNetwork.chainIdNumber === chainId;
    });
  const defaultNetworkObject = networks[defaultNetwork];
  return { needSwitch: !isSupportedChain, network: isSupportedChain || defaultNetworkObject };
};

export default getSupportedNetwork;
