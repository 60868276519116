import React from 'react';
import { FlattenSimpleInterpolation } from 'styled-components';
import Styles from './styles';

interface ITitleProps {
  value: string
  styles: string | FlattenSimpleInterpolation;
  isBlack?: boolean;
}

const Title: React.FC<ITitleProps> = (
  {
    value,
    styles,
    isBlack,
  },
): JSX.Element => {
  return (
    <Styles.Title
      isBlack={isBlack}
      styles={styles}
    >
      {value}
    </Styles.Title>
  );
};

export default Title;
