import { toast } from 'react-toastify';
import { EToasterType } from 'shared/enums';
import ToastContent from 'shared/components/Toast';
import toastOptions from 'theme/options';

export default class ToastService {
  static success({ text }: { text: string }): void {
    const content = ToastContent(EToasterType.SUCCESS_ACTION, text);
    toast(content, toastOptions);
  }

  static warning({ text }: { text: string }): void {
    const content = ToastContent(EToasterType.WARNING_ACTION, text);
    toast(content, toastOptions);
  }

  static error({ text }: { text: string }): void {
    const content = ToastContent(EToasterType.ERROR_ACTION, text);
    toast(content, toastOptions);
  }
}
