import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  height: 3rem;
  padding: 0 0.5rem;
  border: 1px solid ${({ theme }) => theme.lightFontGrey};
  border-radius: 0.5rem;
  transition: box-shadow 0.2s, border 0.2s;
  :focus-within,
  hover {
    border: 1px solid ${({ theme }) => theme.lightFontGrey};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.boxShadowInput};
  }

  & > svg {
    flex-shrink: 0;
    cursor: pointer;
  }
`;

const Input = styled.input`
  color: ${({ theme }) => theme.white};
  background-color: unset;
  width: 100%;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  :placeholder {
    color: ${({ theme }) => theme.lightFontGrey};
  }
  :disabled {
    cursor: not-allowed;
  }
`;

export default {
  InputContainer,
  Input,
};
