import styled from 'styled-components';

const Wrapper = styled.div<{ isMain: boolean }>`
  margin-top: ${({ isMain }) => (isMain ? '9rem' : '0')};

  ${({ theme, isMain }) => theme.media.mobile(`
    padding: 0 1.4rem;
    margin-top: ${isMain ? '6rem' : '0'}
  `)};
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 45rem;
  margin: 2.8rem auto 0;
  ${({ theme }) => theme.media.mobile`
    margin: 2rem auto 0;
  `}
`;

const TopRow = styled.div`
  display: flex;
  margin-top: 2.81rem;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.media.mobile`
    margin-top: 2.06rem;
  `}
`;

const ColumnName = styled.span`
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.interRegular};
  color: ${({ theme }) => theme.lightFontGrey};
`;

const AllHunters = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`;

const RowSide = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled.span`
  width: 2rem;
  font-size: 1.25rem;
  margin-right: 2rem;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interBold};
  ${({ theme }) => theme.media.mobile`
    font-size: 1rem;
    margin-right: 0.75rem;
 `}
`;

const NickName = styled.span<{ isAllUsers?: boolean }>`
  margin-left: 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interSemiBold};
  ${({ theme }) => theme.media.mobile`font-size: 1rem`}
`;

const NickNameHighlight = styled.mark`
  background: ${({ theme }) => theme.black};
`;

const ReportValue = styled.span`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interSemiBold};
  ${({ theme }) => theme.media.mobile`font-size: 1rem;`}
`;

const NoResults = styled.div`
  font-size: 1rem;
  text-align: center;
  margin-top: 4.5rem;
  color: ${({ theme }) => theme.opacityBlack};
  font-family: ${({ theme }) => theme.interSemiBold};
`;

const UserWrapper = styled.div`
  margin-left: 2rem;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.mobile`
    margin-left: 1rem;
 `}
`;

const Title = styled.div<{ isMain: boolean }>`
  color: ${({ theme }) => theme.white};
  font-size: ${({ isMain }) => (isMain ? '2.75rem' : '2rem')};
  font-weight: 700;
  padding-top: ${({ isMain }) => (isMain ? '0' : '2rem')};
  text-align: ${({ isMain }) => (isMain ? 'center' : 'left')};
  ${({ theme }) => theme.media.mobile`
    font-size: 1.5rem;
 `}
`;

export default {
  Wrapper,
  Table,
  TopRow,
  ColumnName,
  UserWrapper,
  Row,
  RowSide,
  Number,
  NickName,
  ReportValue,
  AllHunters,
  NoResults,
  NickNameHighlight,
  Title,
};
