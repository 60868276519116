import { createAsyncThunk } from '@reduxjs/toolkit';

import { chains, getClient, getConfig } from 'services/config';
import { scamfariABI } from 'services/config/contract_abi';
import ToastService from 'services/toast-service';
import { Category } from 'shared/enums';
import { setCategoryReward, setRewardNetworks } from 'store/slices/reports';

import { updateRewardNetworks } from 'shared/utils/networks';
import { initialRewardsNetwork } from 'shared/constants/reports';

import Big from 'big.js';
import { ZERO_STRING } from 'shared/constants';

import { RootState } from '../index';

const loadCategoryRewards = createAsyncThunk<void, number, { state: RootState }>(
  'reports/loadCategoryRewards',
  async (chainId, { dispatch, getState }) => {
    try {
      const {
        reports: {
          rewards: { networks },
        },
      } = getState();
      dispatch(setRewardNetworks(updateRewardNetworks(networks, chainId)));

      if (!chainId) return;
      const publicClient = getClient(chainId);

      const [configuration, rewardResults] = await Promise.all([
        publicClient.readContract({
          address: getConfig(chainId).contract,
          abi: scamfariABI,
          functionName: 'configuration',
        }),
        publicClient.readContract({
          address: getConfig(chainId).contract,
          abi: scamfariABI,
          functionName: 'getCategoryRewards',
        }),
      ]);

      const baseReward = Big(configuration?.[1].toString() || ZERO_STRING);
      const reward = rewardResults
        ? rewardResults.map(({ category, reward_amount }) => ({
          category: category as Category,
          amount: Big(reward_amount.toString()).eq(ZERO_STRING) ? baseReward : Big(reward_amount.toString()),
        }))
        : Array.from({ length: Object.values(Category).length / 2 }, (_, category) => ({
          category: category as Category,
          amount: baseReward,
        }));

      dispatch(setCategoryReward({ chainId, reward }));
    } catch (e) {
      console.error(`Error: while loading category rewards \n ${e}`);
      ToastService.error({ text: 'Error: while loading category rewards' });
    } finally {
      dispatch(setRewardNetworks(initialRewardsNetwork()));
    }
  },
);

export default loadCategoryRewards;
