import { EInformationField, Network } from '../enums';
import { FILE_SIZE_DECIMAL } from '../constants';

export const updatedInformation = (
  array: {address: string, network: Network}[],
  targetIndex: number,
  typeValue: Network | string,
  fieldName: EInformationField,
) => {
  return array.map((item, id) => {
    if (id === targetIndex) {
      return {
        ...item,
        [fieldName]: typeValue,
      };
    }
    return item;
  });
};

export const megabytesToBytes = (megabytes: number) => megabytes * (1024 ** 2);
export const bytesToMegabytes = (bytes: number) => bytes / (1024 ** 2);

export const formatSize = (size: number): string => {
  const megabytes = bytesToMegabytes(size);
  return `${megabytes.toFixed(FILE_SIZE_DECIMAL)} MB`;
};

export const isAddressMatchNetwork = (caption: string, chain: Network) => {
  if (['.', '_', '-'].includes(caption[0])) {
    return false;
  }
  const trimmedString = caption.trim();

  switch (chain) {
    case Network.NEAR: {
      const parts = caption.split('.').reverse();
      if (parts[0] === 'near') {
        // eslint-disable-next-line
        return /^[a-z0-9\.\-_]{2,64}$/.test(caption);
      }
      return /^[a-f0-9]{64}$/.test(caption);
    }
    case Network.Solana:
      return /^[a-zA-Z0-9]{32,44}$/.test(trimmedString);

    case Network.Aurora:
    case Network.Linea:
    case Network.Ethereum:
    case Network.Polygon:
    case Network.BNBChain:
    case Network.OKTC:
      return /^(0x)?[0-9a-fA-F]{40}$/.test(trimmedString);

    case Network.Bitcoin:
      return /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(trimmedString);

    case Network.Tron:
      return /^T[a-zA-Z1-9]{33}$/.test(trimmedString);

    default:
      return false;
  }
};
