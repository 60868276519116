import styled from 'styled-components';

const Wrapper = styled.div`
  gap: 1.31rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media.tablet`
      justify-content: center;
  `}
`;

const SocialCircle = styled.div`
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

export default {
  Wrapper,
  SocialCircle,
};
