import React, { useEffect, useState } from 'react';
import { ECreateReportStage, IModalProps } from 'shared/interfaces';
import ModalWrapper from 'shared/components/modals/ModalWrapper';
import ModalsHeader from 'shared/components/modals/components/ModalsHeader';
// import FirstStage from 'shared/components/ReportStages/FirstStage';
import SecondStage from 'shared/components/ReportStages/SecondStage';
import { MainCategories } from 'shared/enums';
import { MixpanelService, EMixpanelEvents } from 'services/mixpanel';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import initialLoading from 'store/actions/initialLoading';
import switchNetwork from 'shared/utils/switchNetwork';
import getSupportedNetwork from 'shared/utils/getSupportedNetwork';
import CreateReportStyles from './styles';

export const CreateReportModal: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const dispatch = useAppDispatch();
  const [stage, setStage] = useState<ECreateReportStage>(ECreateReportStage.SECOND);
  const [mainCategory, setMainCategory] = useState<MainCategories>(MainCategories.IllegalActivity);

  const closeModalHandler = () => {
    closeModal();
    MixpanelService.trackEvent(EMixpanelEvents.CLOSE_REPORT_MODAL);
  };

  useEffect(() => {
    const { needSwitch, network } = getSupportedNetwork();
    const checkNetwork = async (id: number) => {
      const { isSuccess } = await switchNetwork(id);
      if (isSuccess) {
        dispatch(initialLoading());
      } else {
        closeModalHandler();
      }
    };
    if (needSwitch && network) {
      const { chainIdNumber } = network;
      checkNetwork(chainIdNumber);
    }
  }, [dispatch]);

  return (
    <ModalWrapper closeModal={closeModalHandler} shouldClose={false} width="24rem">
      <CreateReportStyles.SidePadding>
        <ModalsHeader closeModal={closeModalHandler} title="Create Report" />
        {/* <CreateReportStyles.Stage>{`Step ${stage}/2`}</CreateReportStyles.Stage> */}
        <CreateReportStyles.HeatTitle>
          {ECreateReportStage.FIRST === stage
            ? 'Select the type of scam you are about to report'
            : 'Enter your report details'}
        </CreateReportStyles.HeatTitle>
      </CreateReportStyles.SidePadding>
      {/* {stage === ECreateReportStage.FIRST ? (
        <FirstStage setMainCategory={setMainCategory} setStage={setStage} />
      ) : ( */}
      <SecondStage setStage={closeModalHandler} mainCategory={mainCategory} />
      {/* )} */}
    </ModalWrapper>
  );
};

export default CreateReportModal;
