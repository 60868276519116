import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getAccount,
  waitForTransactionReceipt, writeContract,
} from '@wagmi/core';
import config, { getConfig } from 'services/config';
import { scamfariABI } from 'services/config/contract_abi';
import apiService from 'services/api-service/api-service';
import formatIndexerReport, { IIndexerReport } from 'shared/utils/formatIndexerReport';
import { RootState } from '../index';
import { updateReportById } from '../slices/reports';
import ToastService from '../../services/toast-service';

const declineReport = createAsyncThunk<void, { id: number; reason: string; }, { state: RootState }>(
  'reports/declineReport',
  async ({ id, reason }, { dispatch }) => {
    try {
      const { chain } = getAccount(config);
      if (!chain) return;

      const hash = await writeContract(config, {
        address: getConfig().contract,
        abi: scamfariABI,
        functionName: 'reject',
        args: [BigInt(id), reason],
      });

      await waitForTransactionReceipt(config, { hash });

      setTimeout(async () => {
        const { items: reports } = await apiService.getReports({
          chain_id: chain.id,
          skip: id - 1,
          take: 1,
        });

        const [report] = reports.map((reportItem: IIndexerReport) => formatIndexerReport(reportItem));

        dispatch(updateReportById({ id, report }));
      }, 7000);

      ToastService.success({ text: 'This report is declined' });
    } catch (e) {
      console.error(`Error: while declining report \n ${e}`);
      ToastService.error({ text: 'Error: while declining report' });
    }
  },
);

export default declineReport;
