import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import reportsReducer from './slices/reports';
import modalsReducer from './slices/modals';
import tokensReducer from './slices/token';
import networksReducer from './slices/networks';

const store = configureStore({
  reducer: {
    user: userReducer,
    reports: reportsReducer,
    modals: modalsReducer,
    tokens: tokensReducer,
    networks: networksReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
