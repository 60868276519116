import styled from 'styled-components';

const GlossaryWrapper = styled.div`
  gap: 2rem;
  display: flex;
  width: 39.5rem;
  flex-direction: column;
  margin: 2rem auto 0 auto;
  max-width: calc(100% - 2rem);

  h1 {
    margin: 0;
  }

  button {
    width: 11.1875rem;
  }
`;

const Tabs = styled.div`
  gap: 1.5rem;
  display: flex;
  height: 1.75rem;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar{
    display: none;
  }
`;

const Tab = styled.div<{ $isActive: boolean }>`
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  white-space: nowrap;
  cursor: ${({ $isActive }) => ($isActive ? 'default' : 'pointer')};
  color: ${({ theme, $isActive }) => ($isActive ? theme.white : theme.lightFontGrey)};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    height: 0.125rem;
    top: calc(100% + 0.25rem);
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.white};
    width: ${({ $isActive }) => ($isActive ? '1.5rem' : '0')};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

const TabContent = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0 0 0.75rem 0;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 120%;
    margin: 0 0 0.75rem 0;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
    color: ${({ theme }) => theme.lightGrey};
  }

  ul {
    margin: 0;
    padding-left: 1.5rem;

    li {
      font-size: 1rem;
      font-weight: 400;
      line-height: 140%;
      color: ${({ theme }) => theme.lightGrey};
    }
  }
`;

export default {
  GlossaryWrapper,
  Tabs,
  Tab,
  TabContent,
};
