import React, { useEffect } from 'react';
import { IModalProps } from 'shared/interfaces';
import NavigationList from 'shared/components/NavigationList';
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import { EDimensions } from 'shared/enums';
import MobileWrapperStyles from './styles';

// eslint-disable-next-line import/prefer-default-export
export const MobileMenu: React.FC<IModalProps> = ({ closeModal }: IModalProps) => {
  const dimension = useWindowDimensions();

  const isAdaptive = dimension === EDimensions.SMALL || dimension === EDimensions.MEDIUM;

  useEffect(() => {
    if (!isAdaptive) closeModal();
  }, [isAdaptive, closeModal]);

  return (
    <MobileWrapperStyles.Overlay>
      <MobileWrapperStyles.CloseButton onClick={closeModal} />
      <NavigationList closeModal={closeModal} />
    </MobileWrapperStyles.Overlay>
  );
};
