import styled from 'styled-components';
import { css } from 'styled-components/macro';

const Title = styled.p`
  color: ${({ theme }) => theme.white};
  font-size: 2.75rem;
  font-family: ${({ theme }) => theme.interBlack};
  text-align: center;
  padding-top: 9rem;
  ${({ theme }) => css`
    ${theme.media.mobile(`
     font-size: 1.5rem;
     padding-top: 6rem;
    `)}
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4.5rem;

  ${({ theme }) => css`
    ${theme.media.tablet(`
      margin-top: 4.5rem;
      padding: 0 7.313rem;
      flex-direction: column;
      `)}
  `}

  ${({ theme }) => css`
    ${theme.media.mobile(`
     padding: 0 1rem;
     margin-top: 3rem;
    `)}
  `}
`;

const Logo = styled.img`
  width: 4.5rem;
  height: 4.5rem;
`;

const Description = styled.p`
  line-height: 140%;
  font-size: 1.25rem;
  text-align: center;
  padding-top: 1.5rem;
  color: ${({ theme }) => theme.lightGrey};
  font-family: ${({ theme }) => theme.interRegular};

  ${({ theme }) => theme.media.tablet`
    max-width: 31.25rem;
  `}

  ${({ theme }) => css`
    ${theme.media.mobile(`
      font-size: 1rem;
    `)}
  `}
`;

const Item = styled.div`
  flex: 0 0 20.5rem;
  margin-right: 3rem;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }
  ${({ theme }) => css`
    ${theme.media.tablet(`
      margin-right: 0;
      flex: 0 0 0;
      margin-top: 3rem;
      &:first-child {
      margin-top: 0rem;
       }
    `)}
  `}
`;

export default {
  Item,
  Title,
  Logo,
  Description,
  Wrapper,
};
