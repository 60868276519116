import styled from 'styled-components';

const Description = styled.div`
  margin-top: 1.5rem;
  font-size: 0.8125rem;
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 400;
`;

const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  & > div {
    flex: 1;
  }
  svg {
    cursor: pointer;
  }
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;

const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AmountTitle = styled.div`
  font-weight: 500;
  line-height: 120%;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.middleGrey};
  `;

const AmountValue = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 120%;
  color: ${({ theme }) => theme.white};
`;

const WarningText = styled.div`
  margin-top: 2rem;
  font-weight: 400;
  line-height: 140%;
  position: relative;
  font-size: 0.75rem;
  padding-left: 1.5rem;
  color: ${({ theme }) => theme.pendingYellow};
`;

const WarningIcon = styled.img`
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  position: absolute;
`;

const BalanceRow = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;

  div {
    &:first-child {
      margin-right: 0.75rem;
    }
  }
`;

export default {
  Description,
  ButtonWrapper,
  AmountWrapper,
  AmountRow,
  AmountTitle,
  AmountValue,
  WarningText,
  WarningIcon,
  BalanceRow,
};
