import React, { SyntheticEvent, useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { v4 as uuid } from 'uuid';
import { IReport } from 'shared/interfaces';
import ReportStatusLabel from 'shared/components/ReportStatusLabel';
import { addSpacesBetweenWords, copied, sliceString } from 'shared/utils';
import {
  Category, EQueryParams, Network, ReportStatus,
} from 'shared/enums';
import ReportListStyles from 'shared/components/ReportList/styles';

import ARROW from 'assets/images/icons/white-top-arrow.svg';
import { ReactComponent as COPY_ICON } from 'assets/images/icons/copy.svg';
import { EMPTY_STRING } from 'shared/constants';
import { NetworkIconMap } from 'shared/components/Dropdown/interface';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectCurrentChainId } from 'store/slices/networks';

const Report: React.FC<{ report: IReport }> = ({
  report: {
    addr, reporter, category, id, status, reject_reason, network, url, proof, description,
  },
}) => {
  const history = useHistory();

  const chainId = useAppSelector(selectCurrentChainId);

  const [isShow, setIsShow] = useState<boolean>(false);
  const isReportRejected = status === ReportStatus.Declined;

  const onError = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    (e.target as HTMLInputElement).onerror = null;
    (e.target as HTMLInputElement).src = EMPTY_STRING;
  }, []);

  const searchParams = { [EQueryParams.CHAIN_ID]: chainId.toString() };
  const reportRoute = `${generatePath(ROUTES.REPORT, { id })}?${new URLSearchParams(searchParams).toString()}`;
  const reportPageUrl = `${window.location.origin}${reportRoute}`;

  const goToReport = () => {
    history.push(reportRoute);
    MixpanelService.trackEvent(EMixpanelEvents.REPORT, { id: id.toString(), addressReport: addr });
  };

  return (
    <ReportListStyles.Container>
      <ReportListStyles.Address>{addr}</ReportListStyles.Address>
      <ReportListStyles.InformationRow>
        <div>
          <ReportStatusLabel status={status} />
          <ReportListStyles.IdContainer>
            <ReportListStyles.SmallText onClick={goToReport}>{`ID: ${id}`}</ReportListStyles.SmallText>
            <CopyToClipboard onCopy={copied} text={reportPageUrl}>
              <COPY_ICON />
            </CopyToClipboard>
          </ReportListStyles.IdContainer>
        </div>
        <div>
          <ReportListStyles.BorderText>{addSpacesBetweenWords(Category[category])}</ReportListStyles.BorderText>
          <ReportListStyles.BorderText>
            <img src={NetworkIconMap[network]} alt="network icon" />
            {Network[network]}
          </ReportListStyles.BorderText>
        </div>
      </ReportListStyles.InformationRow>
      <ReportListStyles.DetailsRow>
        <ReportListStyles.SmallText>
          Reported by
          {' '}
          {sliceString(reporter)}
        </ReportListStyles.SmallText>
        <ReportListStyles.WhiteText onClick={() => setIsShow((prevState) => !prevState)} isShow={isShow}>
          Details
          <img src={ARROW} alt="arrow" />
        </ReportListStyles.WhiteText>
      </ReportListStyles.DetailsRow>
      {isShow && (
        <ReportListStyles.GeneralBlock>
          <ReportListStyles.Link href={url} target="_blank" rel="noreferrer">
            {url}
          </ReportListStyles.Link>
          <ReportListStyles.MiddleText>{description}</ReportListStyles.MiddleText>
          <ReportListStyles.PhotosWrapper>
            {proof.map((source) => (
              <a key={uuid()} href={source} target="_blank" rel="noreferrer">
                <img src={source} onError={onError} alt="proof" />
              </a>
            ))}
          </ReportListStyles.PhotosWrapper>
          {isReportRejected && <ReportListStyles.RejectReason>{reject_reason}</ReportListStyles.RejectReason>}
        </ReportListStyles.GeneralBlock>
      )}
    </ReportListStyles.Container>
  );
};

export default Report;
