interface Response {
  filename: string,
  newfilename: string,
  url: string,
  web3cid: string,
}

async function uploadFile(
  files: File[],
): Promise<Array<string>> {
  try {
    const data = new FormData();
    files.forEach((file) => data.append(file.name, file));
    const response = await fetch('https://storage.hapilabs.one/upload.php', {
      method: 'POST',
      headers: {
        accept: 'application/json',
      },
      body: data,
    });
    const json: Array<Response> = await response.json();
    return json.map((element) => element.web3cid);
  } catch (error) {
    console.error('Error while uploading file', error);
    throw error;
  }
}

export default { uploadFile };
