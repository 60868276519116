import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

const titleStyles: FlattenSimpleInterpolation = css`
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  font-size: 2.75rem;
  position: relative;
  padding: 12.75rem 0 1rem;
  color: ${({ theme }) => theme.white};
  z-index: 14;
  ${({ theme }) => theme.media.tablet`
    padding-top: 9rem;
    font-size: 2.75rem;
  `}
  
  ${({ theme }) => theme.media.mobile`
    padding-top: 6rem;
    font-size: 1.5rem;
  `}
` as FlattenSimpleInterpolation;

export default titleStyles;
