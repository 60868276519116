import styled from 'styled-components';

const Text = styled.div`
  font-family: ${({ theme }) => theme.interRegular};
  color: ${({ theme }) => theme.regularGrey};
  font-size: 0.75rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem 1.5rem;
  ${({ theme }) => theme.media.tablet`
    padding: 1.5rem 1rem 1.5rem;
  `}

  > div {
    flex: 1;
  }
`;

const Container = styled.div`
  margin-top: 9rem;
  border-top: 1px solid ${({ theme }) => theme.borderBlack};
  ${({ theme }) => theme.media.tablet`
    padding: 1.5rem 0 1rem;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ProtectedLogo = styled.img`
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

const TermsAndPrivacy = styled.div`
  gap: 0.5rem;
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 4.5rem;
  font-family: ${({ theme }) => theme.interRegular};

  a, div {
    gap: 0.5rem;
    display: flex;
    line-height: 140%;
    font-size: 0.75rem;
    text-align: center;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.regularGrey};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  ${({ theme }) => theme.media.tablet`
    margin: 1.5rem auto 0;
  `}
`;

export default {
  Text,
  Wrapper,
  Container,
  FlexRow,
  ProtectedLogo,
  TermsAndPrivacy,
};
