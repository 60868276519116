import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAccount } from '@wagmi/core';
import { ITEMS_PER_PAGE } from 'shared/constants';
import { toMap } from 'shared/utils';
import formatIndexerReport, { IIndexerReport } from 'shared/utils/formatIndexerReport';
import apiService from 'services/api-service/api-service';
import { EReportSortDir, EReportsStatus } from 'services/api-service/interfaces/report';
import config from 'services/config';

import { RootState } from '../index';
import { setReportsByUser } from '../slices/reports';

const updateReportsByUser = createAsyncThunk<
  void,
  { reportStatus: EReportsStatus | null; page: number; chainId?: number },
  { state: RootState }
>('updateReportsByUser', async ({ reportStatus, page, chainId }, { dispatch, getState }) => {
  const {
    networks: { currentChainId },
  } = getState();

  try {
    const { address } = getAccount(config);
    if (!address || !currentChainId) {
      dispatch(setReportsByUser({ list: {}, amount: 0, notFound: true }));
      return;
    }

    const { items: reports, total: reportsAmount } = await apiService.getReports({
      take: ITEMS_PER_PAGE,
      sort_dir: EReportSortDir.DESC,
      chain_id: chainId ?? currentChainId,
      skip: (page - 1) * ITEMS_PER_PAGE,
      status: reportStatus || undefined,
      reporter: address.toLowerCase(),
    });

    const reportsArray = reports.map((report: IIndexerReport) => formatIndexerReport(report));
    const reportsMap = toMap(reportsArray, 'id');

    dispatch(setReportsByUser({ list: reportsMap, amount: reportsAmount, notFound: false }));
  } catch (e) {
    console.error(`Error: while initial loading user reports \n ${e}`);
  }
});

export default updateReportsByUser;
