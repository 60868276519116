import styled from 'styled-components';

const Pages = styled.div`
  z-index: 1;
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

export default {
  Pages,
  Content,
};
