import React from 'react';
import Submenu from 'shared/components/Submenu';
import styles from 'shared/components/Submenu/styles';
import { closeModal } from 'store/slices/modals';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import { EDimensions } from 'shared/enums';
import { INFO_NAVIGATION } from 'shared/constants/navigation';

const Info: React.FC = () => {
  const dispatch = useAppDispatch();
  const dimension = useWindowDimensions();
  const isAdaptive = dimension === EDimensions.SMALL || dimension === EDimensions.MEDIUM;

  const clickHandler = () => {
    if (!isAdaptive) return;
    dispatch(closeModal());
  };

  return (
    <Submenu>
      {INFO_NAVIGATION.map(
        ({ name, route }) => route && (
        <styles.NavItem to={route} onClick={clickHandler} key={name}>
            {name}
        </styles.NavItem>
        ),
      )}
    </Submenu>
  );
};

export default Info;
