enum ECheckAddressResponseStatuses {
  SCAMFARI_LEGACY = 'scamfari_legacy',
  HAPI = 'hapi',
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected',
  NOT_FOUND = 'not_found',
}

export default ECheckAddressResponseStatuses;
