import React from 'react';

import useAppSelector from 'shared/hooks/redux/useAppSelector';
import Loader from 'shared/components/Loader';
import { selectRewards } from 'store/slices/reports';
import { INetwork } from 'services/config/interface';
import { selectRewardToken } from 'store/slices/token';
import { displayAmount } from 'shared/utils';

import Tooltip from 'shared/components/Tooltip';
import styles from './styles';
import categorizeCategories from './utils';

export interface ICategoryList {
  network: INetwork
}

const CategoryList: React.FC<ICategoryList> = ({ network }) => {
  const token = useAppSelector(selectRewardToken);
  const { categoryRewards } = useAppSelector(selectRewards);

  if (!categoryRewards || !token || !categoryRewards[network.chainIdNumber]) {
    return (
      <styles.LoaderContainer>
        <Loader />
      </styles.LoaderContainer>
    );
  }
  const list = categoryRewards[network.chainIdNumber];
  return (
    <styles.Container>
      {categorizeCategories(list).map(({ title, description, categories }) => (
        <styles.List key={title}>
          <styles.List gapInRem={0.75}>
            <styles.Title>{title}</styles.Title>
            <styles.Description>{description}</styles.Description>
          </styles.List>
          <styles.List>
            {categories.map((category) => (
              <styles.Row key={category.category}>
                <Tooltip tooltip={category.tooltip}>{category.category}</Tooltip>
                <p>{`${displayAmount(category.amount, token.decimal)} ${token.symbol}`}</p>
              </styles.Row>
            ))}
          </styles.List>
        </styles.List>
      ))}
    </styles.Container>
  );
};

export default CategoryList;
