import React from 'react';
import Crime from './components/Crime';
import FinancialFraud from './components/FinancialFraud';
import IllegalActivity from './components/IllegalActivity';
import Scam from './components/Scam';
import { ITab } from './interface';

const data: { [key: string]: ITab } = {
  FinancialFraud: {
    tab: 'Financial Fraud',
    content: <FinancialFraud />,
  },
  Crime: {
    tab: 'Crime',
    content: <Crime />,
  },
  Scam: {
    tab: 'Scam',
    content: <Scam />,
  },
  IllegalActivity: {
    tab: 'Illegal Activity',
    content: <IllegalActivity />,
  },
};

export default data;
