import styled from 'styled-components';

const FileArea = styled.div`
  border: 1px dashed ${({ theme }) => theme.lightGrey};
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
`;

const UploadText = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  & img {
    margin-right: 0.5rem;
  }
  & span {
    font-size: 1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    display: block;
    &:last-child {
      font-weight: 500;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.7rem;
      white-space: nowrap;
      color: ${({ theme, error }) => (error ? theme.errorRed : theme.lightFontGrey)};
    }
  }
`;

export default {
  FileArea,
  UploadText,
};
