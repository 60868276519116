import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 60rem;
  margin: auto;
  ${({ theme }) => theme.media.tablet`
    max-width: 39.5rem;
  `}
  ${({ theme }) => theme.media.mobile`
    & > div:first-child {
      padding: 2rem 1rem 0;
    }
    & > div:nth-child(2) {
      padding: 0 1rem;
    }
    & > div:nth-child(3) {
      padding: 0 1rem;
    }
  `}
`;

const Header = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  & > div {
    display: flex;
    gap: 1rem;
    align-items: center;
    & > svg {
      width: 2.5rem;
      height: auto;
      cursor: pointer;
    }
    & > span {
      cursor: pointer;
      color: ${({ theme }) => theme.declinedRed};
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
`;

const Filter = styled.div<{ isActiveFilter: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.middleGrey};
`;

const FilterIconContainer = styled.div<{ isActiveFilter: boolean }>`
  position: relative;
  cursor: pointer;
  ${({ isActiveFilter }) => isActiveFilter && css`
    ::after {
      content: "";
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      right: -2px;
      top: -2px;
      border-radius: 50%;
      background: ${({ theme }) => theme.declinedRed};
    }
  `}
`;

const Title = styled.p`
  font-size: 2rem;
  ${({ theme }) => theme.media.mobile`
    font-size: 1.5rem;
  `}
`;

const ReportsAmount = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.lightGrey};
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  & > button {
    border-radius: 8px;
  }
`;

const Body = styled.div<{ showFilter: boolean }>`
  display: ${({ showFilter }) => (showFilter ? 'grid' : 'block')};
  grid-template-columns: 17.75rem auto;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 2.75rem;
  & > div:first-child {
    grid-area: 1 / 1 / 3 / 2;;
  }
  & > div:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  & > div:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }

  ${({ theme }) => theme.media.tablet`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `}
`;

const NotFoundReports = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 23.313rem;
  align-self: center;
  margin-top: 8.25rem;
  p {
    text-align: center;
    :last-child {
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 140%;
    }
    :first-child {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 130%;
    }
  }
`;

export default {
  Container,
  Header,
  Title,
  Filter,
  FilterIconContainer,
  ReportsAmount,
  SearchContainer,
  Body,
  NotFoundReports,
};
