import styled, { css } from 'styled-components';

const DropdownWrapper = styled.div<{ isHeader?: boolean, $isFullWidth?: boolean }>`
  z-index: 10;
  padding: 0.5rem;
  min-width: 12.5rem;
  position: absolute;
  border-radius: 1rem;
  top: ${({ isHeader }) => (isHeader ? '3.438rem' : '5rem')};
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : 'auto')};
  background: ${({ theme, isHeader }) => (isHeader ? theme.grey : theme.veryLightGrey)};
  ${({ isHeader }) => (!isHeader ? 'left: 0' : 'right: 0')};
  ${({ theme, isHeader }) => theme.media.tablet(`
    top: ${isHeader ? '2.5rem' : '5rem'}
  `)}
`;

const Title = styled.div<{marginTopInRem?: number}>`
  font-size: 1rem;
  color: ${({ theme }) => theme.lightGrey};
  font-family: ${({ theme }) => theme.interRegular};
  margin-top: ${({ marginTopInRem }) => marginTopInRem || 0}rem;
  ${({ theme }) => theme.media.tablet`
    font-size: 0.75rem;
  `}
`;

const List = styled.div<{ isHeader?: boolean }>`
  margin-top: ${({ isHeader }) => (isHeader ? '0.75rem' : '0')};
`;

const ItemWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 0.313rem;
  margin-top: 0.75rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: space-between;
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  ${({ active }) => active !== undefined
    && !active
    && css`
      opacity: 0.4;
      cursor: not-allowed;
      &:hover {
        background: transparent;
      }
  `}
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.interSemiBold};
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
`;

const Icon = styled.img`
  width: auto;
  height: 1.5rem;
  margin-right: 0.813rem;

  ${({ theme }) => theme.media.mobile`
    width: auto;
    height: 1rem;
    margin-right: 0.458rem;
  `}
`;

const Soon = styled.div`
  border-radius: 13px;
  padding: 0px 4px;
  background-color: ${({ theme }) => theme.yellowSoonTitle(0.16)};
  display: flex;
  margin-left: 4px;
`;

const SoonTitle = styled.span`
  font-weight: 400;
  line-height: 17px;
  font-size: 0.75rem;
  letter-spacing: 0em;
  color: ${({ theme }) => theme.yellowSoonTitle(1)};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export default {
  DropdownWrapper,
  Title,
  Name,
  TitleWrapper,
  List,
  ItemWrapper,
  Icon,
  Soon,
  SoonTitle,
};
