import React from 'react';

import useAppSelector from 'shared/hooks/redux/useAppSelector';

import { selectDataByNetwork } from 'store/slices/networks';
import { ZERO_STRING } from 'shared/constants';
import InstructionStyles from './styles';

const Instruction: React.FC = () => {
  const dataByNetwork = useAppSelector(selectDataByNetwork);

  return (
    <div>
      <InstructionStyles.Stats>
        <div>
          <InstructionStyles.StatsValue>{dataByNetwork?.reportersCounter || ZERO_STRING}</InstructionStyles.StatsValue>
          <InstructionStyles.StatsName>Hunters</InstructionStyles.StatsName>
        </div>
        <div>
          <InstructionStyles.StatsValue>{dataByNetwork?.reportsCounter || ZERO_STRING}</InstructionStyles.StatsValue>
          <InstructionStyles.StatsName>Reports</InstructionStyles.StatsName>
        </div>
        <div>
          <InstructionStyles.StatsValue>
            {dataByNetwork?.acceptedReportsCounter || ZERO_STRING}
          </InstructionStyles.StatsValue>
          <InstructionStyles.StatsName>Approved Reports</InstructionStyles.StatsName>
        </div>
      </InstructionStyles.Stats>
    </div>
  );
};

export default Instruction;
