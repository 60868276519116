import { EMPTY_STRING } from 'shared/constants';
import { Category } from 'shared/enums';
import { CategoryListType } from 'shared/interfaces';
import { splitCamelCase } from 'shared/utils';

const categoriesArray = [
  {
    title: 'Financial Fraud',
    description: 'Reports in this category relate to deceitful activities or schemes to acquire money or assets dishonestly within financial contexts.',
    categories: [Category.MoneyLaundering, Category.RugPull],
  },
  {
    title: 'Crime',
    description: 'Reports involve acts or intents that breach legal statutes, potentially leading to punishment like fines or imprisonment. This category includes a range of unlawful activities from counterfeiting to organised crime.',
    categories: [
      Category.Drug,
      Category.OrganizedCrime,
      Category.DataBreaches,
      Category.CyberCrime,
      Category.Counterfeiting,
      Category.WeaponsTrade,
      Category.HumanTrafficking,
    ],
  },
  {
    title: 'Scam',
    description: 'Reports in this category cover deceptive or fraudulent schemes designed to cheat someone out of money or other assets. These can range from social media scams to more elaborate fraudulent website operation secretive schemes to deceive individuals into parting with assets or information.',
    categories: [Category.SocialScam, Category.FraudulentWebsite, Category.NFTScam],
  },
  {
    title: 'Illegal Activity',
    description: 'Reports encompass a range of actions that are expressly forbidden by law. This can include activities from funding terrorism to participating in darknet marketplaces. These actions not only breach legal standards but often carry significant ethical and moral implications.',
    categories: [
      Category.TerroristFinance,
      Category.Sanction,
      Category.DarknetMarkets,
      Category.WarDonations,
    ],
  },
];

const categoriesTooltip: Record<number, string> = {
  [Category.RugPull]: 'Mention the crypto token\'s name, its developers, any unusual token transfers, or abrupt project updates.',
  [Category.MoneyLaundering]: 'Processes that disguise illicitly-gained money\'s origins, give it a legitimate look.',
  [Category.Counterfeiting]: 'Mention specific details about the counterfeit items, where and how they are being sold or distributed.',
  [Category.OrganizedCrime]: 'Details of the group\'s activities, your observations, and potential societal impact.',
  [Category.CyberCrime]: 'Links to phishing sites, fraudulent emails/websites, or forums where such activities are being discussed.',
  [Category.DataBreaches]: 'The extent of the data breach, types of data compromised, and impact on affected individuals or organizations.',
  [Category.Drug]: 'The trade of drugs',
  [Category.WeaponsTrade]: 'Illegal trade of weapons',
  [Category.HumanTrafficking]: 'The trade of humans',
  [Category.SocialScam]: 'Details of the scammer\'s approach, the nature of interaction, and any specific claims or requests made.',
  [Category.FraudulentWebsite]: 'Description of the website, how it\'s fraudulent, any transactions or communications made with the site operators, and discrepancies noticed that signaled fraud.',
  [Category.NFTScam]: 'Specifics on how the NFTs are fraudulent, including details about the creators, the platforms used, and any misleading information or promises made.',
  [Category.TerroristFinance]: 'How the funding is being raised and channeled, identifying any organizations or individuals involved',
  [Category.Sanction]: 'Specific details about the sanctioned entities and how they are evading or violating these sanctions.',
  [Category.DarknetMarkets]: 'Nature of goods or services traded, methods of transaction, and any observed security measures or user anonymity protocols.',
  [Category.WarDonations]: 'Description of the funding campaign, including who is organizing it, the purpose of the donations, and how the funds are supposedly used.',
};

const categorizeCategories = (list: CategoryListType) => (
  categoriesArray.map((element) => {
    const matchingCategories = list.filter(
      (listElement) => element.categories.includes(listElement.category),
    );
    const categories = matchingCategories.map(
      (el) => (
        {
          ...el,
          category: splitCamelCase(Category[el.category]),
          tooltip: categoriesTooltip[el.category] || EMPTY_STRING,
        }
      ),
    );
    return {
      ...element,
      categories,
    };
  })
);

export default categorizeCategories;
