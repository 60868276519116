import { FlattenSimpleInterpolation, css } from 'styled-components/macro';

export const reportButtonStyles = css`
  margin-right: 1.5rem;

  ${({ theme }) => theme.media.tablet`
    margin-right: 0.75rem;
  `}
` as FlattenSimpleInterpolation;

export const connectButtonStyles = css`
  margin-left: 1.5rem;

  ${({ theme }) => theme.media.tablet`
      margin-left: 0.5rem;

      svg {
        width: 1rem;
        height: auto;
      }
  `}
` as FlattenSimpleInterpolation;
