import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

export enum RequestResult {
  Existed,
  Unique,
  HAPI
}

export const title: FlattenSimpleInterpolation = css`
  line-height: 120%;
  text-align: center;
  font-size: 2.75rem;

  ${({ theme }) => theme.media.mobile`
    font-size: 1.5rem;
  `}
` as FlattenSimpleInterpolation;

export const button: FlattenSimpleInterpolation = css`
  font-size: 1rem;

  ${({ theme }) => theme.media.mobile`
    width: 100%;
  `}
` as FlattenSimpleInterpolation;

export const clearButton: FlattenSimpleInterpolation = css`
  margin-right: 1rem;
`;

export const checkButton: FlattenSimpleInterpolation = css`
  ${({ theme }) => theme.media.mobile`
    width: 100%;
  `}
` as FlattenSimpleInterpolation;
