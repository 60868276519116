import { Network } from 'shared/enums';
import LineaIcon from 'assets/images/icons/networks/Linea.svg';
import BaseIcon from 'assets/images/icons/networks/Base.svg';
import BlastIcon from 'assets/images/icons/networks/Blast.svg';
import EthereumIcon from 'assets/images/icons/networks/Ethereum.svg';
import OptimismIcon from 'assets/images/icons/networks/Optimism.svg';
import BNBIcon from 'assets/images/icons/networks/BNB.svg';
import PolygonIcon from 'assets/images/icons/networks/Polygon.svg';
import ScrollIcon from 'assets/images/icons/networks/Scroll.svg';
import AuroraIcon from 'assets/images/icons/networks/Aurora.svg';
import zkSyncIcon from 'assets/images/icons/networks/ZKSync.svg';
import SepoliaIcon from 'assets/images/icons/networks/sepolia.svg';
import GoerliIcon from 'assets/images/icons/networks/goerli.svg';

import {
  aurora,
  base,
  blastSepolia,
  bsc,
  goerli,
  linea,
  mainnet,
  optimism,
  polygon,
  scrollTestnet,
  sepolia,
  zkSync,
  zkSyncTestnet,
} from 'viem/chains';
import { RewardNetworksType } from 'shared/interfaces';

const enumNetworkToNumbers = [
  Network.Bitcoin,
  Network.Ethereum,
  Network.Linea,
  Network.Tron,
  Network.BNBChain,
  Network.Solana,
  Network.Polygon,
  Network.NEAR,
  Network.Aurora,
  Network.OKTC,
];

export const convertEnumNetworkToNumber = (network: Network): number => {
  return enumNetworkToNumbers.indexOf(network);
};

export const convertNumberToEnumNetwork = (number: number): Network => {
  return enumNetworkToNumbers[number];
};

export const getChainIcon = (chainId?: number) => {
  switch (chainId) {
    case mainnet.id:
      return EthereumIcon;
    case sepolia.id:
      return SepoliaIcon;
    case zkSync.id:
      return zkSyncIcon;
    case zkSyncTestnet.id:
      return zkSyncIcon;
    case goerli.id:
      return GoerliIcon;
    case base.id:
      return BaseIcon;
    case polygon.id:
      return PolygonIcon;
    case bsc.id:
      return BNBIcon;
    case scrollTestnet.id:
      return ScrollIcon;
    case optimism.id:
      return OptimismIcon;
    case linea.id:
      return LineaIcon;
    case aurora.id:
      return AuroraIcon;
    case blastSepolia.id:
      return BlastIcon;
    default:
      return LineaIcon;
  }
};

export const getChainNetwork = (chainId: number): string => {
  switch (chainId) {
    case mainnet.id:
      return 'mainnet';
    case sepolia.id:
      return 'sepolia';
    case zkSync.id:
      return 'zksync-era';
    case zkSyncTestnet.id:
      return 'zksync-era-testnet';
    case goerli.id:
      return 'goerli';
    case base.id:
      return 'base';
    case polygon.id:
      return 'polygon';
    case bsc.id:
      return 'bsc';
    case scrollTestnet.id:
      return 'scroll-testnet';
    case optimism.id:
      return 'optimism';
    case linea.id:
      return 'linea-mainnet';
    case aurora.id:
      return 'aurora';
    case blastSepolia.id:
      return 'blast-sepolia';
    default:
      return 'linea-mainnet';
  }
};

export const updateRewardNetworks = (
  rewardNetworks: RewardNetworksType,
  chainIdNumber: number,
): RewardNetworksType => rewardNetworks.map((network) => (
  network.chainIdNumber === chainIdNumber
    ? { ...network, loading: true }
    : { ...network, disabled: true }
));
