import React from 'react';

const FinancialFraud: React.FC = () => {
  return (
    <>
      <div>
        <h2>Financial Fraud</h2>
        <p>
          Reports in this category relate to deceitful activities or schemes to acquire money or assets dishonestly
          within financial contexts.
        </p>
      </div>
      <div>
        <h3>RugPull</h3>
        <p>A practice where developers abandon a project and run away with investor funds.</p>
      </div>
      <ul>
        <li>
          Pump and Dump Schemes: Manipulations with asset prices with the aim of profit through artificial inflation and
          subsequent sale.
        </li>
        <li>Ponzi Schemes: A fraudulent investment scheme promising high returns with minimal risks.</li>
        <li>Honeypots: Not a real token created to attract a user created to steal funds.</li>
      </ul>
      <div>
        <h3>Mixing Services</h3>
        <p>
          A platform that mixes different streams of potentially identifiable cryptocurrency to obfuscate the original
          source.
        </p>
      </div>
    </>
  );
};

export default FinancialFraud;
