import styled from 'styled-components';
import { ReportStatus } from '../../enums';

const Container = styled.span<{status: ReportStatus}>`
  background: ${({ theme, status }) => {
    switch (status) {
      case ReportStatus.Approved:
        return theme.approvedGreen;
      case ReportStatus.Pending:
        return theme.pendingYellow;
      default:
        return theme.declinedRed;
    }
  }};
  color: ${({ theme }) => theme.white};
  font-size: 0.75rem;
  font-family: ${({ theme }) => theme.interSemiBold};
  padding: 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  max-width: 5.4rem;
`;

const Icon = styled.img`
  width: 0.917rem;
  height: 0.917rem;
  margin-right: 0.313rem;
`;

export default {
  Container,
  Icon,
};
