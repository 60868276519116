import React from 'react';
import PHOTO_ICON from 'assets/images/icons/upload-image-icon.svg';
import { formatSize } from 'shared/utils';
import {
  Control, useFormContext, useWatch,
} from 'react-hook-form';
import Tooltip from 'shared/components/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info-circle.svg';

import PhotoProofStyles from './styles';
import FileArea from '../FileArea';
import { IBasic } from '../../SecondStage';
import { tooltipForm } from '../../SecondStage/constants';

type PartialIBasic = Partial<IBasic>;

const PhotoProof = ({ control }:{control: Control<PartialIBasic, any>}) => {
  const { proofs } = useWatch({ control });

  const {
    setValue,
    formState: { errors },
  } = useFormContext<IBasic>();

  const deletePhoto = (index: number) => {
    if (!proofs) return;
    setValue('proofs', [...proofs.filter((_, id) => id !== index)], { shouldValidate: true });
  };

  return (
    <div>
      <div>
        <PhotoProofStyles.Label>
          <Tooltip tooltip={tooltipForm.photoProof} iconRight={<InfoIcon />}>
            Photo Proof
          </Tooltip>
        </PhotoProofStyles.Label>
        <FileArea />
      </div>
      {!!proofs?.length && (
        <PhotoProofStyles.UploadedPhotos>
          {proofs.map((file, index) => (
            <PhotoProofStyles.UploadedFile key={`${file.size}-${index}`}>
              <img src={PHOTO_ICON} alt="uploaded icon" />
              <PhotoProofStyles.UploadedTextWrapper>
                <PhotoProofStyles.PhotoName>
                  {file.name}
                </PhotoProofStyles.PhotoName>
                <PhotoProofStyles.DownloadSizeText>
                  {formatSize(file.size)}
                </PhotoProofStyles.DownloadSizeText>
              </PhotoProofStyles.UploadedTextWrapper>
              <PhotoProofStyles.Close onClick={() => deletePhoto(index)} />
            </PhotoProofStyles.UploadedFile>
          ))}
        </PhotoProofStyles.UploadedPhotos>
      )}
      {errors?.proofs && (
        <PhotoProofStyles.Error>
          {errors?.proofs.message}
        </PhotoProofStyles.Error>
      )}
    </div>
  );
};

export default PhotoProof;
