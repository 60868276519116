const getCorrectIPFSLinks = <T>(linksMap: T): T => {
  const result: T = {} as T;
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in linksMap) {
    let value = '';
    if (
      (linksMap[key] && (linksMap[key] as string)?.startsWith('http'))
      || (linksMap[key] as string)?.startsWith('blob')
    ) {
      value = (linksMap[key] as string) || '';
    } else if (linksMap[key]) {
      value = `https://${linksMap[key]}.ipfs.dweb.link`;
    }
    result[key] = value as T[Extract<keyof T, string>];
  }
  return result;
};

export default getCorrectIPFSLinks;
