import { ReportStatus } from 'shared/enums';

const FILTER_CATEGORIES = [
  { name: 'All', type: 'All' },
  { name: 'Pending', type: ReportStatus.Pending },
  { name: 'Approved', type: ReportStatus.Approved },
  { name: 'Declined', type: ReportStatus.Declined },
];

const FILTER_DEFAULT_VALUE = 'All';

export { FILTER_CATEGORIES, FILTER_DEFAULT_VALUE };
