import React from 'react';
import EMPTY_ICON from 'assets/images/icons/empty-reports.svg';
import ClientReport from 'shared/components/ReportList/components/Report/Client';
import InvestigatorReport from 'shared/components/ReportList/components/Report/Investigator';
import ReportListStyles from './styles';
import { IReportList } from './interface';

const ReportList: React.FC<IReportList> = ({
  reports,
  isClientReport = true,
}) => {
  return (
    <ReportListStyles.Wrapper>
      {Boolean(reports.length) && reports.map((report) => (
        isClientReport ? <ClientReport key={report.id} report={report} />
          : <InvestigatorReport key={report.id} report={report} />
      ))}

      {!reports.length && (
        <ReportListStyles.EmptyReport>
          <img src={EMPTY_ICON} alt="empty icon" />
          <span>
            No reports yet
          </span>
        </ReportListStyles.EmptyReport>
      )}
    </ReportListStyles.Wrapper>
  );
};

export default ReportList;
