import styled from 'styled-components';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 39.5rem;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  gap: 2rem;
  ${({ theme }) => theme.media.mobile`
    padding: 0 1rem;
  `}
`;

export default {
  Page,
  Container,
};
