import React from 'react';

const IllegalActivity: React.FC = () => {
  return (
    <>
      <div>
        <h2>Illegal Activity</h2>
        <p>
          Reports encompass a range of actions that are expressly forbidden by law. This can include activities from
          funding terrorism to participating in darknet marketplaces. These actions not only breach legal standards but
          often carry significant ethical and moral implications.
        </p>
      </div>
      <ul>
        <li>Terrorist Finance: Financing terrorism.</li>
        <li>Sanction: Violation of international sanctions.</li>
        <li>Darknet Markets: Sale of prohibited goods on the Dark market.</li>
        <li>War Donations: RTF.</li>
      </ul>
    </>
  );
};

export default IllegalActivity;
