const termsAndConditions = [
  {
    title: 'Users',
    values: [
      {
        title: 'User Reports',
        description: 'Users of the Platform ("Users") are permitted to create and file reports regarding suspicious or potentially malicious addresses.',
      },
      {
        title: 'Reporting Criteria',
        description: 'Users must ensure that they do not file reports for addresses that have already been reported on the platform. Duplicate reports are not allowed and can result in penalties, including the potential loss of rewards or bans. Duplicates are considered as such if they match addresses already flagged in the “Check address” utility, which checks them in Scamfari smart contracts, HAPI Protocol, and a database of addresses accepted to initial seasons of Scamfari.',
      },
      {
        title: 'Ban for Malicious Data',
        description: 'Users who consistently submit inaccurate, bad, or malicious data to the platform will face consequences. This may include the loss of rewards or a permanent ban from using the Platform.',
      },
    ],
  },
  {
    title: 'Data',
    values: [
      {
        title: 'Data Storage',
        description: 'All report data submitted to Scamfari is stored on EVM-compatible blockchains. This ensures transparency, security, and permanent storage of the report information.',
      },
      {
        title: 'User Metrics Collection',
        description: 'The platform gathers and stores basic user metrics. This data collection is for the purpose of improving the user interface and detecting bad agents. It aims to ensure a seamless and secure experience for all users.',
      },
      {
        title: 'Data Usage',
        description: 'The data collected and stored by the Platform consists of user reports data and metrics gathered about user interaction with the UI. This data will be indexed and subsequently used by HAPI Protocol and HAPI Labs for further research and applications.',
      },
      {
        title: 'Data Sharing',
        description: 'The Platform reserves the right to share the collected data with trusted partners and affiliates. This data-sharing is intended for the betterment of the online and blockchain community.',
      },
    ],
  },
  {
    title: 'Rewards',
    values: [
      {
        title: 'Rewards for Accepted Reports',
        description: 'Users are eligible to receive a reward for each report that is accepted by the Platform validators. Reward is counted towards reporter\'s account at the moment of it\'s acceptance by a validator. The nature and amount of the reward will be detailed separately and can any time be verified on the smart contract.',
      },
      {
        title: 'Reward Fund Limitations',
        description: 'The Platform smart contract is replenished periodically. Users should be aware that rewards are not guaranteed to be immediately available. The availability of rewards depends on the balance of the Platform smart contract.',
      },
    ],
  },
  {
    title: 'Validators',
    values: [
      {
        title: 'Validators Definition',
        description: 'Validators are a specific class of users who have been whitelisted by the Platform and act on its behalf.',
      },
      {
        title: 'Validation of Reports',
        description: 'Reports submitted by Users are subject to review by designated Plaftorm validators. These validators have the responsibility of either accepting or rejecting the reports based on the provided information and the accuracy of the claims. Validators are responsible to process a report within 48 hours on business days.',
      },
      {
        title: 'Rejection Notices',
        description: 'If a report is deemed inaccurate or does not meet the required standards, the Platform validators will provide the User with a rejection notice, detailing the reason(s) for rejection.',
      },
      {
        title: 'Validator\'s Decision',
        description: 'Scamfari validators possess the final say regarding the quality, accuracy, and validity of the report. Their decision is final and cannot be disputed.',
      },
    ],
  },
  {
    title: 'Liability',
    values: [
      {
        title: 'Limited Liability',
        description: 'The Platform is not responsible for funds or money that may be stolen or misappropriated by third parties. Users should exercise caution and due diligence when dealing with suspicious addresses.',
      },
      {
        title: 'Understanding of Risks',
        description: 'Users acknowledge and agree that using any services offered by the Platform is fraught with significant risks and potential financial losses. These risks include, but are not limited to:',
        subValues: [
          'Technological complexity and potential misunderstandings: Features, functions, parameters, and other qualities of the Platform and its Base Technology may be technologically intricate or challenging to comprehend or assess.',
          'Vulnerabilities: The Platform and its Base Technology may be susceptible to attacks targeting their security, integrity, or functionality. This can include attacks that suppress normal operations of the blockchain or other foundational technologies.',
          'Unexpected Changes: The Platform or Base Technology may undergo unintended changes or cease to function as expected due to modifications in the Base Technology, changes initiated through embedded features or functions, or as a result of an attack. These modifications can encompass "changes to the source code" or a "rollback" of the Platform or the blockchain.',
          'Platform Control: The Platform Team reserves the right to pause, modify, or revoke access to the Platform\'s services to comply with laws, regulations, or when directed by governmental entities, or due to other reasons deemed necessary by the Platform Team.',
          'Asset Risks: As with other crypto assets, the risks specified above might lead to loss of capital, significant decrease or total loss in asset value, difficulties accessing or transferring assets, trading limitations, inability to derive financial benefits associated with the asset, among other financial losses.',
        ],
      },
      {
        title: 'Legal Reporting',
        description: 'The Platform is not responsible for filing legal reports to any governmental structures or third-party private parties.',
      },
    ],
  },
];

export default termsAndConditions;
