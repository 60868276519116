import React from 'react';
import { useAccount } from 'wagmi';
import UserAvatar from 'shared/components/UserAvatar';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import { EModals, showModal } from 'store/slices/modals';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectRewardToken } from 'store/slices/token';
import { selectReportsByUser } from 'store/slices/reports';
import { selectUser } from 'store/slices/user';
import { displayAmount, sliceString } from 'shared/utils';
import { EMPTY_STRING } from 'shared/constants';
import SCMTokenIcon from 'assets/images/icons/scamfari-token.svg';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import UserAccountStyles from './styles';
import { avatarStyles, claimButton } from './constants';

const UserAccountInformation: React.FC = () => {
  const dispatch = useAppDispatch();
  const { address } = useAccount();
  const token = useAppSelector(selectRewardToken);

  const { reward } = useAppSelector(selectUser);
  const { amount } = useAppSelector(selectReportsByUser);

  const openClaimModal = () => {
    if (!token) return;
    dispatch(
      showModal({
        modal: EModals.CLAIM_MODAL,
        props: { token },
      }),
    );

    MixpanelService.trackEvent(EMixpanelEvents.OPEN_CLAIM_MODAL_CLICKED);
  };

  return (
    <UserAccountStyles.YellowBackground>
      <UserAccountStyles.Wrapper>
        <UserAccountStyles.UserAvatarAddress>
          <UserAvatar nickname={address} styles={avatarStyles} isHunter={false} />
          <UserAccountStyles.Address>{sliceString(address || EMPTY_STRING)}</UserAccountStyles.Address>
        </UserAccountStyles.UserAvatarAddress>
        <UserAccountStyles.FlexRow>
          <UserAccountStyles.Column>
            <p>Reports</p>
            <p>{amount}</p>
          </UserAccountStyles.Column>
          <UserAccountStyles.Column>
            <p>Reward Tokens</p>
            <p>
              <UserAccountStyles.TokenSymbol src={SCMTokenIcon} alt="token symbol" />
              {`${displayAmount(reward, token?.decimal)} ${token?.symbol || EMPTY_STRING}`}
            </p>
          </UserAccountStyles.Column>
          <Button
            label="Claim"
            handleClick={openClaimModal}
            variant={EButtonActions.GREY_BUTTON}
            styles={claimButton}
            disabled={!token}
          />
        </UserAccountStyles.FlexRow>
      </UserAccountStyles.Wrapper>
    </UserAccountStyles.YellowBackground>
  );
};

export default UserAccountInformation;
