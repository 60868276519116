import React from 'react';

import titleStyles from 'views/Main/constants';
import { EButtonActions } from 'shared/enums';

import EMPTY_ICON from 'assets/images/icons/empty-reports.svg';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectUser } from 'store/slices/user';
import { ReactComponent as ViewAllIcon } from 'assets/images/icons/view-all.svg';
import Report from 'shared/components/ReportList/components/Report/Client';
import { MixpanelService } from 'services/mixpanel';
import { selectDataByNetwork } from 'store/slices/networks';
import { ROUTES } from 'routes/constants';
import { useHistory } from 'react-router-dom';
import ReportListStyles from './styles';
import Title from '../Title';
import Button from '../Buttons';
import ViewButton from '../HuntersList/constants';

const LastAddedReports: React.FC = () => {
  const history = useHistory();
  const { isInvestigator } = useAppSelector(selectUser);
  const dataByNetwork = useAppSelector(selectDataByNetwork);
  const reports = dataByNetwork?.lastReports.slice(0, 5) || [];

  const viewAllReports = () => {
    history.push(ROUTES.REPORTS);
    MixpanelService.trackEvent('Navigate to Reports');
  };

  return (
    <ReportListStyles.Wrapper>
      <Title value="Last Added Reports" styles={titleStyles} />
      {reports.length ? (
        <ReportListStyles.List>
          {reports.slice(-5).map((report) => (
            <Report key={report.id} report={report} />
          ))}
        </ReportListStyles.List>
      ) : (
        <ReportListStyles.EmptyReport>
          <img src={EMPTY_ICON} alt="empty icon" />
          <span>No reports yet</span>
        </ReportListStyles.EmptyReport>
      )}
      {isInvestigator && (
        <Button
          label="View All"
          iconRight={<ViewAllIcon />}
          handleClick={viewAllReports}
          variant={EButtonActions.WHITE_BUTTON}
          styles={ViewButton}
        />
      )}
    </ReportListStyles.Wrapper>
  );
};

export default LastAddedReports;
