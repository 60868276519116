import { ZERO_STRING, EMPTY_STRING } from '../constants';

export const formatRank = (value: number) => String(value).padStart(2, ZERO_STRING);

export const capitalizeFirstLetter = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const addSpacesBetweenWords = (text?: string) => {
  if (!text) return EMPTY_STRING;

  return text
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
    .trim();
};

export const sliceString = (text: string, step = 4) => {
  if (!text) return '';

  if (text.length > 20) {
    const head = text.substring(2, step + 2);
    const tail = text.substring(text.length - step, text.length);
    return `${head}…${tail}`;
  }
  return text;
};
