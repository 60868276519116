import { DropdownNetwork, Network } from 'shared/enums';
import EthereumIcon from 'assets/images/icons/networks/Ethereum.svg';
import LineaIcon from 'assets/images/icons/networks/Linea.svg';
import BNBIcon from 'assets/images/icons/networks/BNB.svg';
import PolygonIcon from 'assets/images/icons/networks/Polygon.svg';
import AuroraIcon from 'assets/images/icons/networks/Aurora.svg';
import NEARIcon from 'assets/images/icons/networks/NEAR.svg';
import BitcoinIcon from 'assets/images/icons/networks/Bitcoin.svg';
import OKTCIcon from 'assets/images/icons/networks/OKTC.svg';
import TronIcon from 'assets/images/icons/networks/Tron.svg';
import SolanaIcon from 'assets/images/icons/networks/Solana.svg';

interface IDropdownElement<Name extends DropdownNetwork | Network | string> {
  name: Name;
  iconPath?: string;
  active?: boolean;
}

export interface IDropdown {
  elements: IDropdownElement<Network | string>[];
  title?: string;
  isHeader?: boolean;
  isFullWidth?: boolean;
  onChange: (name: any) => void;
}

export interface INetworkDropdown {
  elements: IDropdownElement<DropdownNetwork>[];
  title: string;
  onChange: (name: DropdownNetwork) => void;
}

export interface IReducerNetworkDropdown {
  active: IDropdownElement<DropdownNetwork>[],
  testnet: IDropdownElement<DropdownNetwork>[],
  soon: IDropdownElement<DropdownNetwork>[]
}

const ReportNetworks = [
  {
    iconPath: BitcoinIcon,
    name: Network.Bitcoin,
  },
  {
    iconPath: EthereumIcon,
    name: Network.Ethereum,
  },
  {
    iconPath: LineaIcon,
    name: Network.Linea,
  },
  {
    iconPath: TronIcon,
    name: Network.Tron,
  },
  {
    iconPath: BNBIcon,
    name: Network.BNBChain,
  },
  {
    iconPath: SolanaIcon,
    name: Network.Solana,
  },
  {
    iconPath: PolygonIcon,
    name: Network.Polygon,
  },
  {
    iconPath: NEARIcon,
    name: Network.NEAR,
  },
  {
    iconPath: AuroraIcon,
    name: Network.Aurora,
  },
  {
    iconPath: OKTCIcon,
    name: Network.OKTC,
  },
];

const NetworkIconMap: Record<Network, string> = {
  [Network.Bitcoin]: BitcoinIcon,
  [Network.Ethereum]: EthereumIcon,
  [Network.Linea]: LineaIcon,
  [Network.Tron]: TronIcon,
  [Network.BNBChain]: BNBIcon,
  [Network.Solana]: SolanaIcon,
  [Network.Polygon]: PolygonIcon,
  [Network.NEAR]: NEARIcon,
  [Network.Aurora]: AuroraIcon,
  [Network.OKTC]: OKTCIcon,
};

export { ReportNetworks, NetworkIconMap };
