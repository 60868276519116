import React from 'react';

const Scam: React.FC = () => {
  return (
    <>
      <div>
        <h2>Scam</h2>
        <p>
          Reports in this category cover deceptive or fraudulent schemes designed to cheat someone out of money or other assets. These can range from social media scams to more elaborate fraudulent website operation deceptive schemes to deceive individuals into parting with assets or information.
        </p>
      </div>
      <div>
        <h3>Social Scam</h3>
        <p>Fraud on social media.</p>
      </div>
      <ul>
        <li>Blackmail: Sending malicious messages with viral attachments or fake links.</li>
        <li>Social media Scam: Social media Scam.</li>
      </ul>
      <div>
        <h3>Fraudulent Website</h3>
        <p>Raising funds under the guise of investing or charity.</p>
      </div>
      <ul>
        <li>Investment Scam.</li>
        <li>Lottery Scam.</li>
        <li>Data theft.</li>
      </ul>
      <div>
        <h3>NFT Scam</h3>
        <p>Fraud related to NFTs.</p>
      </div>
    </>
  );
};

export default Scam;
