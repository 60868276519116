import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Report from 'shared/components/ReportList/components/Report/Investigator';
import { IReport } from 'shared/interfaces';

import ToastService from 'services/toast-service';

import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectCurrentChainId } from 'store/slices/networks';
import { EQueryParams } from 'shared/enums';
import { getChainNetwork, getChainIcon } from 'shared/utils/networks';

import apiService from 'services/api-service/api-service';
import formatIndexerReport from 'shared/utils/formatIndexerReport';
import { environment } from 'shared/constants';
import styles from './styles';
import { ReportRouteParams } from './interface';

const ReportPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<ReportRouteParams>();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryChainId = searchParams.get(EQueryParams.CHAIN_ID);

  const id = params.id ? Number(params.id) : 0;
  const [report, setReport] = useState<IReport | null>(null);
  const appChainId = useAppSelector(selectCurrentChainId);
  const chainId = Number(queryChainId) ?? appChainId;
  const getReport = async (chainIdLocal: number) => {
    try {
      const zeroReport = environment === 'mainnet' ? 0 : 1;
      const reportOutput = await apiService.getReports({ skip: id - zeroReport, take: 1, chain_id: chainIdLocal });
      const formattedReport = reportOutput ? formatIndexerReport(reportOutput.items[0]) : null;
      formattedReport && setReport(formattedReport);
    } catch (error) {
      ToastService.error({ text: 'Report loading error' });
      // history.push(ROUTES.HOME);
      console.error(`Error: while loading report by id \n ${error}`);
    }
  };

  useEffect(() => {
    if (!queryChainId) {
      searchParams.set(EQueryParams.CHAIN_ID, chainId.toString());
      history.push({ search: searchParams.toString() });
    }
    getReport(chainId);
  }, []);

  if (!report || !id) return null;

  return (
    <styles.ReportWrapper>
      <styles.Header>
        <h1>
          Report #
          {id}
        </h1>
        <styles.Network>
          uploaded from
          {' '}
          {getChainNetwork(chainId)}
          <img src={getChainIcon(chainId)} alt="network icon" />
        </styles.Network>
      </styles.Header>

      <Report report={report} isReportPage />
    </styles.ReportWrapper>
  );
};

export default ReportPage;
