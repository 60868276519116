import React, { Dispatch, SetStateAction, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactComponent as BackIcon } from 'assets/images/icons/back.svg';

import {
  Category, EButtonActions, MainCategories, Network, subcategoryGroups,
} from 'shared/enums';
import { addSpacesBetweenWords } from 'shared/utils';

import Button from 'shared/components/Buttons';

import { ECreateReportStage } from 'shared/interfaces';
import { FormProvider, useForm } from 'react-hook-form';

import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import { closeModal } from 'store/slices/modals';

import loadAccountDetails from 'store/actions/loadAccountDetails';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';

import { ROUTES } from 'routes/constants';
import SecondStageStyles from './styles';

import { checkAddresses, createReports } from './helpers';
import { scheme, defaultValues } from './constants';
import Form from './Form';

interface ISecondStage {
  mainCategory: MainCategories;
  setStage: Dispatch<SetStateAction<ECreateReportStage>>;
}

export interface IBasic {
  url: string;
  network: Network;
  address: string;
  proofs: File[];
  description: string;
  subcategory: Category | null;
  country: string;
}

const SecondStage: React.FC<ISecondStage> = ({ mainCategory, setStage }) => {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAssent, setIsAssent] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const methods = useForm<Partial<IBasic>>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: zodResolver(scheme),
    defaultValues,
  });

  const {
    trigger,
    getValues,
    setError,
    formState: { isValid: isValidForm },
  } = methods;

  const handleClick = async () => {
    try {
      setIsButtonLoading(true);
      const values = getValues();
      const isValid = await trigger();
      const errorsGlobal = await checkAddresses(values, setError);
      if (!isValid || errorsGlobal) return;
      await createReports(values, () => dispatch(closeModal()));
      MixpanelService.trackEvent(EMixpanelEvents.REPORT_CREATED);
    } catch (error) {
      console.error(`Error while create report - ${error}`);
    } finally {
      setIsButtonLoading(false);
      dispatch(loadAccountDetails());
    }
    MixpanelService.trackEvent(EMixpanelEvents.CREATE_REPORT_MODAL_SECOND_STEP);
  };

  const backToFirstStage = () => {
    setStage(ECreateReportStage.FIRST);
    MixpanelService.trackEvent(EMixpanelEvents.BACK_TO_FIRST_STEP_REPORT_MODAL);
  };

  const acceptanceOfTheAgreement = () => {
    window.open(`${window.origin}${ROUTES.TERMS_AND_CONDITIONS}`, '_blank');
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    MixpanelService.trackEvent(EMixpanelEvents.TERMS_AND_CONDITIONS_CLICKED);
  };

  return (
    <SecondStageStyles.Container>
      <SecondStageStyles.Category $mainCategory={mainCategory}>
        {addSpacesBetweenWords(subcategoryGroups[MainCategories.IllegalActivity][0].name)}
      </SecondStageStyles.Category>
      <FormProvider {...methods}>
        <Form />
      </FormProvider>
      <SecondStageStyles.Approve>
        <SecondStageStyles.AcceptInput>
          <input type="checkbox" defaultChecked={isAssent} onChange={() => setIsAssent(!isAssent)} />
          <svg viewBox="0 0 21 21">
            <polyline points="5 10.75 8.5 14.25 16 6" />
          </svg>
        </SecondStageStyles.AcceptInput>
        <SecondStageStyles.ApproveDescription>
          I agree to the platform
          {' '}
          <span aria-hidden="true" onClick={acceptanceOfTheAgreement}>
            terms and conditions
          </span>
        </SecondStageStyles.ApproveDescription>
      </SecondStageStyles.Approve>
      <SecondStageStyles.ButtonWrapper>
        <Button
          label="Close"
          handleClick={backToFirstStage}
          variant={EButtonActions.GREY_BUTTON}
          iconLeft={<BackIcon />}
        />
        <Button
          label="Create Report"
          handleClick={handleClick}
          variant={EButtonActions.YELLOW_BUTTON}
          loading={isButtonLoading}
          disabled={isButtonLoading || !isAssent || !isValidForm}
        />
      </SecondStageStyles.ButtonWrapper>
    </SecondStageStyles.Container>
  );
};

export default SecondStage;
