import styled from 'styled-components';

const Title = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 4rem;
  line-height: 76.8px;
  font-weight: 700;
  margin: 1rem 0;
  ${({ theme }) => theme.media.mobile`
    font-size: 2rem;
    line-height: 38.4px;
    text-align: center;
  `}
`;

const Subtitle = styled.div`
  line-height: 140%;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.lightGrey};
  font-family: ${({ theme }) => theme.interRegular};
  margin-bottom: 3rem;
  ${({ theme }) => theme.media.mobile`
    font-size: 1rem;
    text-align: center;
    line-height: 22.4px;
    >br{
      display: none;
    }
  `}

`;

const Wrapper = styled.div`
  ${({ theme }) => theme.media.tablet`
    margin-left: 4.5rem;
  `}
  ${({ theme }) => theme.media.mobile`
    font-size: 1rem;
    margin-left: 0;
    padding: 0 1.5rem;
  `}
`;

const ActionWrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  ${({ theme }) => theme.media.mobile`
     justify-content: center;
     gap: 1rem;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.mobile`
    flex-direction: column-reverse;
  `}
`;
const MainImage = styled.div`
  img {
    ${({ theme }) => theme.media.mobile`
    height: 180px;
  `}
  }
`;

export default {
  Title,
  Subtitle,
  Wrapper,
  Container,
  ActionWrapper,
  MainImage,
};
