export enum EEnvironment {
  TESTNET = 'testnet',
  MAINNET = 'mainnet',
}

export enum EButtonActions {
  BLACK_BUTTON = 'black_button',
  WHITE_BUTTON = ' white_button',
  YELLOW_BUTTON = 'yellow_button',
  OPACITY_YELLOW_BUTTON = 'opacity_yellow_button',
  GREY_BUTTON = 'grey_button',
  RED_BUTTON = 'red_button',
  GREEN_BUTTON = 'green_button',
}

export enum ERoutes {
  REPORTS = 'Reports',
  HUNTERS = 'Hunters',
  ABOUT = 'About',
  FAQs = 'FAQs',
  REWARDS = 'Rewards',
  GLOSSARY = 'Glossary',
}

export enum EDimensions {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LAPTOP = 'LAPTOP',
  DESKTOP = 'DESKTOP',
  UNKNOWN = 'UNKNOWN',
}

export enum KeyboardButton {
  ENTER_BUTTON = 'Enter',
}

export enum ENetworks {
  SEPOLIA = 'SEPOLIA',
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  LINEA = 'LINEA',
  AURORA = 'AURORA',
}

export enum EWalletMethods {
  REQUEST_ACCOUNTS = 'eth_requestAccounts',
  ADD_ETHEREUM_CHAIN = 'wallet_addEthereumChain',
  GET_ACCOUNT = 'eth_accounts',
  DISCONNECT = 'wallet_requestPermissions',
  CHANGE_NETWORK = 'wallet_switchEthereumChain',
}

export enum EToasterType {
  SUCCESS_ACTION = 'SUCCESS_ACTION',
  WARNING_ACTION = 'WARNING_ACTION',
  ERROR_ACTION = 'ERROR_ACTION',
}

export enum EQueryParams {
  CHAIN_ID = 'chain_id',
}

export * from './report.enums';
export * from './createReport.enums';
