import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';
import { colors } from 'theme';

export const titleStyles = css`
  font-size: 2rem;
  text-align: left;
  ${({ theme }) => theme.media.mobile`
    font-size: 1.5rem;
  `}
` as FlattenSimpleInterpolation;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 39.5rem;
  margin: 2rem auto 0;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  ${({ theme }) => css`
    ${theme.media.tablet(`
      width: auto;
      padding: 0 6.3rem;
    `)}
    ${theme.media.mobile(`
      padding: 0 1rem;
    `)}
  `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  p {
    color: ${({ theme }) => theme.lightGrey};
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%;
  }
`;

const WrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const StyledButton = (isActive: boolean) => css`
  padding: 0 0.5rem;
  font-size: 0.75rem;
  height: 2.5rem;
  color: ${colors.white};
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
  ${isActive
    && css`
      background-color: ${colors.white};
      color: ${colors.black};
      &:hover {
        background-color: ${colors.white};
        opacity: 0.9;
      }
      &:active {
        background-color: ${colors.white};
      }
  `}
`;

export default {
  Page,
  Container,
  Header,
  WrapperButtons,
  StyledButton,
};
