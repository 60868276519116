const romanNumerals: { [key: number]: string } = {
  1: 'I',
  4: 'IV',
  5: 'V',
  9: 'IX',
  10: 'X',
  40: 'XL',
  50: 'L',
  90: 'XC',
  100: 'C',
  400: 'CD',
  500: 'D',
  900: 'CM',
  1000: 'M',
};

const romanLowerCaseNumerals: { [key: number]: string } = {
  1: 'i',
  4: 'iv',
  5: 'v',
  9: 'ix',
  10: 'x',
  40: 'xl',
  50: 'l',
  90: 'xc',
  100: 'c',
  400: 'cx',
  500: 'd',
  900: 'cm',
  1000: 'm',
};

export default function convertNumberToRoman(num: number, isLowerCase = false): string {
  let result = '';
  let newNumber = num;
  const numerals = isLowerCase ? romanLowerCaseNumerals : romanNumerals;
  const sortedValues = Object.keys(numerals)
    .map(Number)
    .sort((a, b) => b - a);

  for (const value of sortedValues) {
    while (newNumber >= value) {
      result += numerals[value];
      newNumber -= value;
    }
  }

  return result;
}
