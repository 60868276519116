import styled from 'styled-components';
import { css } from 'styled-components/macro';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 39.5rem;
  margin: 2rem auto 0;
  ${({ theme }) => css`
    ${theme.media.tablet(`
      width: auto;
      padding: 0 6.3rem;
    `)}
    ${theme.media.mobile(`
      padding: 0 1rem;
    `)}
  `}
`;

const Title = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2rem;
  ${({ theme }) => css`
    ${theme.media.mobile(`
      font-size: 1.25rem;
    `)}
  `}
`;

const Text = styled.div`
  margin-top: 2rem;
  font-weight: 400;
  line-height: 140%;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.lightGrey};

  & p {
    padding-top: 1rem;
    &:first-child {
      padding-top: 0;
    }
  }

  ${({ theme }) => css`
    ${theme.media.mobile(`
      font-size: 1rem;
    `)}
  `}
`;

export default {
  Page,
  Container,
  Title,
  Text,
};
