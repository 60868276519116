import React, { useState } from 'react';

import FaqStyles from 'views/FAQ/styles';
import CloseIcon from 'assets/images/icons/close-drop-icon.svg';
import OpenIcon from 'assets/images/icons/open-drop-icon.svg';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';

interface IFaqItem {
  question: string;
  answer: JSX.Element;
}

const FaqItem: React.FC<IFaqItem> = ({ question, answer }) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const iconSource = isShow ? CloseIcon : OpenIcon;

  const toggleFAQ = () => {
    setIsShow((prev) => !prev);

    !isShow && MixpanelService.trackEvent(EMixpanelEvents.OPEN_FAQ, { question });
  };

  return (
    <div>
      <FaqStyles.HeaderWrapper>
        <FaqStyles.Title>{question}</FaqStyles.Title>
        <FaqStyles.Icon onClick={toggleFAQ}>
          <img src={iconSource} alt="icon" />
        </FaqStyles.Icon>
      </FaqStyles.HeaderWrapper>
      {isShow && <FaqStyles.AnswerText>{answer}</FaqStyles.AnswerText>}
    </div>
  );
};

export default FaqItem;
