import { createAsyncThunk } from '@reduxjs/toolkit';

import ToastService from 'services/toast-service';

import { getAccount, readContract } from '@wagmi/core';

import { setInitialData, setLimitData } from 'store/slices/reports';
import Big from 'big.js';
import getScamfariContract from 'shared/utils/getScamfariContract';
import switchNetwork from 'shared/utils/switchNetwork';
import { scamfariABI } from 'services/config/contract_abi';

import getSupportedNetwork from 'shared/utils/getSupportedNetwork';
import config, { getClient, getConfig } from 'services/config';

import { RootState } from '../index';

import loadAccountDetails from './loadAccountDetails';
import loadTokenData from './loadTokenData';

const initialLoading = createAsyncThunk<void, void, { state: RootState }>(
  'initialLoading',
  async (_, { dispatch, getState }) => {
    try {
      const { isConnected, address } = getAccount(config);
      const state = getState();
      const contractConfig = getScamfariContract(state.networks.currentChainId);

      const publicClient = getClient(state.networks.currentChainId);

      const configuration = await publicClient.readContract({
        address: getConfig(state.networks.currentChainId).contract,
        abi: scamfariABI,
        functionName: 'configuration',
      });

      if (address) {
        const dailyLimit = await readContract(config, {
          ...contractConfig,
          functionName: 'getMyDailyLimit',
          account: address,
        });
        dispatch(
          setLimitData({
            claimedToday: Big(dailyLimit[0].toString() || 0),
            todayStarted: Big(dailyLimit[1].toString() || 0),
            dailyLimit: Big(dailyLimit[2].toString() || 0),
          }),
        );
      }
      if (configuration) {
        dispatch(setInitialData({
          token: configuration[0],
          amount: Big(configuration[1].toString()),
        }));
        dispatch(loadTokenData(configuration[0]));
      }

      if (isConnected) {
        const supportedNetwork = getSupportedNetwork();
        if (supportedNetwork.needSwitch && supportedNetwork.network) {
          await switchNetwork(supportedNetwork.network.chainIdNumber);
        }

        dispatch(loadAccountDetails());
      }
    } catch (e) {
      console.error(`Error: while initial loading hunters \n ${e}`);
      ToastService.error({ text: 'Error: while initial loading hunters' });
    }
  },
);

export default initialLoading;
