import React from 'react';
import Title from 'shared/components/Title';
import FaqStyles from './styles';
import titleStyles from './constants';
import FaqItem from './components/FaqItem';

const FAQ_LIST = [
  {
    question: 'How to register?',
    answer: (
      <ul className="without-dots">
        <li>1. Go to the main page</li>
        <li>2. Connect your MetaMask wallet</li>
        <li>3. Click the “Create Report” button</li>
        <li>4. You are ready to send your first report!</li>
      </ul>
    ),
  },
  {
    question: 'How to submit a report?',
    answer: (
      <ul className="without-dots">
        <li>1. Log in with your MetaMask wallet</li>
        <li>2. Click on the &quot;Create Report&quot; button</li>
        <li>3. Choose a scam type option </li>
        <li>4. Choose the Blockchain to which the fraudulent wallet belongs</li>
        <li>5. Specify the address of the wallet</li>
        <li>6. Attach photo proof (screenshot)</li>
        <li>7. Push &quot;Create/Send Report&quot; button </li>
      </ul>
    ),
  },
  {
    question: 'What kind of reporters get rewards?',
    answer: (
      <p>
        Rewards are credited for all reports approved by the validators. Rewards
        are granted for successfully accepted reports of unique addresses.
      </p>
    ),
  },
  {
    question:
      'How to send a report if a scammer gives several wallet addresses on different networks?',
    answer: (
      <p>
        You can send multiple addresses within one report, as long as they refer
        to the same scammer and appear on your photo proofs. To do this, when
        you create a report, you must click &quot;Add address&quot;. Please note
        that each address must be submitted as a separate transaction and will
        be reviewed (and rewarded) individually.
      </p>
    ),
  },
  {
    question: 'What are the scam categories?',
    answer: (
      <ul className="with-dots">
        <li>Social Media Scammer</li>
        <p>
          A scammer that directly messages you on well-known social media
          channels such as Telegram, Twitter, Discord etc. This might also
          include imposter accounts of notable people (Elon Musk making a
          giveaway)
        </p>
        <li>Fraudulent Website</li>
        <p>
          A phishing website that is either a clone of the original well-known
          website or a giveaway/airdrop masquerade that has you connect your
          wallet to it
        </p>
        <li>Scam Project (Rugpull)</li>
        <p>
          A project that has initiated a liquidity pool drainage/sell-off aka
          Rugpull or any otherwise maliciously intended and Asset Damaging
          activity done by a project to its Token Holders. Both ongoing and past
          cases of Rugpull are accepted.
        </p>
        <li>Terrorist Financing</li>
        <p>
          Wallets actively soliciting donations to sponsor terrorist
          organizations
        </p>
      </ul>
    ),
  },
  {
    question:
      'How to check if the address exists in the database before creating the report?',
    answer: (
      <ul className="without-dots">
        <li>1. Go to a main page</li>
        <li>2. Past address to a special form</li>
        <li>3. Click &quot;Check Address&quot;</li>
        <li>
          4. In case the address is already present in our database, it will be
          rejected if you send it as a report
        </li>
      </ul>
    ),
  },
  {
    question: 'How do I get my reward?',
    answer: (
      <ul className="without-dots">
        <li>1. Login with your MetaMask wallet</li>
        <li>2. Check the amount of your rewards in appropriate section</li>
        <li>3. Click &quot;Claim Reward&quot; button</li>
        <li>4. The reward will be sent to your wallet</li>
      </ul>
    ),
  },
  {
    question: 'How do I know the status of my reports?',
    answer: (
      <>
        <p>
          Login with your MetaMask wallet. There are three statuses for reports:
        </p>
        <ul className="with-dots">
          <li>Pending. The report is under review by the validation team</li>
          <li>
            Approved. The report was approved, and the user will receive reward
            for it
          </li>
          <li>
            Declined. The report was not approved by the validation team. You
            can clarify the reason for rejection by clicking on the
            &laquo;Details&raquo; button
          </li>
        </ul>
      </>
    ),
  },
];

const FAQ: React.FC = () => {
  return (
    <FaqStyles.Page>
      <FaqStyles.Container>
        <Title value="FAQs" styles={titleStyles} />
        <FaqStyles.FaqList>
          {FAQ_LIST.map(({ question, answer }) => (
            <FaqItem
              key={question}
              question={question}
              answer={answer}
            />
          ))}
        </FaqStyles.FaqList>
      </FaqStyles.Container>
    </FaqStyles.Page>
  );
};

export default FAQ;
