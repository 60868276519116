import { ToastOptions, Slide } from 'react-toastify';

import theme from './index';

const toastOptions: ToastOptions = {
  transition: Slide,
  hideProgressBar: true,
  closeButton: false,
  position: 'top-center',
  style: {
    background: theme.borderBlack,
    borderRadius: '1rem',
    color: theme.white,
    padding: '.5rem',
    width: '100%',
  },
};

export default toastOptions;
