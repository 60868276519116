import React, { useEffect, useState } from 'react';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import { EModals, showModal } from 'store/slices/modals';
import image from 'assets/images/main-image.png';
import rewardImage from 'assets/images/reward-image.png';

import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import { getAccount } from '@wagmi/core';

import { useWeb3Modal } from '@web3modal/wagmi/react';
import config from 'services/config';
import HomeScreenStyles from './styles';
import reportButtonStyles from './constants';

const HomeScreen: React.FC = () => {
  const [anchorTarget, setAnchorTarget] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const { address } = getAccount(config);
  const { open } = useWeb3Modal();

  useEffect(() => {
    setAnchorTarget(document.getElementById('checkAddress'));
  }, []);

  const handleClick = () => {
    if (!anchorTarget) return;
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    MixpanelService.trackEvent(EMixpanelEvents.SCROLL_TO_CHECK_ADDRESS);
  };

  const createReport = async () => {
    if (!address) {
      MixpanelService.trackEvent(EMixpanelEvents.CONNECT_WALLET_CLICKED);
      open();
      return;
    }

    dispatch(
      showModal({
        modal: EModals.CREATE_REPORT_MODAL,
        props: {},
      }),
    );
    MixpanelService.trackEvent(EMixpanelEvents.CREATE_REPORT_CLICKED);
  };

  return (
    <HomeScreenStyles.Container>
      <HomeScreenStyles.Wrapper>
        <img src={rewardImage} alt="rewards" />
        <HomeScreenStyles.Title>
          Hunting Terrorist
          <br />
          Financing Begins!
        </HomeScreenStyles.Title>
        <HomeScreenStyles.Subtitle>
          Report cryptocurrency wallets linked to fundraising for global
          <br />
          terrorism, military aggression, and related threats. Earn
          <br />
          significant rewards for each verified report. Join Scamfari in
          <br />
          disrupting these financial flows and make a stand against
          <br />
          global terrorism. Be rewarded for your vigilance and efforts!
        </HomeScreenStyles.Subtitle>
        <HomeScreenStyles.ActionWrapper>
          <Button
            label="Create Report"
            handleClick={createReport}
            variant={EButtonActions.RED_BUTTON}
            styles={reportButtonStyles}
          />
          <Button label="Check Address" handleClick={handleClick} variant={EButtonActions.GREY_BUTTON} />
        </HomeScreenStyles.ActionWrapper>
      </HomeScreenStyles.Wrapper>
      <HomeScreenStyles.Wrapper>
        <HomeScreenStyles.MainImage>
          <img src={image} alt="main" />
        </HomeScreenStyles.MainImage>
      </HomeScreenStyles.Wrapper>
    </HomeScreenStyles.Container>
  );
};

export default HomeScreen;
