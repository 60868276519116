import APPROVED_ICON from 'assets/images/icons/approve-report-icon.svg';
import PENDING_ICON from 'assets/images/icons/clock-icon.svg';
import DECLINED_ICON from 'assets/images/icons/decline-icon.svg';
import { ReportStatus } from 'shared/enums';

const ReportStatusIcon = {
  [ReportStatus.Approved]: APPROVED_ICON,
  [ReportStatus.Pending]: PENDING_ICON,
  [ReportStatus.Declined]: DECLINED_ICON,
  [ReportStatus.Claimed]: DECLINED_ICON,
};

export default ReportStatusIcon;
