import {
  IReport,
  IRewardPerReport,
  CategoryListType,
  IRewards,
  RewardNetworksType,
} from 'shared/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Big from 'big.js';
import { EMPTY_ADDRESS } from 'shared/constants';
import { initialReportsState, initialRewardsState } from 'shared/constants/reports';
import { Address } from 'viem';
import { RootState } from '../index';

interface IReportsState {
  list: { [key: number]: IReport };
  amount: number;
  notFound: boolean;
}

export interface ReportsState {
  reports: IReportsState;
  reportsUser: IReportsState;
  rewardConfiguration: IRewardPerReport;
  rewards: IRewards;
}

const initialState: ReportsState = {
  reports: initialReportsState,
  reportsUser: initialReportsState,
  rewardConfiguration: {
    amount: Big(0),
    token: EMPTY_ADDRESS,
    dailyLimit: Big(0),
    claimedToday: Big(0),
    todayStarted: Big(0),
  },
  rewards: initialRewardsState,
};

const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setNotFoundReports: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          notFound: action.payload,
        },
      };
    },
    setReports: (
      state,
      action: PayloadAction<{ list: { [key: number]: IReport }; amount: number; notFound: boolean }>,
    ) => {
      const { list, amount, notFound } = action.payload;

      return {
        ...state,
        reports: {
          list,
          amount,
          notFound,
        },
      };
    },
    setReportsByUser: (state, action: PayloadAction<IReportsState>) => {
      const { list, amount, notFound } = action.payload;

      return {
        ...state,
        reportsUser: {
          list,
          amount,
          notFound,
        },
      };
    },
    setInitialData: (state, action: PayloadAction<{ amount: Big; token: Address }>) => {
      return {
        ...state,
        rewardConfiguration: {
          ...state.rewardConfiguration,
          amount: action.payload.amount,
          token: action.payload.token,
        },
      };
    },
    setLimitData: (state, action: PayloadAction<{ dailyLimit: Big; claimedToday: Big; todayStarted: Big }>) => {
      return {
        ...state,
        rewardConfiguration: {
          ...state.rewardConfiguration,
          dailyLimit: action.payload.dailyLimit,
          claimedToday: action.payload.claimedToday,
          todayStarted: action.payload.todayStarted,
        },
      };
    },
    updateReportById: (state, action: PayloadAction<{ id: number; report: IReport }>) => {
      const { id, report } = action.payload;
      return {
        ...state,
        reports: {
          ...state.reports,
          list: {
            ...state.reports.list,
            [id]: report,
          },
        },
      };
    },
    setCategoryReward: (
      state,
      action: PayloadAction<{
        chainId: number;
        reward: CategoryListType;
      }>,
    ) => {
      const { chainId, reward } = action.payload;
      return {
        ...state,
        rewards: {
          ...state.rewards,
          categoryRewards: {
            ...state.rewards.categoryRewards,
            [chainId]: reward,
          },
        },
      };
    },
    setRewardNetworks: (state, action: PayloadAction<RewardNetworksType>) => {
      return {
        ...state,
        rewards: {
          ...state.rewards,
          networks: action.payload,
        },
      };
    },
  },
});

// SELECTORS
export const selectReports = (state: RootState) => state.reports.reports;
export const selectReportsByUser = (state: RootState) => state.reports.reportsUser;
export const selectRewardConfiguration = (state: RootState) => state.reports.rewardConfiguration;
export const selectRewards = (state: RootState) => state.reports.rewards;

// ACTIONS
export const {
  setNotFoundReports,
  setReports,
  setReportsByUser,
  updateReportById,
  setInitialData,
  setCategoryReward,
  setRewardNetworks,
  setLimitData,
} = reports.actions;

export default reports.reducer;
