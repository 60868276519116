import ModalWrapperStyles from 'shared/components/modals/ModalWrapper/styles';
import React from 'react';
import { IModalProps } from 'shared/interfaces';

const ModalWrapper: React.FC<IModalProps> = ({
  children,
  shouldClose = true,
  closeModal,
  width,
  padding,
}) => {
  return (
    <ModalWrapperStyles.Wrapper
      onClick={shouldClose ? closeModal : (e) => e.stopPropagation()}
    >
      <ModalWrapperStyles.Modal
        padding={padding}
        onClick={(e) => e.stopPropagation()}
        width={width}
      >
        {children}
      </ModalWrapperStyles.Modal>
    </ModalWrapperStyles.Wrapper>
  );
};

export default ModalWrapper;
