import { ReactComponent as Optimism } from 'assets/images/banners/logo/optimism.svg';
import { ReactComponent as Aurora } from 'assets/images/banners/logo/aurora.svg';
import { ReactComponent as ZkSync } from 'assets/images/banners/logo/zk-sync.svg';
import { ReactComponent as Ethereum } from 'assets/images/banners/logo/ethereum.svg';
import { ReactComponent as Blast } from 'assets/images/banners/logo/blast.svg';

import { ReactComponent as OptimismIcon } from 'assets/images/icons/networks/Optimism.svg';
import { ReactComponent as AuroraIcon } from 'assets/images/icons/networks/Aurora.svg';
import { ReactComponent as ZkSyncIcon } from 'assets/images/icons/networks/ZKSync.svg';
import { ReactComponent as EthereumIcon } from 'assets/images/icons/networks/Ethereum.svg';
import { ReactComponent as BlastIcon } from 'assets/images/icons/networks/Blast.svg';

import EthereumBg from 'assets/images/banners/bg/ethereum-banner-bg.svg';
import OptimismBg from 'assets/images/banners/bg/optimism-banner-bg.svg';

import AuroraBg from 'assets/images/banners/bg/aurora-banner-bg.svg';
import BlastBg from 'assets/images/banners/bg/blast-banner-bg.svg';

import { DropdownNetwork } from 'shared/enums';
import { colors } from 'theme';

interface IData {
  [key: string]: {
    logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    bgImagePath?: string;
    bgColor: string;
  };
}

const data: IData = {
  [DropdownNetwork.Optimism]: {
    logo: Optimism,
    icon: OptimismIcon,
    bgImagePath: OptimismBg,
    bgColor: colors.bgBannerOptimism,
  },
  [DropdownNetwork.Blast]: {
    logo: Blast,
    icon: BlastIcon,
    bgImagePath: BlastBg,
    bgColor: colors.bgBannerBlast,
  },
  [DropdownNetwork.Aurora]: {
    logo: Aurora,
    icon: AuroraIcon,
    bgImagePath: AuroraBg,
    bgColor: colors.bgBannerAurora,
  },
  [DropdownNetwork.zkSync]: {
    logo: ZkSync,
    icon: ZkSyncIcon,
    bgColor: colors.bgBannerZkSync,
  },
  [DropdownNetwork.Ethereum]: {
    logo: Ethereum,
    icon: EthereumIcon,
    bgImagePath: EthereumBg,
    bgColor: colors.bgBannerEthereum,
  },
};

export default data;
