const explorers = {
  Bitcoin: 'https://blockchair.com/bitcoin/address/',
  Ethereum: 'https://etherscan.io/address/',
  Linea: 'https://lineascan.build/address/',
  Tron: 'https://tronscan.org/#/address/',
  BNBChain: 'https://bscscan.com/address/',
  Solana: 'https://solscan.io/account/',
  Polygon: 'https://polygonscan.com/address/',
  Near: 'https://nearblocks.io/address/',
  Aurora: 'https://explorer.aurora.dev/address/',
  OKTC: 'https://www.okx.com/explorer/oktc/address/',
};

export default explorers;
