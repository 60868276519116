import { getAccount, switchChain as switchNetworkWagmi } from '@wagmi/core';
import config, { networks } from 'services/config';
import ToastService from 'services/toast-service';
import userRejectedErrorHandler from './errorHandlers';

export default async function switchNetwork(pendingChainId: number) {
  try {
    const switchNetworkResult = await switchNetworkWagmi(
      config,
      { chainId: pendingChainId },
    );
    return { isSuccess: true, chain: switchNetworkResult };
  } catch (error) {
    const isUserRejected = userRejectedErrorHandler(error);

    if (isUserRejected) {
      ToastService.error({ text: "You've rejected the transaction. Please try again." });
      return { isSuccess: false, chain: null };
    }

    const { chain } = getAccount(config);
    const userNetwork = Object.values(networks).find(
      (supportedNetwork) => supportedNetwork.chainIdNumber === Number(chain?.id),
    );

    console.error(`Error: while switch network \n ${error}`, `\n\nChainId: ${pendingChainId}`);
    ToastService.error({
      text: `Your Network ${userNetwork?.name || ''} are Not Supported. Please Choose a Different Network in wallet.`,
    });
    return { isSuccess: false, chain: null };
  }
}
