import React from 'react';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import { ReactComponent as ArrowRight } from 'assets/images/icons/view-all.svg';
import { ROUTES } from 'routes/constants';
import { useHistory } from 'react-router-dom';
import styles from './styles';
import { ITab } from './interface';
import data from './data';

const GlossaryPage: React.FC = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState<ITab>(data.FinancialFraud);

  return (
    <styles.GlossaryWrapper>
      <h1>Scamfari Glossary</h1>
      <styles.Tabs>
        {Object.keys(data).map((key) => (
          <styles.Tab key={key} onClick={() => setActiveTab(data[key])} $isActive={activeTab.tab === data[key].tab}>
            {data[key].tab}
          </styles.Tab>
        ))}
      </styles.Tabs>
      <styles.TabContent>{activeTab.content}</styles.TabContent>
      <Button
        label="Check Rewards"
        handleClick={() => history.push(ROUTES.REWARDS)}
        variant={EButtonActions.YELLOW_BUTTON}
        iconRight={<ArrowRight />}
      />
    </styles.GlossaryWrapper>
  );
};

export default GlossaryPage;
