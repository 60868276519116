import { EEnvironment } from 'shared/enums';

import {
  linea, zkSyncTestnet, goerli, sepolia, mainnet, aurora, blastSepolia,
} from 'viem/chains';
import { getAccount } from '@wagmi/core';
import {
  Address, createPublicClient, http,
} from 'viem';
import scamfariLogo from 'assets/images/icons/mobile-logo.svg';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { IConfig } from './interface';
import mainnetConfig from './env-defaults/mainnet';
import testnetConfig from './env-defaults/testnet';

export const projectId = '2c00d113200749f27e7e970776874f1c';

const environments: { [key in EEnvironment]: IConfig } = {
  [EEnvironment.MAINNET]: mainnetConfig,
  [EEnvironment.TESTNET]: testnetConfig,
};

const currentEnvironment: EEnvironment = (process.env.REACT_APP_ENVIRONMENT as EEnvironment) || EEnvironment.MAINNET;

export const {
  apiUrl,
  networks,
  defaultNetwork,
  chains,
  mixpanelToken,
  infuraKey,
  explorers,
}: IConfig = {
  ...environments[currentEnvironment],
};
export const metadata = {
  name: 'Scamfari',
  description: 'Scamfari',
  url: 'https://scamfari.com/',
  icons: [scamfariLogo],
};

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

createWeb3Modal({ wagmiConfig: config, projectId, enableAnalytics: true });

export const getConfig = (selectedChainId?: number): { contract: Address } => {
  const { chain } = getAccount(config);
  const chainId = selectedChainId ?? chain?.id ?? networks[defaultNetwork]?.chainIdNumber;
  switch (Number(chainId)) {
    case sepolia.id: {
      return {
        contract: '0xA2176D31Afd1b0c15Bcf60FAF75EC3948273bDC4',
      };
    }
    case goerli.id: {
      return {
        contract: '0xA2176D31Afd1b0c15Bcf60FAF75EC3948273bDC4',
      };
    }
    case zkSyncTestnet.id: {
      return {
        contract: '0xC95F07270d2A5afF27169F8E4941cec2c9345b17',
      };
    }
    case linea.id: {
      return {
        contract: '0xA2176D31Afd1b0c15Bcf60FAF75EC3948273bDC4',
      };
    }
    case mainnet.id: {
      return {
        contract: '0xA2176D31Afd1b0c15Bcf60FAF75EC3948273bDC4',
      };
    }
    case aurora.id: {
      return {
        contract: '0xA2176D31Afd1b0c15Bcf60FAF75EC3948273bDC4',
      };
    }
    case blastSepolia.id: {
      return {
        contract: '0x41310300B431778B1B2744F5372D5d8D2F872f99',
      };
    }
    default:
      return {
        contract: '0xA2176D31Afd1b0c15Bcf60FAF75EC3948273bDC4',
      };
  }
};

export const getClient = (chainId: number) => {
  const chainLocal = chains.find((chain) => chain.id === chainId);
  const publicClient = createPublicClient({
    chain: chainLocal,
    cacheTime: 30000,
    transport: http(),
  });
  return publicClient;
};

export default config;
