import { FlattenSimpleInterpolation } from 'styled-components';
import { css } from 'styled-components/macro';

const becomePartnerStyles: FlattenSimpleInterpolation = css`
  margin: 4rem auto 0;
  ${({ theme }) => theme.media.mobile`
    margin: 3.5rem auto 0;
  `}
` as FlattenSimpleInterpolation;

export default becomePartnerStyles;
