import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 199;
  height: 100%;
  display: flex;
  overflow: auto;
  position: fixed;
  background: ${({ theme }) => theme.black};
  align-items: center;
`;

const CloseButton = styled(CloseIcon)`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.25rem;
  left: 1rem;
  cursor: pointer;
`;

export default {
  Overlay,
  CloseButton,
};
