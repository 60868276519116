import { EMPTY_STRING } from 'shared/constants';
import {
  Category, Network, countriesList, subcategoryGroups,
} from 'shared/enums';

import { isAddressMatchNetwork } from 'shared/utils';
import { z } from 'zod';
import { checkAddressByApi } from './helpers';

const tooltipForm = {
  network: 'Choose the relevant Blockchain from the dropdown list where you encountered the fraudulent address.',
  subcategory: 'Choose the relevant subcategory from the dropdown list.',
  country: 'Submit the country name.',
  address:
    'Enter the address of the suspicious or fraudulent wallet you want to report. It must match the selected blockchain.',
  url: 'Provide any relevant URL associated with the reported address, such as a website, social media profile, publication, token chart etc.',
  photoProof:
    'Upload clear screenshots or images as evidence to support your report. The screenshot should show the address you provided.',
  description:
    'Explain the details of the suspicious activity freely. Provide context to help investigators understand the situation better.',
};

const scheme = z
  .object({
    network: z.nativeEnum(Network),
    address: z.string().min(1),
    url: z.string().min(1).url(),
    proofs: z.any(),
    description: z.string().min(10),
    country: z
      .string()
      .min(1)
      .refine((val) => countriesList.map(({ name }) => name).includes(val), {
        message: 'Country should be listed',
      }),
  })
  .superRefine(async ({ proofs, address, network }, ctx) => {
    const validation = isAddressMatchNetwork(address, network);
    if (validation) {
      await checkAddressByApi(address, ctx);
    } else {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Address doesn't match ${Network[network]} validation`,
        path: ['address'],
      });
      return false;
    }
    const proofsLength = proofs.length;

    if (!proofsLength) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Add more proofs files',
        path: ['proofs'],
      });
      return false;
    }

    // if (subcategory === null) {
    //   ctx.addIssue({
    //     code: z.ZodIssueCode.custom,
    //     message: 'Subcategory is required',
    //     path: ['subcategory'],
    //   });
    //   return false;
    // }

    return true;
  });

const defaultValues = {
  network: Network.Bitcoin,
  subcategory: Category.TerroristFinance,
  url: EMPTY_STRING,
  proofs: [],
  address: EMPTY_STRING,
  description: EMPTY_STRING,
  country: EMPTY_STRING,
};

export { tooltipForm, defaultValues, scheme };
