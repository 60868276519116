import { IReport } from 'shared/interfaces';
import { Address } from 'viem';
import getCorrectIPFSLinks from '../helpers/ipfs';
import { convertNumberToEnumNetwork } from './networks';

export interface IContractReport {
  id: bigint;
  reporter: Address;
  network: number;
  category: number;
  addr: string;
  url: string;
  status: number;
  reject_reason: string;
  proof: readonly string[];
  description: string;
}
const formatReport = (report: IContractReport): IReport => {
  const {
    id, reporter, network, category, addr, url, status, reject_reason, proof, description,
  } = report;

  const viewProofs = proof.map((p: string) => getCorrectIPFSLinks({ proof: p }).proof);

  return {
    addr,
    category: Number(category),
    id: Number(id),
    network: convertNumberToEnumNetwork(Number(network)),
    proof: viewProofs,
    reject_reason,
    reporter,
    status: Number(status),
    url,
    description,
  };
};

export default formatReport;
