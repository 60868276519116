import { useEffect } from 'react';
import { EModals, selectModalState } from 'store/slices/modals';
import useAppSelector from './redux/useAppSelector';

const useDisableScrolling = () => {
  const modalState = useAppSelector(selectModalState);
  useEffect(() => {
    if (modalState.modal === EModals.EMPTY) {
      document.documentElement.style.removeProperty('overflow');
    } else {
      document.documentElement.style.overflow = 'hidden';
    }
  }, [modalState.modal]);
};

export default useDisableScrolling;
