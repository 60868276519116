import React, { PropsWithChildren } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { colors } from 'theme';
import { v4 as uuid } from 'uuid';

import styles from './styles';

interface ITooltipProps extends PropsWithChildren {
  tooltip: string;
  iconRight?: JSX.Element;
}

const Tooltip: React.FC<ITooltipProps> = ({ children, tooltip, iconRight }) => {
  const tooltipId = uuid();
  return (
    <>
      <styles.Tooltip data-tooltip-id={tooltipId}>
        {children}
        {iconRight || null}
      </styles.Tooltip>
      {tooltip && tooltip.length && (
        <ReactTooltip
          id={tooltipId}
          content={tooltip}
          place="top-start"
          style={{
            maxWidth: '16rem',
            borderRadius: '8px',
            backgroundColor: colors.grey,
            padding: '0.5rem',
            fontSize: '0.75rem',
          }}
        />
      )}
    </>
  );
};

export default Tooltip;
