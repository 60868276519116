import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/images/pagination-arrow.svg';
import { ReactComponent as DoubleArrowIcon } from 'assets/images/icons/pagination-double-arrow.svg.svg';

const Pagination = styled.div`
  display: flex;
  margin-top: 3rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
    input {
      width: 4rem;
      font-weight: 700;
      padding: 0.5rem 0;
      font-size: 0.75rem;
      text-align: center;
      background-color: transparent;
      font-family: ${({ theme }) => theme.interRegular};
      color: ${({ theme }) => theme.white};
      transition: 0.5s ease all;
      border: 1px solid ${({ theme }) => theme.opacityBorderGrey};
      border-radius: 0.5rem;

      &[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
      }

      &:focus {
        outline: none;
        border: 1px solid ${({ theme }) => theme.opacityBorderGrey};
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }

    button {
      border: none;
      line-height: 0;
      cursor: pointer;
      background-color: transparent;
      svg {
        path {
          fill: ${({ theme }) => theme.white};
        }
      }
      &:disabled {
        cursor: not-allowed;
        svg {
          path {
            opacity: 0.4;
          }
        }
      }

      &.prev {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    span {
      font-weight: 400;
      font-size: 0.75rem;
      font-family: ${({ theme }) => theme.interRegular};
      color: ${({ theme }) => theme.white};
    }
`;

export default {
  Pagination,
  ArrowIcon,
  DoubleArrowIcon,
};
