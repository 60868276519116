import React from 'react';
import Icon from 'assets/images/icons/close.svg';
import ModalsHeaderStyle from './styles';
import IModalsHeader from './interface';

const ModalsHeader: React.FC<IModalsHeader> = ({ closeModal, title }) => {
  return (
    <ModalsHeaderStyle.Wrapper>
      <ModalsHeaderStyle.Title>
        {title}
      </ModalsHeaderStyle.Title>
      <ModalsHeaderStyle.Icon
        src={Icon}
        alt="close icon"
        onClick={closeModal}
      />
    </ModalsHeaderStyle.Wrapper>
  );
};

export default ModalsHeader;
