import React from 'react';
import Title from 'shared/components/Title';
import { supportEmail } from 'shared/constants';
import convertNumberToRoman from 'shared/utils/convertToRoman';
import styles, { titleStyles } from './styles';
import termsAndConditions from './constants';

const TermsAndConditions: React.FC = () => {
  return (
    <styles.Page>
      <styles.Container>
        <Title value="Terms and conditions" styles={titleStyles} />
        <styles.MainList>
          <styles.MainListHeading>
            Intro
          </styles.MainListHeading>
          <styles.Description>
            These Terms and Conditions (&quot;Terms&quot;) govern your use of the Scamfari platform and services. Scamfari is a platform that combines a Dapp to gather user security reports and an interface for validators to review them. By accessing or using Scamfari (&quot;the Platform&quot;), you agree to abide by these Terms. Please read them carefully.
          </styles.Description>
        </styles.MainList>
        {termsAndConditions.map((element, index) => (
          <styles.MainList key={element.title}>
            <styles.MainListHeading>
              {`${convertNumberToRoman(index + 1)}. ${element.title}`}
            </styles.MainListHeading>
            {element.values.map((sublistElement, sublistElementIndex) => (
              <styles.SubList key={sublistElement.title}>
                <styles.SublistHeading>
                  {`${sublistElementIndex + 1}. ${sublistElement.title}`}
                </styles.SublistHeading>
                <styles.Description>
                  {sublistElement.description}
                  {sublistElement.subValues && sublistElement.subValues.map((subValue, subValuesIndex) => (
                    <styles.ThirdLevel>
                      <div>
                        {convertNumberToRoman(subValuesIndex + 1, true)}
                        .
                      </div>
                      {subValue}
                    </styles.ThirdLevel>
                  ))}
                </styles.Description>
              </styles.SubList>
            ))}
          </styles.MainList>
        ))}
        <styles.MainList>
          <styles.MainListHeading>
            VI. Contact Information
          </styles.MainListHeading>
          <styles.Description>
            If you have any questions or concerns about these Terms, please contact us at
            <a
              href={`mailto:${supportEmail}`}
              target="_blank"
              rel="noreferrer"
            >
              {supportEmail}
              .
            </a>
          </styles.Description>
        </styles.MainList>
        <styles.Description>
          By using the Platform, you agree to the above terms and conditions. We reserve the right to update or change these terms at any time without prior notice. Your continued use of the Platform after any changes constitutes your acceptance of the updated terms and conditions.
        </styles.Description>
      </styles.Container>
    </styles.Page>
  );
};

export default TermsAndConditions;
