import styled from 'styled-components';
import { ReactComponent as CloseImage } from 'assets/images/icons/close.svg';
import { css } from 'styled-components/macro';

const Container = styled.div`
  padding: 0.75rem 2rem;
  background: ${({ theme }) => theme.yellow};

  ${({ theme }) => css`
    ${theme.media.tablet(`
      padding: 0.75rem 1rem;
    `)}
  `}

  ${({ theme }) => css`
    ${theme.media.mobile(`
      padding: 0.75rem 0 0;
    `)}
  `}
`;

const Banner = styled.div<{ bgColor: string; bgImagePath?: string }>`
  height: 3rem;
  display: grid;
  padding: 0 0.75rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: 2fr 6fr 2fr;
  background: ${({ bgColor, bgImagePath }) => (bgImagePath ? `url(${bgImagePath}), ${bgColor}` : bgColor)};

  ${({ theme }) => css`
    ${theme.media.mobile(`
      width: 100%;
      display: flex;
      border-radius: unset;
      justify-content: space-between;
    `)}
  `}

  ${({ theme }) => css`
    ${theme.media.uhd_desktop(`
      background-size: cover;
    `)}
  `}
`;

const Text = styled.div`
  gap: 0.5rem;
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  line-height: 120%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.white};

  ${({ theme }) => css`
    ${theme.media.mobile(`
      text-align: center;
      font-size: 0.875rem;
    `)}
  `}
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseIcon = styled(CloseImage)`
  z-index: 100;
  height: auto;
  width: 1.5rem;
  cursor: pointer;
  position: relative;
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    opacity: 0.8;
  }
`;

export default {
  Container,
  Banner,
  CloseIconWrapper,
  CloseIcon,
  Text,
};
