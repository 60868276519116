import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

const StyledLoading = styled.div<{ spinnerColor: string, widthInRem: number }>`
  width: ${({ widthInRem }) => widthInRem}rem;
  height: ${({ widthInRem }) => widthInRem}rem;
  border-radius: 50%;
  border: 2px solid ${({ spinnerColor }) => spinnerColor};
  border-color: ${({ spinnerColor }) => spinnerColor} transparent ${({ spinnerColor }) => spinnerColor} transparent;
  animation: ${spinner} 1s linear infinite;
`;

const LoadingSpinner: React.FC<{
  spinnerColor: string,
  widthInRem?: number
}> = ({ spinnerColor, widthInRem = 1 }): JSX.Element => {
  return (
    <StyledLoading widthInRem={widthInRem} spinnerColor={spinnerColor} />
  );
};

export default LoadingSpinner;
