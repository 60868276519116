import React from 'react';
import NearIcon from 'assets/images/partners/Near.svg';
import BaseIcon from 'assets/images/partners/Base.svg';
import AuroraIcon from 'assets/images/partners/Aurora.svg';
import ScrollIcon from 'assets/images/partners/Scroll.svg';
import LineaIcon from 'assets/images/partners/Linea.svg';
import GoPlusIcon from 'assets/images/partners/GoPlus.svg';
import CoinDeskIcon from 'assets/images/partners/CoinDesk.svg';
import WhitebitIcon from 'assets/images/partners/Whitebit.svg';
import IncryptedIcon from 'assets/images/partners/Incrypted.svg';
import CyberPoliceIcon from 'assets/images/partners/CyberPolice.svg';

import { HAPI_EMAIL, SOCIALS } from 'shared/constants';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import PartnersStyles from './styles';
import becomeAPartnerStyles from './constants';

const projectsLogo = [
  {
    logo: LineaIcon,
    key: 1,
    social: SOCIALS.linea,
  },
  {
    logo: BaseIcon,
    key: 2,
    social: SOCIALS.base,
  },
  {
    logo: ScrollIcon,
    key: 3,
    social: SOCIALS.scroll,
  },
  {
    logo: NearIcon,
    key: 4,
    social: SOCIALS.near,
  },
  {
    logo: AuroraIcon,
    key: 5,
    social: SOCIALS.aurora,
  },
  {
    logo: GoPlusIcon,
    key: 6,
    social: SOCIALS.gopluslabs,
  },
  {
    logo: CoinDeskIcon,
    key: 7,
    social: SOCIALS.coindesk,
  },
  {
    logo: WhitebitIcon,
    key: 7,
    social: SOCIALS.whiteBit,
  },
  {
    logo: IncryptedIcon,
    key: 7,
    social: SOCIALS.incrypted,
  },
  {
    logo: CyberPoliceIcon,
    key: 7,
    social: SOCIALS.cyberPolice,
  },
];

const goToEmail = () => {
  window.location.href = HAPI_EMAIL;
  MixpanelService.trackEvent(EMixpanelEvents.BECOME_A_PARTNER_CLICKED);
};

const openPartner = (link: string) => {
  window.open(link, '_blank');
  MixpanelService.trackEvent(EMixpanelEvents.PARTNER_CLICKED, { 'Partner URL': link });
};

const Partners: React.FC = () => {
  return (
    <>
      <PartnersStyles.Title>Partners</PartnersStyles.Title>
      <PartnersStyles.Wrapper>
        {projectsLogo.map((item, index) => (
          <div key={index}>
            <PartnersStyles.Link key={item.key} onClick={() => openPartner(item.social)}>
              <PartnersStyles.ProjectLogo src={item.logo} alt="logo icon" />
            </PartnersStyles.Link>
          </div>
        ))}
      </PartnersStyles.Wrapper>
      <Button
        styles={becomeAPartnerStyles}
        label="Become a partner"
        handleClick={goToEmail}
        variant={EButtonActions.WHITE_BUTTON}
      />
    </>
  );
};

export default Partners;
