import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { EButtonActions } from 'shared/enums';
import { getBg, getColor } from './helpers';

const Button = styled.button<{ variant: EButtonActions; styles?: FlattenSimpleInterpolation; disabled: boolean }>`
  gap: 0.5rem;
  height: 3rem;
  display: flex;
  border: unset;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.75rem;
  font-weight: 700;
  align-items: center;
  border-radius: 1.5rem;
  justify-content: center;
  color: ${({ theme, variant }) => getColor(theme, variant)};
  transition: ${({ theme }) => theme.transitions.hoverTransition};
  background: ${({ theme, variant }) => getBg(theme, variant).default};

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    background: ${({ theme, variant }) => getBg(theme, variant).hover};

    &:disabled {
      background: ${({ theme, variant }) => getBg(theme, variant).default};
    }
  }

  &:active {
    background: ${({ theme, variant }) => getBg(theme, variant).pressed};
  }
  svg {
    path {
      fill: ${({ theme, variant }) => getColor(theme, variant)};
    }
  }

  ${({ styles }) => styles || null};
`;

export default {
  Button,
};
