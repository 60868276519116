import React from 'react';
import { IDeclineReasonsModal } from 'shared/interfaces';

import ModalsHeader from '../components/ModalsHeader';
import ModalWrapper from '../ModalWrapper';
import DeclineReasonStyles from './styles';

const reasons = [
  'Disparity between an address in caption and on a screenshot',
  'Disparity between an address and a chain',
  'The presence of the address in a public database',
  'Submitted proof is not substantial enough to be accepted',
  'The specified URL cannot be accessed',
  'This report has already been submitted, there is no evidence',
  'This report has already been submitted, your information is a duplicate',
  'The report does not fit this category',
];

// eslint-disable-next-line import/prefer-default-export
export const DeclineReasonsModal: React.FC<IDeclineReasonsModal> = ({ closeModal, handleClick }) => {
  return (
    <ModalWrapper closeModal={closeModal} width="24rem">
      <DeclineReasonStyles.SidePadding>
        <ModalsHeader title="Decline Reasons" closeModal={closeModal} />
        <DeclineReasonStyles.Description>
          Select one of the reasons for declining the report
        </DeclineReasonStyles.Description>
        <DeclineReasonStyles.Wrapper>
          {reasons.map((reason) => (
            <DeclineReasonStyles.Reason
              key={reason}
              onClick={() => {
                handleClick(reason);
                closeModal();
              }}
            >
              {reason}
            </DeclineReasonStyles.Reason>
          ))}
        </DeclineReasonStyles.Wrapper>
      </DeclineReasonStyles.SidePadding>
    </ModalWrapper>
  );
};
