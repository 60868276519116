import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { css } from 'styled-components/macro';

const List = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-right: 4rem;
  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
    align-items: start; 
  `}
`;

const ListItem = styled(NavLink)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.lightGrey};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &::after {
    content: '';
    width: 0;
    left: 50%;
    opacity: 0;
    height: 0.125rem;
    position: absolute;
    border-radius: 0.125rem;
    top: calc(100% + 0.25rem);
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.lightGrey};
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }

  &.active {
    color: ${({ theme }) => theme.white};

    &::after {
      width: 50%;
      opacity: 1;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.white};

    &::after {
      width: 50%;
      opacity: 1;
    }
  }

  ${({ theme }) => css`
    ${theme.media.tablet(`
      gap: 3rem;
      opacity: 1;
      width: auto;
      text-align: left;
      font-size: 1.5rem;
      padding: 0 0 0 11.5rem;
      color: ${theme.lightGrey};
      
      &:hover {
        color: ${theme.white};
      }

      &.active {
        color: ${theme.white};
        
        &::after {
          opacity: 1;
        }
      }
    `)}

    ${theme.media.tablet('padding: 0 0 0 3.5rem;')}
  `}
`;

export default {
  List,
  ListItem,
};
