import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const Loader = styled.div<{ widthInRem: number}>`
    display: grid;
    place-items: center;
    width: ${({ widthInRem }) => widthInRem}rem;
    height: ${({ widthInRem }) => widthInRem}rem;
    border-radius: 50%;
    background: conic-gradient(
      from 180deg at 50% 50%,
      rgba(82, 0, 255, 0) 0deg,
      ${({ theme }) => theme.white} 360deg
    );
    animation: ${spin} 2s infinite linear;
  ::before {
    content: "";
    border-radius: 50%;
    width: 90%;
    height: 90%;
    background-color: ${({ theme }) => theme.bgBlack};
  }
`;

export default {
  Loader,
};
