import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/dropdown.svg';

const ButtonWrapper = styled.div`
  position: relative;
`;

const HandlerButton = styled.div`
  gap: 0.5rem;
  height: 3rem;
  display: flex;
  cursor: pointer;
  padding: 0.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.opacityWhite};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  svg {
    transition: ${({ theme }) => theme.transitions.hoverTransition};
  }

  &:hover {
    background: ${({ theme }) => theme.opacityWhiteHover};

    svg {
      transform: translateY(0.125rem);
    }
  }

  ${({ theme }) => theme.media.tablet`
    gap: 0.25rem;
    height: 2rem;
    padding: 0.5rem;
  `}
`;

const Arrow = styled(ArrowIcon)`
  ${({ theme }) => theme.media.tablet`
    width: 1rem;
    height: auto;
  `}
`;

const NetworkIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;

  ${({ theme }) => theme.media.tablet`
    width: 1rem;
    height: 1rem;
  `}
`;

export default {
  HandlerButton,
  Arrow,
  ButtonWrapper,
  NetworkIcon,
};
