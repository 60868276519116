import { DropdownNetwork } from 'shared/enums';
import { IConfig } from 'services/config/interface';
import {
  aurora, blastSepolia, linea, mainnet,
} from 'viem/chains';

import networks from './constants';
import explorers from './explorers';

export default {
  apiUrl: 'https://scamfari.com/data/',
  infuraKey: process.env.REACT_APP_INFURA_KEY,
  networks,
  chains: [linea, mainnet, aurora, blastSepolia],
  defaultNetwork: DropdownNetwork.Linea,
  mixpanelToken: '1139443c1cd0fbad38b675c411acfa07',
  explorers,
} as IConfig;
