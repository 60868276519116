import React from 'react';
import { formatRank, sliceString } from 'shared/utils';
import HunterStyles from '../HuntersList/styles';
import UserAvatar from '../UserAvatar';

interface IUserModel {
  nickname: string
  rank: number
  score: number
}

const UsualUserRow: React.FC<IUserModel> = (
  {
    nickname,
    rank,
    score,
  },
) => {
  return (
    <HunterStyles.Row>
      <HunterStyles.RowSide>
        <HunterStyles.ReportValue>
          {`#${formatRank(rank)}`}
        </HunterStyles.ReportValue>
        <HunterStyles.UserWrapper>
          <UserAvatar nickname={nickname} />
          <HunterStyles.NickName>{sliceString(nickname)}</HunterStyles.NickName>
        </HunterStyles.UserWrapper>
      </HunterStyles.RowSide>
      <HunterStyles.ReportValue>{score}</HunterStyles.ReportValue>
    </HunterStyles.Row>
  );
};

export default UsualUserRow;
