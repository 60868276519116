import styled from 'styled-components';

const YellowBackground = styled.div`
  padding: 3rem 0;
  background: ${({ theme }) => theme.black};

  ${({ theme }) => theme.media.tablet`padding: 2rem 0;`}
  ${({ theme }) => theme.media.mobile`padding: 1.5rem 0;`}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15.38rem;
  ${({ theme }) => theme.media.tablet`
   flex-direction: column;
   gap: 2rem;
   `}
  ${({ theme }) => theme.media.mobile`
   gap: 1.5rem;
   padding: 0 1rem;
  `}
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const Address = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.lightFontGrey};
  margin-left: 1rem;
  ${({ theme }) => theme.media.mobile`
    font-size: 0.75rem;
  `}
`;

const Column = styled.div`
  padding: 0.5rem 0;
  & p {
    &:first-child {
      font-size: 1rem;
      font-weight: 500;
      color: ${({ theme }) => theme.lightFontGrey};
      ${({ theme }) => theme.media.mobile`
        font-size: 0.75rem;
      `}
    }
    &:last-child {
      display: flex;
      color: ${({ theme }) => theme.white};
      font-size: 1.5rem;
      font-weight: 600;
      margin-top: 0.5rem;
      align-items: center;
      ${({ theme }) => theme.media.mobile`
        margin-top: 0.25rem;
        font-size: 1rem;
      `}
    }
  }
  &:first-child {
    padding-right: 1.5rem;
    border-right: 1px solid ${({ theme }) => theme.middleGreyBorder};
    margin-right: 1.5rem;
  }
  ${({ theme }) => theme.media.mobile`
    &:nth-of-type(2) {
      & p {
        &:first-child {
          text-align: right;
        }
      }
    }
  `}
`;

const TokenSymbol = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  ${({ theme }) => theme.media.mobile`
    width: 1.2rem;
    height: auto;
  `}
`;

const UserAvatarAddress = styled.div`
  display: flex;
  align-items: center;

  div {
    &:first-child {
      margin: 0;
    }
  }

  ${({ theme }) => theme.media.tablet`
    width: 100%;
    padding: 0 6.3125rem;
  `}

  ${({ theme }) => theme.media.mobile`padding: 0;`}
`;

export default {
  YellowBackground,
  Wrapper,
  FlexRow,
  Address,
  Column,
  TokenSymbol,
  UserAvatarAddress,
};
