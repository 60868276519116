import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const SubmenuWrapper = styled.div`
  top: 100%;
  gap: 0.5rem;
  display: flex;
  left: -0.75rem;
  padding: 0.5rem;
  position: absolute;
  border-radius: 1rem;
  min-width: 11.25rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.veryLightGrey};

  ${({ theme }) => css`
    ${theme.media.tablet(`
        gap: 1.5rem;
        position: static;
        padding: 3rem 0 0 5rem;
        background-color: unset;
    `)}
  `}
`;

const NavItem = styled(NavLink)`
  font-size: 1rem;
  padding: 0.31rem;
  font-weight: 600;
  line-height: 140%;
  text-decoration: none;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.white};
  transition: ${({ theme }) => theme.transitions.hoverTransition};

  &:hover {
    background-color: ${({ theme }) => theme.backgroundBlack};
  }

  ${({ theme }) => css`
    ${theme.media.tablet(`
      padding: 0;
      width: auto;
      border-radius: 0;
      text-align: left;
      font-size: 1.5rem;
      background-color: unset;
      color: ${theme.lightGrey};
      
      &:hover {
        color: ${theme.white};
        background-color: unset;
      }

      &.active {
        color: ${theme.white};
      }
    `)}
  `}
`;

export default {
  SubmenuWrapper,
  NavItem,
};
