import styled, { keyframes } from 'styled-components';
import { MainCategories } from 'shared/enums';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-bottom.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';

const Wrapper = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 2rem 0.75rem 0 0.75rem;
`;

const Category = styled.div<{ $mainCategory: MainCategories }>`
  background: ${({ theme, $mainCategory }) => {
    switch ($mainCategory) {
      case MainCategories.FinancialFraud:
        return theme.lightViolet;
      case MainCategories.Crime:
        return theme.lightBlue;
      case MainCategories.Scam:
        return theme.lightAqua;
      default:
        return theme.lightGreen;
    }
  }};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  padding: 0.25rem 0.75rem;
  color: ${({ theme }) => theme.white};
`;

const AddAddressButton = styled.button`
  margin: 0.5rem auto 0;
  display: flex;
  height: 3rem;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  background: transparent;
  font-size: 1rem;
  color: ${({ theme }) => theme.lightGrey};
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  & button {
    &:first-child {
      margin-right: 1rem;
    }
  }
`;

const FormAddressGroupContainer = styled.div<{ isScam: boolean }>`
  border-radius: 1.25rem;
  padding: ${({ isScam }) => (isScam ? '0.75rem' : '0')};
  background: ${({ theme, isScam }) => (isScam ? theme.grey : 'unset')};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Address = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 0.875rem;
  font-weight: 600;
`;

const FieldWrapper = styled.div`
  position: relative;
`;

const SubcategoryFieldWrapper = styled(FieldWrapper)`
  width: 100%;
`;

const NetworkFieldWrapper = styled(FieldWrapper)`
  width: 10rem;
`;

const FieldItem = styled.div<{ $isOpened: boolean; $isSelected: boolean }>`
  display: flex;
  font-size: 1rem;
  cursor: pointer;
  height: 2.75rem;
  padding: 0 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: space-between;
  font-weight: ${({ $isSelected }) => ($isSelected ? 600 : 400)};
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.white : theme.lightGrey)};
  border: 1px solid ${({ theme, $isOpened }) => ($isOpened ? theme.yellow : theme.lightGreyBorder)};
  ${({ $isOpened }) => $isOpened && 'box-shadow: 0 0 0 2px rgba(253, 238, 45, 0.4);'}
`;

const Arrow = styled(ArrowIcon)`
  width: auto;
  path {
    fill: ${({ theme }) => theme.white};
  }
`;

const Close = styled(CloseIcon)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.errorRed};
  }
`;

const InputContainer = styled.div`
  height: auto;
`;

const Label = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${({ theme }) => theme.lightGrey};
  display: flex;
  width: fit-content;
`;

const Input = styled.input<{ error: boolean }>`
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme, error }) => (!error ? theme.lightGreyBorder : theme.declinedRed)};
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  padding: 0 0.5rem;
  height: 2.75rem;
  background: transparent;
  outline: none;
  transition: 0.5s border;
  &:focus {
    border: 1px solid ${({ theme }) => theme.yellow};
    box-shadow: 0 0 0 2px rgba(253, 238, 45, 0.4);
    transition: 0.5s border;
  }
`;

const Error = styled.span`
  color: ${({ theme }) => theme.errorRed};
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: 0.5rem;
  display: block;
`;

const bounce = keyframes`
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
`;

const Approve = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  position: relative;
`;

const AcceptInput = styled.label`
  position: relative;
  input,
  svg {
    width: 1rem;
    height: 1rem;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    outline: none;
    background: transparent;
    border: none;
    margin: 0.063rem 0 0 0.25rem;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: box-shadow 0.3s;
    box-shadow: inset 0 0 0 0.063rem ${({ theme }) => theme.regularGrey};
    :hover {
      box-shadow: inset 0 0 0 0.125rem ${({ theme }) => theme.regularGrey};
    }
    :checked {
      box-shadow: inset 0 0 0 0.688rem ${({ theme }) => theme.acceptInputBg};
      & + svg {
        animation: ${bounce} 0.4s linear forwards 0.2s;
      }
    }
    :disabled {
      box-shadow: inset 0 0 0 0.063rem ${({ theme }) => theme.regularGrey};
      cursor: not-allowed;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 0.125rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: ${({ theme }) => theme.white};
    position: absolute;
    top: 1px;
    left: 4px;
    transform: scale(0) translateZ(0);
  }
`;

const ApproveDescription = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 140%;
  padding-left: 0.75rem;
  color: ${({ theme }) => theme.lightGrey};
  span {
    color: ${({ theme }) => theme.white};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default {
  Wrapper,
  FormAddressGroupContainer,
  Address,
  SubcategoryFieldWrapper,
  FieldItem,
  NetworkFieldWrapper,
  Arrow,
  Close,
  Header,
  Container,
  Category,
  AddAddressButton,
  ButtonWrapper,
  InputContainer,
  Label,
  Input,
  Error,
  Approve,
  AcceptInput,
  ApproveDescription,
};
