import React, { useEffect, useState } from 'react';

import Title from 'shared/components/Title';
import Button from 'shared/components/Buttons';
import Loading from 'shared/components/Buttons/LoadingSpinner';

import { EButtonActions } from 'shared/enums';
import { INetwork } from 'services/config/interface';
import { selectRewards } from 'store/slices/reports';
import getSupportedNetwork from 'shared/utils/getSupportedNetwork';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import loadCategoryRewards from 'store/actions/loadCategoryRewards';
import useAppSelector from 'shared/hooks/redux/useAppSelector';

import getButtonTextColor from 'shared/components/Buttons/utils';
import { selectCurrentChainId } from 'store/slices/networks';
import styles, { titleStyles } from './styles';
import CategoryList from './components/CategoryList';

const Rewards: React.FC = () => {
  const dispatch = useAppDispatch();
  const { networks, categoryRewards } = useAppSelector(selectRewards);
  const currentChainId = useAppSelector(selectCurrentChainId);
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);

  useEffect(() => {
    const { network } = getSupportedNetwork(currentChainId);
    if (!network) return;
    setSelectedNetwork(network);
    dispatch(loadCategoryRewards(network?.chainIdNumber));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleClick = async (network: INetwork) => {
    setSelectedNetwork(network);
    if (categoryRewards?.[network.chainIdNumber]) return;
    dispatch(loadCategoryRewards(network.chainIdNumber));
  };

  return (
    <styles.Page>
      <styles.Container>
        <styles.Header>
          <Title value="Rewards" styles={titleStyles} />
          <p>
            Contribute to a safer blockchain environment across multiple networks by reporting scam addresses. For your
            valuable input, we offer SCM tokens as a symbol of gratitude. Explore the sections below to see how rewards
            are allocated for each blockchain category and learn how your diligence can turn into tangible benefits.
          </p>
        </styles.Header>
        <styles.WrapperButtons>
          {networks.map((network) => (
            <Button
              key={network.name}
              label={network.name}
              handleClick={() => handleClick(network)}
              variant={EButtonActions.GREY_BUTTON}
              disabled={!network.active || network.disabled}
              styles={styles.StyledButton(selectedNetwork?.name === network.name)}
              iconLeft={
                network.loading ? (
                  <Loading widthInRem={1.5} spinnerColor={getButtonTextColor(EButtonActions.GREY_BUTTON)} />
                ) : (
                  <img src={network.iconPath} alt="network icon" />
                )
              }
            />
          ))}
        </styles.WrapperButtons>
        {selectedNetwork && <CategoryList network={selectedNetwork} />}
      </styles.Container>
    </styles.Page>
  );
};

export default Rewards;
