import { css } from 'styled-components/macro';
import { FlattenSimpleInterpolation } from 'styled-components';
import { sizes } from '../shared/constants';
import transitions from './transitions';

type Media = {
  [key in keyof typeof sizes]: (styles: TemplateStringsArray | string) => FlattenSimpleInterpolation;
};

const media: Media = {
  mobile: (styles) => css`
    @media (max-width: ${sizes.mobile}) {
      ${styles}
    }
  `,
  tablet: (styles) => css`
    @media (max-width: ${sizes.tablet}) {
      ${styles}
    }
  `,
  desktop: (styles) => css`
    @media (max-width: ${sizes.desktop}) {
      ${styles}
    }
  `,
  fhd_desktop: (styles) => css`
    @media (max-width: ${sizes.fhd_desktop}) {
      ${styles}
    }
  `,
  uhd_desktop: (styles) => css`
    @media (max-width: ${sizes.uhd_desktop}) {
      ${styles}
    }
  `,
  uhd_huge_desktop: (styles) => css`
    @media (max-width: ${sizes.uhd_huge_desktop}) {
      ${styles}
    }
  `,
};

export type ThemeColors = typeof colors;
export type Fonts = typeof fonts;

export const fonts = {
  interBlack: 'Inter-Black',
  interBold: 'Inter-Bold',
  interSemiBold: 'Inter-SemiBold',
  interRegular: 'Inter-Regular',
  interLight: 'Inter-Light',
};

export const colors = {
  bgBlack: '#131313',
  yellow: '#FDEE2D',
  yellowHover: '#FBDE08',
  yellowPressed: '#F9CF01',
  black: '#090E15',
  blackHover: '#1D2229',
  blackPressed: '#31363D',
  white: '#FFFFFF',
  grey: '#31343C',
  opacityYellow: 'rgba(9, 14, 21, 0.08)',
  opacityYellowHover: 'rgba(9, 14, 21, 0.16)',
  lightGrey: '#BCBCC6',
  opacityBlackBorder: 'rgba(0, 0, 0, 0.1)',
  lightFontGrey: '#6C6C76',
  regularGrey: '#B8B8BF',
  borderBlack: '#21252C',
  backgroundBlack: 'rgba(255, 255, 255, 0.1)',
  greyButton: 'rgba(188, 188, 198, 0.08)',
  greyButtonHover: 'rgba(188, 188, 198, 0.16);',
  greyButtonPressed: 'rgba(188, 188, 198, 0.24);',
  pendingYellow: '#F8A426',
  approvedGreen: '#66BF50',
  approvedGreenHover: '#52AB3C',
  approvedGreenPressed: '#3E9728',
  declinedRed: '#EF4F45',
  declinedRedHover: '#DB3B31',
  declinedRedPressed: '#C7271D',
  opacityBorderGrey: 'rgba(184, 184, 191, 0.4)',
  blue: '#5BACF8',
  modalOverlayBlack: 'rgba(188, 188, 198, 0.40)',
  lightGreyBorder: 'rgba(188, 188, 198, 0.6)',
  violet: 'rgba(179, 105, 191, 0.40)',
  darkViolet: 'rgba(78, 89, 188, 0.40)',
  darkBlue: 'rgba(41, 143, 166, 0.40)',
  darkGreen: 'rgba(76, 175, 98, 0.5)',
  lightViolet: '#B369BF',
  lightBlue: '#4E59BC',
  lightAqua: '#298FA6',
  lightGreen: '#4CAF62',
  red: '#FF5252',
  errorRed: '#EF4F45',
  veryLightGrey: '#454850',
  middleGreyBorder: 'rgba(0, 0, 0, 0.16)',
  middleGrey: '#C0C7D1',
  yellowSoonTitle: (opacity: number) => `rgba(248, 164, 38, ${opacity})`,

  lightWhite: '#E5E5E5',
  opacityWhite: 'rgba(255, 255, 255, 0.1)',
  opacityWhiteHover: 'rgba(255, 255, 255, 0.05)',
  opacityBlack: '#6C6C76',
  tooltip: '#2D3138',
  acceptInputBg: '#8E939A',
  boxShadowInput: 'rgba(255, 89, 36, 0.1)',

  bgBannerOptimism: 'linear-gradient(270deg, rgba(69, 31, 31, 1) 2.45%, rgba(158, 24, 5, 1) 81.77%), #000C0D',
  bgBannerBlast: 'linear-gradient(270.32deg, rgba(17, 20, 12, 0.8) 16.47%, rgba(57, 68, 35, 0.8) 72.49%)',
  bgBannerAurora: 'linear-gradient(180deg, rgba(52, 18, 61, 1) 0%, rgba(121, 46, 151, 1) 92.19%)',
  bgBannerZkSync: 'linear-gradient(94deg, rgba(22, 44, 69, 1) 4.79%, rgba(48, 51, 140, 1) 91.34%)',
  bgBannerArbitrum: 'radial-gradient(98.77% 43.37% at 50% 50%, rgba(24, 32, 57, 1) 0%, rgba(18, 24, 45, 0.00) 100%)',
  bgBannerBase: 'radial-gradient(98.77% 43.37% at 50% 50%, rgba(24, 32, 57, 1) 0%, rgba(18, 24, 45, 0.00) 100%)',
  bgBannerEthereum: 'linear-gradient(90deg, rgba(81, 35, 110, 1) 12.57%, rgba(132, 73, 170, 1) 96.45%)',
};

export const styledScrollbar = css`
  scrollbar-width: 5px;
  scrollbar-color: ${colors.middleGrey} transparent;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.middleGrey};
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.6;
  }
`;

const theme = {
  ...colors,
  ...fonts,
  media,
  transitions,
};

export default theme;
