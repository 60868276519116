import { getAccount } from '@wagmi/core';
import mixpanel from 'mixpanel-browser';
import config from 'services/config';
import { environment } from 'shared/constants';
import { EEnvironment } from 'shared/enums';

export type TAdditionalArguments = { [key: string]: string };

export enum EMixpanelEvents {
  // Check address
  CHECK_ADDRESS = 'Check Address',
  CHECK_ANOTHER_ADDRESS = 'Check Another Address',
  SCROLL_TO_CHECK_ADDRESS = 'Scroll to Check Address',
  CHECK_ADDRESS_CREATE_REPORT = 'Check Address - Create Report',
  // Header
  HEADER_LOGO_CLICKED = 'Header logo clicked',
  // Socials
  SOCIAL_CLICKED = 'Social clicked',
  // Partners
  PARTNER_CLICKED = 'Partner clicked',
  BECOME_A_PARTNER_CLICKED = 'Become a partner clicked',
  // Docs & HAPI
  DOCS_CLICKED = 'Docs clicked',
  HAPI_CLICKED = 'HAPI clicked',
  TERMS_AND_CONDITIONS_CLICKED = 'Terms and conditions clicked',
  // Create report
  CREATE_REPORT_CLICKED = 'Create report - start',
  CREATE_REPORT_MODAL_FIRST_STEP = 'Create report - next',
  BACK_TO_FIRST_STEP_REPORT_MODAL = 'Create report - back',
  CLOSE_REPORT_MODAL = 'Create report - close',
  CREATE_REPORT_MODAL_SECOND_STEP = 'Create report (2/2) clicked',
  REPORT_CREATED = 'Report created',
  // Connect wallet
  CONNECT_WALLET_CLICKED = 'Connect wallet - start',
  USER_CONNECTED_WALLET = 'Connect wallet - finish',
  CHANGE_NETWORK = 'Change network',
  ADD_NETWORK = 'Add network',
  // Claim
  OPEN_CLAIM_MODAL_CLICKED = 'Claim - open',
  CLOSE_CLAIM_MODAL_CLICKED = 'Claim - close',
  CLAIM_CLICKED = 'Claim clicked',
  USER_CLAIMED_TOKENS = 'User claimed tokens',
  // Reports
  CHANGE_REPORTS_FILTER = 'Change reports filter',
  CHANGE_REPORTS_PAGE = 'Change reports page',
  // FAQ
  OPEN_FAQ = 'Open FAQ',
  // Report
  REPORT = 'Report clicked',
}

export class MixpanelService {
  public static getUserCurrentState() {
    const { address, chain } = getAccount(config);

    if (!chain) return;
    const additionalArguments: { address?: string; network: string } = { network: chain.name };

    if (address) additionalArguments.address = address;

    return additionalArguments;
  }

  public static trackEvent(eventName: string | EMixpanelEvents, additionalArguments?: TAdditionalArguments) {
    if (environment === EEnvironment.MAINNET) {
      mixpanel.track(eventName, { ...additionalArguments, ...this.getUserCurrentState() });
    }
  }

  public static userIdentify(address: string) {
    if (environment === EEnvironment.MAINNET) {
      mixpanel.identify(address);
    }
  }
}
