export const scamfariABI = [
  {
    anonymous: false,
    inputs: [
      {
        components: [
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'reward_amount',
            type: 'uint256',
          },
        ],
        indexed: false,
        internalType: 'struct Scamfari.CategoryReward[]',
        name: 'categories_reward_',
        type: 'tuple[]',
      },
    ],
    name: 'CategoriesRewardsUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'reward_token',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'reward_amount',
        type: 'uint256',
      },
    ],
    name: 'ConfigurationUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'daily_claim_limit_',
        type: 'uint256',
      },
    ],
    name: 'DailyClaimLimitUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'investigator',
        type: 'address',
      },
    ],
    name: 'InvestigatorAdded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'investigator',
        type: 'address',
      },
    ],
    name: 'InvestigatorRemoved',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'ReportAccepted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'reporter',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'addr',
        type: 'string',
      },
    ],
    name: 'ReportCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'uint256',
        name: 'id',
        type: 'uint256',
      },
    ],
    name: 'ReportRejected',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'reporter',
        type: 'address',
      },
    ],
    name: 'ReporterBlocked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'reporter',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'username',
        type: 'string',
      },
    ],
    name: 'ReporterProfileUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'reporter',
        type: 'address',
      },
    ],
    name: 'ReporterUnblocked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'reporter',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'RewardClaimed',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id_',
        type: 'uint256',
      },
    ],
    name: 'accept',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'accepted_reports_count',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr_',
        type: 'address',
      },
    ],
    name: 'addInvestigator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr_',
        type: 'address',
      },
    ],
    name: 'blockReporter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'enum Scamfari.Category',
        name: '',
        type: 'uint8',
      },
    ],
    name: 'category_rewards',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'addr_',
        type: 'string',
      },
    ],
    name: 'checkAddress',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount_',
        type: 'uint256',
      },
    ],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'configuration',
    outputs: [
      {
        internalType: 'address',
        name: 'reward_token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'reward_amount',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'enum Scamfari.Network',
        name: 'network_',
        type: 'uint8',
      },
      {
        internalType: 'enum Scamfari.Category',
        name: 'category_',
        type: 'uint8',
      },
      {
        internalType: 'string',
        name: 'addr_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'url_',
        type: 'string',
      },
      {
        internalType: 'string[]',
        name: 'proof_',
        type: 'string[]',
      },
      {
        internalType: 'string',
        name: 'description_',
        type: 'string',
      },
      {
        internalType: 'string',
        name: 'country_',
        type: 'string',
      },
    ],
    name: 'createReport',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getCategoryRewards',
    outputs: [
      {
        components: [
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'reward_amount',
            type: 'uint256',
          },
        ],
        internalType: 'struct Scamfari.CategoryReward[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getLastReports',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'reporter',
            type: 'address',
          },
          {
            internalType: 'enum Scamfari.Network',
            name: 'network',
            type: 'uint8',
          },
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'addr',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'url',
            type: 'string',
          },
          {
            internalType: 'enum Scamfari.ReportStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'reject_reason',
            type: 'string',
          },
          {
            internalType: 'string[]',
            name: 'proof',
            type: 'string[]',
          },
          {
            internalType: 'string',
            name: 'description',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'country',
            type: 'string',
          },
        ],
        internalType: 'struct Scamfari.Report[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMyDailyLimit',
    outputs: [
      {
        internalType: 'uint256',
        name: 'claimed_today',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'today_started',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'daily_limit',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getMyStatus',
    outputs: [
      {
        internalType: 'enum Scamfari.ReporterStatus',
        name: 'status_',
        type: 'uint8',
      },
      {
        internalType: 'uint256',
        name: 'reward_',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'report_count_',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'is_investigator_',
        type: 'bool',
      },
      {
        internalType: 'string',
        name: 'username_',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id_',
        type: 'uint256',
      },
    ],
    name: 'getReport',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'reporter',
            type: 'address',
          },
          {
            internalType: 'enum Scamfari.Network',
            name: 'network',
            type: 'uint8',
          },
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'addr',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'url',
            type: 'string',
          },
          {
            internalType: 'enum Scamfari.ReportStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'reject_reason',
            type: 'string',
          },
          {
            internalType: 'string[]',
            name: 'proof',
            type: 'string[]',
          },
          {
            internalType: 'string',
            name: 'description',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'country',
            type: 'string',
          },
        ],
        internalType: 'struct Scamfari.Report',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'skip',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'take',
        type: 'uint256',
      },
    ],
    name: 'getReports',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'reporter',
            type: 'address',
          },
          {
            internalType: 'enum Scamfari.Network',
            name: 'network',
            type: 'uint8',
          },
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'addr',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'url',
            type: 'string',
          },
          {
            internalType: 'enum Scamfari.ReportStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'reject_reason',
            type: 'string',
          },
          {
            internalType: 'string[]',
            name: 'proof',
            type: 'string[]',
          },
          {
            internalType: 'string',
            name: 'description',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'country',
            type: 'string',
          },
        ],
        internalType: 'struct Scamfari.Report[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'skip',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'take',
        type: 'uint256',
      },
    ],
    name: 'getReportsByReporter',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'id',
            type: 'uint256',
          },
          {
            internalType: 'address',
            name: 'reporter',
            type: 'address',
          },
          {
            internalType: 'enum Scamfari.Network',
            name: 'network',
            type: 'uint8',
          },
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'addr',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'url',
            type: 'string',
          },
          {
            internalType: 'enum Scamfari.ReportStatus',
            name: 'status',
            type: 'uint8',
          },
          {
            internalType: 'string',
            name: 'reject_reason',
            type: 'string',
          },
          {
            internalType: 'string[]',
            name: 'proof',
            type: 'string[]',
          },
          {
            internalType: 'string',
            name: 'description',
            type: 'string',
          },
          {
            internalType: 'string',
            name: 'country',
            type: 'string',
          },
        ],
        internalType: 'struct Scamfari.Report[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTopReporters',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'addr',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'username',
            type: 'string',
          },
          {
            internalType: 'uint256',
            name: 'score',
            type: 'uint256',
          },
        ],
        internalType: 'struct Scamfari.TopReporterRecord[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'id_',
        type: 'uint256',
      },
      {
        internalType: 'string',
        name: 'reason',
        type: 'string',
      },
    ],
    name: 'reject',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr_',
        type: 'address',
      },
    ],
    name: 'removeInvestigator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'report_count',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'reporters_count',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: 'enum Scamfari.Category',
            name: 'category',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'reward_amount',
            type: 'uint256',
          },
        ],
        internalType: 'struct Scamfari.CategoryReward[]',
        name: 'categories_reward_',
        type: 'tuple[]',
      },
    ],
    name: 'setCategoriesRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'enum Scamfari.Category',
        name: 'category_',
        type: 'uint8',
      },
      {
        internalType: 'uint256',
        name: 'reward_amount_',
        type: 'uint256',
      },
    ],
    name: 'setCategoryReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'daily_claim_limit',
        type: 'uint256',
      },
    ],
    name: 'setDailyClaimLimit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'string',
        name: 'username_',
        type: 'string',
      },
    ],
    name: 'setReporterProfile',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'addr_',
        type: 'address',
      },
    ],
    name: 'unblockReporter',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'reward_token_',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'reward_amount_',
        type: 'uint256',
      },
    ],
    name: 'updateConfiguration',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

export default scamfariABI;
