import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const Title = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  ${({ theme }) => theme.media.mobile`
    font-size: 1.25rem;
  `}
`;

const Description = styled.p`
  color: ${({ theme }) => theme.lightGrey};
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%; 
`;

const List = styled.div<{gapInRem?: number}>`
  display: flex;
  flex-direction: column;
  gap: ${({ gapInRem }) => (gapInRem || 1.5)}rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    font-size: 1rem;
    font-weight: 600;
    line-height: 120%;
  }
`;

export default {
  LoaderContainer,
  Container,
  Title,
  Description,
  List,
  Row,
};
