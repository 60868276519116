import RussiaFlag from 'assets/images/flags/russia-flag.png';
import SyriaFlag from 'assets/images/flags/syria-flag.png';
import IranFlag from 'assets/images/flags/iran-flag.png';
import CubaFlag from 'assets/images/flags/cuba-flag.png';
import NorthKoreaFlag from 'assets/images/flags/north-korea-flag.png';
// INFO: on any way don't change the order of that enum
export enum Network {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Linea = 'Linea',
  Tron = 'Tron',
  BNBChain = 'BNBChain',
  Solana = 'Solana',
  Polygon = 'Polygon',
  NEAR = 'NEAR',
  Aurora = 'Aurora',
  OKTC = 'OKTC',
}

export enum DropdownNetwork {
  Ethereum = 'Ethereum',
  Optimism = 'Optimism',
  Linea = 'Linea',
  BNBChain = 'BNBChain',
  Polygon = 'Polygon',
  Scroll = 'Scroll',
  Aurora = 'Aurora',
  zkSync = 'zkSync',
  Blast = 'Blast'
}

export enum Category {
  SocialMediaScammer,
  FraudulentWebsite,
  ScamProject,
  TerroristFinancing,
  FinancialFraud,
  RugPull,
  PumpAndDumpSchemes,
  PonziSchemes,
  Honeypots,
  MoneyLaundering,
  TradeBasedLaundering,
  MixingServices,
  Crime,
  Counterfeiting,
  OrganizedCrime,
  GangOperations,
  MafiaActivities,
  CyberCrime,
  APTGroup,
  PhishingAttacks,
  HackingTool,
  Hackers,
  DataBreaches,
  Drug,
  Trafficking,
  Distribution,
  Manufacturing,
  WeaponsTrade,
  HumanTrafficking,
  SocialScam,
  Blackmail,
  InvestmentScam,
  LotteryScam,
  DataTheft,
  NFTScam,
  IllegalActivity,
  TerroristFinance,
  Sanction,
  DarknetMarkets,
  WarDonations,
}

export enum ReportStatus {
  Pending,
  Approved,
  Declined,
  Claimed,
}

export enum ReporterStatus {
  None,
  Blocked,
  Active,
}

export enum MainCategories {
  FinancialFraud,
  Crime,
  Scam,
  IllegalActivity,
}

export const subcategoryGroups = {
  [MainCategories.FinancialFraud]: [{ name: 'RugPull' }, { name: 'MoneyLaundering' }],
  [MainCategories.Crime]: [
    { name: 'Counterfeiting' },
    { name: 'OrganizedCrime' },
    { name: 'CyberCrime' },
    { name: 'DataBreaches' },
    { name: 'Drug' },
    { name: 'WeaponsTrade' },
    { name: 'HumanTrafficking' },
  ],
  [MainCategories.Scam]: [{ name: 'SocialScam' }, { name: 'FraudulentWebsite' }, { name: 'NFTScam' }],
  [MainCategories.IllegalActivity]: [
    { name: 'TerroristFinance' },
    { name: 'Sanction' },
    { name: 'DarknetMarkets' },
    { name: 'WarDonations' },
  ],
};

export const countriesList = [
  { name: 'Russia', iconPath: RussiaFlag },
  { name: 'Syria', iconPath: SyriaFlag },
  { name: 'Iran', iconPath: IranFlag },
  { name: 'Cuba', iconPath: CubaFlag },
  { name: 'North Korea', iconPath: NorthKoreaFlag },
];
