import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  padding: 0 2rem;
  height: 5.25rem;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.black};

  ${({ theme }) => theme.media.tablet`
    height: 4rem;
    padding: 0 1rem;

    button {
      height: 2rem;
      font-size: 0.8125rem;
      padding: 0.75rem 0.5rem;
    }
  `}
`;

const MenuIcon = styled.img`
  margin-right: 0.875rem;
  cursor: pointer;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export default {
  Wrapper,
  FlexRow,
  MenuIcon,
};
