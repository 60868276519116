import styled from 'styled-components';

const Container = styled.div`
  padding: 0 2.25rem 3.75rem 2.25rem;
  background: ${({ theme }) => theme.black};

  ${({ theme }) => theme.media.mobile`
    padding: 0 1.5rem 0 1.5rem;
  `}
`;

const Wrapper = styled.div`
  margin: 0 84px;
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  ${({ theme }) => theme.media.mobile`
    padding-top: 4.5rem;
    gap: 4.5rem;
    flex-direction: column;
    margin: 0 16px;
  `}
`;

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${({ theme }) => theme.media.mobile`
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    > div {
      width: 100%;
    }
  `}

  img {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -80px);
    -moz-transform: translate(-50%, -80px);
    -ms-transform: translate(-50%, -80px);
    -o-transform: translate(-50%, -80px);
    transform: translate(-50%, -80px);
    min-height: 260px;
    width: auto;
    ${({ theme }) => theme.media.mobile`
      height: auto;
      width: 260px;
      -webkit-transform: translate(-50%, -50px);
      -moz-transform: translate(-50%, -50px);
      -ms-transform: translate(-50%, -50px);
      -o-transform: translate(-50%, -50px);
      transform: translate(-50%, -50px);
    `}
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.black};
`;

const RuleImg = styled.img`
  width: 33.75rem;
  max-width: 100%;
`;

const TextRow = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  max-width: 27.813rem;
  width: 100%;
  min-height: 260px;
  ${({ theme }) => theme.media.mobile`
    max-width: 100%;
    justify-content: center;
    min-height: 210px;
  `}
`;

const TextTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.interSemiBold};
  font-size: 2rem;
  text-align: center;
  z-index: 4;
  p {
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  ${({ theme }) => theme.media.mobile`
    font-size: 1.25rem;
    max-width: 15rem;
    p {
      font-size: 20px;
    }
  `}
`;

export default {
  Container,
  Wrapper,
  InfoItem,
  Title,
  RuleImg,
  Center,
  TextRow,
  TextTitle,
};
