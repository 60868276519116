import { EMPTY_STRING } from 'shared/constants';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

const Title = styled.div<{
  isBlack?: boolean,
  styles: string | FlattenSimpleInterpolation}>`
  color: ${({ theme, isBlack }) => (isBlack ? theme.black : theme.white)};
  font-family: ${({ theme }) => theme.interBold};
  ${({ styles }) => styles || EMPTY_STRING}
`;

export default {
  Title,
};
