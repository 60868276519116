import React from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Modals from 'shared/components/modals';
import {
  IClaimModal, IDeclineReasonsModal, IFilterModal, IModalProps,
} from 'shared/interfaces';
import { RootState } from '../index';

export enum EModals {
  EMPTY = 'EMPTY',
  CREATE_REPORT_MODAL = 'CREATE_REPORT_MODAL',
  CLAIM_MODAL = 'CLAIM_MODAL',
  DECLINE_REASONS_MODAL = 'DECLINE_REASONS_MODAL',
  MOBILE_MENU = 'MOBILE_MENU',
  FILTER = 'FILTER',
}

export type IModalsProps = {
  [EModals.EMPTY]: any;
  [EModals.CREATE_REPORT_MODAL]: IModalProps;
  [EModals.CLAIM_MODAL]: IClaimModal;
  [EModals.DECLINE_REASONS_MODAL]: IDeclineReasonsModal;
  [EModals.MOBILE_MENU]: IModalProps;
  [EModals.FILTER]: IFilterModal;
}

export type IModals = {
  [EModals.EMPTY]: any;
  [EModals.CREATE_REPORT_MODAL]: React.FC<IModalProps>;
  [EModals.CLAIM_MODAL]: React.FC<IClaimModal>;
  [EModals.DECLINE_REASONS_MODAL]: React.FC<IDeclineReasonsModal>;
  [EModals.MOBILE_MENU]: React.FC<IModalProps>;
  [EModals.FILTER]: React.FC<IFilterModal>;
}

export const MODALS: IModals = {
  [EModals.EMPTY]: null,
  [EModals.CREATE_REPORT_MODAL]: Modals.CreateReportModal,
  [EModals.CLAIM_MODAL]: Modals.ClaimModal,
  [EModals.DECLINE_REASONS_MODAL]: Modals.DeclineReasonsModal,
  [EModals.MOBILE_MENU]: Modals.MobileMenu,
  [EModals.FILTER]: Modals.FilterModal,
};

export type ModalProps<T extends EModals> = Omit<IModalsProps[T], 'closeModal'>;

interface IModalState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IModalState = {
  modal: EModals.EMPTY,
  props: null,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (_, action: PayloadAction<IModalState>) => action.payload,
    closeModal: () => initialState,
  },
});

// SELECTORS
export const selectModalState = (state: RootState) => state.modals;

// ACTIONS
export const { showModal, closeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
