import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Big from 'big.js';
import { RootState } from 'store';

export interface Token {
  address: string;
  symbol: string;
  decimal: number;
  contractBalance: Big;
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {} as { [key: string]: Token },
  reducers: {
    updateToken: (state, action: PayloadAction<Token>) => {
      const { address } = action.payload;
      return {
        ...state,
        [address]: action.payload,
      };
    },
  },
});

export const { updateToken } = tokenSlice.actions;
export const selectRewardToken = (state: RootState) => state.tokens[state.reports.rewardConfiguration.token];

export default tokenSlice.reducer;
