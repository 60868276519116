import styled from 'styled-components';
import { styledScrollbar } from 'theme';

const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 199;
  height: 100%;
  display: flex;
  overflow: auto;
  position: fixed;
  justify-content: center;
  backdrop-filter: blur(10px);
  background: ${({ theme }) => theme.modalOverlayBlack};
`;

const Modal = styled.div<{width?: string, padding?: string}>`
  z-index: 200;
  max-width: 90%;
  overflow: auto;
  max-height: 90vh;
  position: relative;
  height: fit-content;
  width: ${({ width }) => (width || '19.5rem')};
  padding: ${({ padding }) => (padding || '1.5rem 0.75rem')};
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.borderBlack};
  align-self: center;
  ${styledScrollbar}
`;

export default {
  Wrapper,
  Modal,
};
