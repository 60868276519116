import React from 'react';
import LogoIcon from 'assets/images/icons/logo.svg';
import MobileLogoIcon from 'assets/images/icons/mobile-logo.svg';
import { useWindowDimensions } from 'shared/hooks/useWindowDimensions';
import { EDimensions } from 'shared/enums';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import { ROUTES } from 'routes/constants';
import { useHistory } from 'react-router-dom';

const Logo: React.FC = () => {
  const history = useHistory();

  const dimension = useWindowDimensions();
  const isMobile = dimension === EDimensions.SMALL;

  const redirectMainPage = () => {
    history.push(ROUTES.HOME);
    MixpanelService.trackEvent(EMixpanelEvents.HEADER_LOGO_CLICKED);
  };

  return (
    <div onClick={redirectMainPage} aria-hidden="true" style={{ cursor: 'pointer' }}>
      {isMobile ? <img src={MobileLogoIcon} alt="logo" /> : <img src={LogoIcon} alt="logo" />}
    </div>
  );
};

export default Logo;
