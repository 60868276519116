import React from 'react';
import { addSpacesBetweenWords } from 'shared/utils';

import styles from './styles';
import { IDropdown } from './interface';

const Dropdown: React.FC<IDropdown> = ({
  elements,
  onChange,
  title,
  isHeader = false,
  isFullWidth = false,
}) => {
  return (
    <styles.DropdownWrapper isHeader={isHeader} $isFullWidth={isFullWidth}>
      {title && <styles.Title>{title}</styles.Title>}
      <styles.List isHeader={isHeader}>
        {elements.map(({ name, iconPath, active }) => (
          <styles.ItemWrapper active={active} onClick={() => (!isHeader || active) && onChange(name)} key={name}>
            <styles.TitleWrapper>
              {iconPath && <styles.Icon src={iconPath} alt="network icon" />}
              <styles.Name>{addSpacesBetweenWords(name)}</styles.Name>
            </styles.TitleWrapper>
            {active !== undefined && !active && (
              <styles.Soon>
                <styles.SoonTitle>
                  <span>Soon</span>
                </styles.SoonTitle>
              </styles.Soon>
            )}
          </styles.ItemWrapper>
        ))}
      </styles.List>
    </styles.DropdownWrapper>
  );
};

export default Dropdown;
