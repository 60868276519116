import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { EButtonActions } from 'shared/enums';
import { getEntries } from 'shared/utils';

import { EReportsAddressNetwork } from 'services/api-service/interfaces/report';
import {
  DEFAULT_FILTER_OPTIONS, filterOptions, FilterNetworkIconMap,
} from './constants';
import { getCurrentFilterLabel, isActiveFilter } from './utils';
import styles from './styles';
import Button from '../Buttons';
import { IFilterPanel } from './interfaces';

const FilterPanel = ({ filter, handleChangeFilter, isModal = false }: IFilterPanel) => {
  const numberOfActiveFilters = Object.values(filter).filter(Boolean).length;
  const handleClick = (filterName: string, key: string) => {
    const isActive = isActiveFilter(filter, filterName, key);
    if (isActive) {
      handleChangeFilter({ ...filter, [filterName]: null });
    } else {
      handleChangeFilter({ ...filter, [filterName]: key });
    }
  };
  const clear = () => handleChangeFilter(DEFAULT_FILTER_OPTIONS);
  return (
    <styles.Container>
      {!isModal && (
        <styles.Header>
          <styles.Title>
            Filters
            {' '}
            {numberOfActiveFilters ? `(${numberOfActiveFilters})` : null}
          </styles.Title>
          {Boolean(numberOfActiveFilters) && (
            <styles.Clear onClick={clear}>
              Clear
              <CloseIcon />
            </styles.Clear>
          )}
        </styles.Header>
      )}
      {getEntries(filterOptions).map(([filterName, filterValues]) => (
        <styles.Filter key={filterName}>
          <styles.FilterName>{filterName}</styles.FilterName>
          <styles.WrapperButtons>
            {filterValues.map((key) => (
              <Button
                key={key}
                label={getCurrentFilterLabel(filterName, key)}
                handleClick={() => handleClick(filterName, key)}
                variant={EButtonActions.GREY_BUTTON}
                styles={styles.FilterButton(isActiveFilter(filter, filterName, key))}
                iconLeft={filterName === 'network'
                  ? FilterNetworkIconMap[key as EReportsAddressNetwork]
                  : undefined}
              />
            ))}
          </styles.WrapperButtons>
        </styles.Filter>
      ))}
    </styles.Container>
  );
};

export default FilterPanel;
