import { EButtonActions } from 'shared/enums';
import { colors } from 'theme';

const getButtonTextColor = (variant: EButtonActions) => {
  switch (variant) {
    case EButtonActions.WHITE_BUTTON:
      return colors.black;
    case EButtonActions.BLACK_BUTTON:
      return colors.white;
    case EButtonActions.YELLOW_BUTTON:
      return colors.black;
    case EButtonActions.OPACITY_YELLOW_BUTTON:
      return colors.black;
    case EButtonActions.GREY_BUTTON:
      return colors.lightGrey;
    default:
      return colors.white;
  }
};

export default getButtonTextColor;
