import {
  Redirect, Route, RouteProps, useHistory,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import { getAccount } from '@wagmi/core';
import config from 'services/config';
import { ROUTES } from '../constants';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

// eslint-disable-next-line import/prefer-default-export
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isConnected } = getAccount(config);
  const history = useHistory();

  useEffect(() => {
    if (!isConnected) history.push(ROUTES.HOME);
  }, [history, isConnected]);

  return (
    <Route {...rest} render={(props) => (isConnected ? <Component {...props} /> : <Redirect to={ROUTES.HOME} />)} />
  );
};
