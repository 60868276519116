import styled from 'styled-components';

const ReportWrapper = styled.div`
  width: 39.5rem;
  margin: 2rem auto 0 auto;
  max-width: calc(100% - 2rem);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
    margin-bottom: 1rem;
  `}
`;

const Network = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  img {
    width: 1.5rem;
    height: 1.5rem;

    ${({ theme }) => theme.media.tablet`
      width: 1rem;
      height: 1rem;
    `}
  }
`;

export default {
  ReportWrapper,
  Header,
  Network,
};
