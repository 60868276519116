import { IFilter } from 'shared/interfaces';
import {
  EReportsAddressNetwork,
  EReportsStatus,
} from 'services/api-service/interfaces/report';
import { FilterNetworkTextMap, FilterStatusTextMap, filterOptions } from './constants';

const capitalizeFirstLetter = (string: string): string => string
  .split('_')
  .map((word) => (
    word.length > 0
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : word
  ))
  .join(' ');

export const isActiveFilter = (filter: IFilter, filterName: string, filterValue: string) => {
  return Object.entries(filter).some(([key, value]) => key === filterName && value === filterValue);
};

export const getCurrentFilterLabel = (filterName: keyof typeof filterOptions, key: string) => {
  switch (filterName) {
    case 'network':
      return FilterNetworkTextMap[key as EReportsAddressNetwork];
    case 'status':
      return FilterStatusTextMap[key as EReportsStatus];
    default:
      return capitalizeFirstLetter(key);
  }
};
