import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import Title from 'shared/components/Title';
import ReportList from 'shared/components/ReportList';
import useAppSelector from 'shared/hooks/redux/useAppSelector';
import { selectReportsByUser } from 'store/slices/reports';
import { toArray } from 'shared/utils';
import { ReportStatus } from 'shared/enums';
import { ITEMS_PER_PAGE } from 'shared/constants';
import Pagination from 'shared/components/Pagination';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';
import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import { EReportsStatus } from 'services/api-service/interfaces/report';
import updateReportsByUser from 'store/actions/updateReportsByUser';
import UserAccountInformation from './components/UserAccountInformation';
import UserProfileStyles from './styles';
import titleStyles from './constants';
import Filter from './components/Filter';
import { FILTER_DEFAULT_VALUE } from './components/Filter/constants';

const UserProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { address, chainId } = useAccount();
  const { list: reports, amount: reportsAmount } = useAppSelector(selectReportsByUser);
  const reportsArray = toArray(reports).reverse();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedCategory, setSelectedCategory] = useState<ReportStatus | string>(FILTER_DEFAULT_VALUE);
  const [reportStatus, setReportStatus] = useState<EReportsStatus | null>(null);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);

    MixpanelService.trackEvent(EMixpanelEvents.CHANGE_REPORTS_PAGE, {
      page: pageNumber.toString(),
    });
  };

  const handleCategoryClick = (category: ReportStatus | string) => {
    setSelectedCategory(category);
    setCurrentPage(1);

    switch (category) {
      case ReportStatus.Pending:
        setReportStatus(EReportsStatus.PENDING);
        break;
      case ReportStatus.Approved:
        setReportStatus(EReportsStatus.ACCEPTED);
        break;
      case ReportStatus.Declined:
        setReportStatus(EReportsStatus.REJECTED);
        break;
      case ReportStatus.Claimed:
        setReportStatus(EReportsStatus.CLAIMED);
        break;
      default:
        setReportStatus(null);
        break;
    }

    const trackedCategory = typeof category === 'string' ? category : ReportStatus[category];
    MixpanelService.trackEvent(EMixpanelEvents.CHANGE_REPORTS_FILTER, {
      category: trackedCategory,
    });
  };

  useEffect(() => {
    dispatch(updateReportsByUser({ reportStatus, page: currentPage, chainId }));
  }, [dispatch, currentPage, reportStatus, address, chainId]);

  const totalPages = Math.ceil(reportsAmount / ITEMS_PER_PAGE);

  return (
    <UserProfileStyles.Page>
      <UserAccountInformation />
      <UserProfileStyles.Container>
        <Title value="My Reports" styles={titleStyles} />
        <Filter onCategoryClick={handleCategoryClick} selectedCategory={selectedCategory} />
        <ReportList reports={reportsArray} />
        {Boolean(totalPages) && (
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        )}
      </UserProfileStyles.Container>
    </UserProfileStyles.Page>
  );
};

export default UserProfile;
