export const environment = process.env.REACT_APP_ENVIRONMENT;

export const MIN_AMOUNT = '0.00001';

export const ZERO_STRING = '0';

export const EMPTY_STRING = '';
export const EMPTY_ADDRESS = '0x0';
export const DASH = '—';

export const FULFILLED = 'fulfilled';

export const DEFAULT_PAGE_LIMIT = 20;

export const ITEMS_PER_PAGE = 4;
export const REPORTS_PER_PAGE_INVESTIGATOR = 10;

export const FILE_SIZE_DECIMAL = 3;

export const UHD_HUGE_DESKTOP_WIDTH = 3900;
export const UHD_DESKTOP_WIDTH = 2800;
export const FHD_DESKTOP_WIDTH = 2500;
export const DESKTOP_WIDTH = 1440;
export const LAPTOP_WIDTH = 1210;
export const TABLET_WIDTH = 768;

export const sizes = {
  mobile: `${TABLET_WIDTH}px`,
  tablet: `${LAPTOP_WIDTH}px`,
  desktop: `${DESKTOP_WIDTH}px`,
  fhd_desktop: `${FHD_DESKTOP_WIDTH}px`,
  uhd_desktop: `${UHD_DESKTOP_WIDTH}px`,
  uhd_huge_desktop: `${UHD_HUGE_DESKTOP_WIDTH}px`,
};

export const SOCIALS = {
  telegram: 'https://t.me/scamfari_public',
  medium: 'https://medium.com/i-am-hapi',
  twitter: 'https://twitter.com/scamfari',
  discord: 'https://discord.gg/9jvGj599zg',
  telegram_bot: 'https://t.me/scamfari_bot',
  hapi: 'https://hapi.one/ ',
  linea: 'https://linea.build/',
  base: 'https://base.org/',
  scroll: 'https://scroll.io/',
  near: 'https://near.org/',
  aurora: 'https://aurora.dev/',
  gopluslabs: 'https://gopluslabs.io',
  coindesk: 'https://www.coindesk.com',
  whiteBit: 'https://whitebit.com',
  incrypted: 'https://incrypted.com',
  cyberPolice: 'https://cyberpolice.gov.ua',
};

export const FOOTER_LINKS = {
  docs: 'https://hapi-one.gitbook.io/scamfari/',
};

export const metamaskInstallerLink = 'https://metamask.io/download/';

export const FORMAT_DOT = '.';
export const FORMAT_COMMA = ',';

export const EMPTY_ERROR = 'To send the report, the fields must not be empty';

export const INVALID_ADDRESS = 'The wallet address you specified does not match the selected network. Please check if the address or the selected network is correct';
export const INVALID_URL = 'Provided text is not URL';

export const SMALLER_THAN_ZERO_ERROR = 'Value should be greater than 0';
export const SMALLER_THAN_MIN_VALUE_ERROR = 'Value should be greater than provided minimum amount';
export const GREATER_THAN_MAX_VALUE_ERROR = 'Value should be less than balance amount';

export const URL_REGEX = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

export const HAPI_EMAIL = 'mailto:i.am.hapi@hapi.one';

export const CLAIM_MODAL_WIDTH = '20.5rem';

export const supportEmail = 'support@scamfari.com';
