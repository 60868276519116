import React from 'react';
import CheckAddressStyles from 'views/Main/components/CheckAddress/styles';
import GreenAddress from 'assets/images/icons/green-address.svg';
import RedAddress from 'assets/images/icons/red-address.svg';
import Button from 'shared/components/Buttons';
import { EButtonActions } from 'shared/enums';
import { css } from 'styled-components';
import { EModals, showModal } from 'store/slices/modals';
import useAppDispatch from 'shared/hooks/redux/useAppDispatch';

import { EMixpanelEvents, MixpanelService } from 'services/mixpanel';
import { getAccount } from '@wagmi/core';

import { useWeb3Modal } from '@web3modal/wagmi/react';
import config from 'services/config';
import { RequestResult, clearButton } from '../../constants';

const InformationMessage = ({ status, clearAction }: { status: RequestResult | null; clearAction: () => void }) => {
  const isUnique = status === RequestResult.Unique;
  const dispatch = useAppDispatch();
  const { address } = getAccount(config);
  const { open } = useWeb3Modal();

  const createReport = async () => {
    if (!address) {
      MixpanelService.trackEvent(EMixpanelEvents.CONNECT_WALLET_CLICKED);
      open();
      return;
    }

    dispatch(showModal({ modal: EModals.CREATE_REPORT_MODAL, props: {} }));
    MixpanelService.trackEvent(EMixpanelEvents.CHECK_ADDRESS_CREATE_REPORT);
  };

  if (status === null) return null;

  return (
    <>
      <CheckAddressStyles.InformMessageWrapper>
        {isUnique ? <CheckAddressStyles.Icon src={GreenAddress} /> : <CheckAddressStyles.Icon src={RedAddress} />}
        <div>
          <CheckAddressStyles.WhiteText>
            {isUnique
              ? 'The address is unique and is not in our database'
              : `The address is already in our ${status === RequestResult.Existed ? 'Scamfari' : 'HAPI'} database`}
          </CheckAddressStyles.WhiteText>
          <CheckAddressStyles.MessageSubtitle>
            {isUnique
              ? 'You can create a report on it and get a reward in case of verification'
              : 'A report with this address will not be accepted'}
          </CheckAddressStyles.MessageSubtitle>
        </div>
      </CheckAddressStyles.InformMessageWrapper>

      <CheckAddressStyles.ActionButtonWrapper>
        <Button
          label="Check Another"
          handleClick={clearAction}
          variant={isUnique ? EButtonActions.GREY_BUTTON : EButtonActions.YELLOW_BUTTON}
          styles={clearButton || css``}
        />
        {isUnique && <Button label="Create Report" handleClick={createReport} variant={EButtonActions.YELLOW_BUTTON} />}
      </CheckAddressStyles.ActionButtonWrapper>
    </>
  );
};

export default InformationMessage;
